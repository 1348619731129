<!-- 商业管理系统 -->
<template>
  <div>
    <div class="business-mng-solutions">
        <BannerWeb :info="bannerInfo" :textWidth="1080"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="industryTrends"></a>
        <div class="industry-trends">
            <div class="title">行业趋势</div>
            <div class="industry-trends-box">
                <ProductAdvantagesItemEnterpriseMngPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnterpriseMngPlatform>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box">
                    <ProductAdvantagesItemSaasPlatform v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"></ProductAdvantagesItemSaasPlatform>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
    <!-- 移动端 -->
    <div class="business-mng-solutions_mob">
        <BannerWeb :info="Object.assign({}, bannerInfo, { bannerImg: bannerMobImg })" :textWidth="500"></BannerWeb>
        <div class="industry-trends_mob">
            <div class="title_mob">行业趋势</div>
            <div class="industry-trends-box_mob">
                <ProductAdvantagesItemEnterpriseMngPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnterpriseMngPlatform>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureMobImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <ProductAdvantagesItemSaasPlatform v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"></ProductAdvantagesItemSaasPlatform>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="Object.assign({}, customerCasePagesInfo, { customerCaseImg: customerCaseMobImg })"></CustomerCasePages>
    </div>
 </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemEnterpriseMngPlatform from "../components/product-advantages-item-EnterpriseMngPlatform.vue";
import ProductAdvantagesItemSaasPlatform from "../components/product-advantages-item-SaasPlatform.vue";
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'BusinessMngSolutions',
    data() {
        return {
            bannerMobImg: require("../assets/img/business-mng-solutions/banner-mob.png"),
            bannerInfo: {
                bannerImg: require("../assets/img/business-mng-solutions/banner.png"),
                title: '商业管理解决方案',
                text: '复创智云商业管理解决方案，以消费者为中心，打造全域、全时与消费者的交互触点，为提供个性化服务、商品奠定基础。面向消费者运营，提供覆盖洞察、服务、留存、复购拉回和消费产出等各环节的数字化能力和效率提升工具；面向商铺运营，提供覆盖服务响应、经营数据采集、经营分析、决策优化、品牌优化、运营管理与人员效率提升的数字化工具'
            },
            anchorLinks: [
                {
                    name: '行业趋势',
                    link: 'industryTrends'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/business-mng-solutions/industry-trends-img1.png"),
                    title: '外部经营环境发生激烈变化',
                    text: '信息化、经济全球化、市场化以及高科技的飞速发展，外部环境(经济、技术、政治、文化环境)的瞬息万变，给企业的持续经营和发展带来极大挑战。'
                },
                {
                    imgUrl: require("../assets/img/business-mng-solutions/industry-trends-img2.png"),
                    title: '消费者需求的变化',
                    text: '随着消费市场日益全球化趋势，市场竞争的不断加强，以及经济的快速发展带来收入不断增加，当前的消费者的消费行为特性发生了一系列的变化，更强调个性化消费、体验式消费。'
                },
                {
                    imgUrl: require("../assets/img/business-mng-solutions/industry-trends-img3.png"),
                    title: '商业模式逐渐趋同化',
                    text: '企业商业模式的创新应以顾客为中心，来调整、优化配置各种资源，以合作共赢的观念来建立各种联系，不断调整商业模式，以便获得持续的竞争优势。'
                },
                {
                    imgUrl: require("../assets/img/business-mng-solutions/industry-trends-img4.png"),
                    title: '纵向一体化会成为核心趋势',
                    text: '一体化由两个要素驱动，一个是头部品牌通过自建产能、自控渠道往上下游走，另一个是头部企业加速进行数字化、智能化的改造。'
                },
            ],
            schemeStructureMobImg: require("../assets/img/business-mng-solutions/scheme-structure-mob.png"),
            schemeStructureImg: require("../assets/img/business-mng-solutions/scheme-structure.png"),
            schemeAdvantagesBgImg: require("../assets/img/business-mng-solutions/scheme-advantages-bgimg.png"),
            schemeAdvantagesInfos: [
                {
                    imgUrl: require("../assets/img/business-mng-solutions/scheme-advantages-img1.png"),
                    title: '全渠道融合的智慧商业模式',
                    text: '以移动端应用为运营管理及交易履约基础，逐步推进会员管理、支付管理、配送管理体系的统一，促进消费融合。'
                },
                {
                    imgUrl: require("../assets/img/business-mng-solutions/scheme-advantages-img2.png"),
                    title: '全线智能化升级',
                    text: '线上借助大数据AI等技术，线下借助AR/VR等技术，以及无人超市、无人零售车等无人设备应用，打造全线智能化升级，提高消费者的用户体验。'
                },
                {
                    imgUrl: require("../assets/img/business-mng-solutions/scheme-advantages-img3.png"),
                    title: '多业态融合发展',
                    text: '通过用户画像及标签分析，定制精细化营销策略，激活多业态联动消费潜能；以用户侧需求推动产品反向定制，带动商业经济持续发展。'
                },
                {
                    imgUrl: require("../assets/img/business-mng-solutions/scheme-advantages-img4.png"),
                    title: 'Paas平台赋能',
                    text: '提供从连接层到业务层一站式技术服务, 赋能产品研发到项目落地全链路, 保障高效率和强落地。'
                }
            ],
            customerCaseMobImg: require("../assets/img/business-mng-solutions/customer-case-mob.png"),
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/business-mng-solutions/customer-case.png"),
                text: '商业管理解决方案'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemEnterpriseMngPlatform, ProductAdvantagesItemSaasPlatform, CustomerCasePages
    },
}
</script>
<!-- B端 -->
<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .business-mng-solutions {
        display: block;
    }
    .business-mng-solutions_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .business-mng-solutions {
        display: none;
    }
    .business-mng-solutions_mob {
        display: block;
    }
}
.business-mng-solutions{
    width: 100%;
    min-width: 1440px;
    .industry-trends{
        width: 1440px;
        margin: 0 auto;
        padding-top: 60px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .industry-trends-box{
            display: flex;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            height: 565px;
            width: 1030px;
            margin: 0 auto;
            img{
                height: 565px;
                width: 1030px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px 28px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 40px;
            }
            .values-box{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.business-mng-solutions_mob {
   .industry-trends_mob, .scheme-structure_mob, .scheme-advantages_mob {
     .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
     }
     .industry-trends-box_mob {
        display: flex;
        flex-wrap: wrap;
     }
    .img-box_mob {
        padding: 0 10px 10px;
        img {
            width: 100%;
        }
     }
     .values-box_mob {
        padding: 0 0 10px;
     }
   }
}
</style>
