<template>
    <div class="identify">
        <!--建设优势-->
        <div id="d2" class="identify-container">
            <div class="identify-title">
                关于二级节点
            </div>
            <div class="identify-des">
                工业互联网标识解析体系类似于互联网域名系统（DNS），为全球制造业发展和工业互联网普及提供关键资源和基础服务，
                以及跨国家、跨地域、跨行业、跨企业的全球信息互联互通能力，是整个工业互联网网络实现互联互通的关键基础设施。
            </div>
            <div class="identify-list" style="display: flex;margin-top: 40px;margin-bottom: 60px;">
                <div style="color: #221815;line-height: 32px;width: 50%;padding:25px 0">
                    <div class="track-list">
                        <ul>
                            <li class="first">
                                <span class="node-icon" style="top: 65%;">
                                </span>
                                <span class="txt">
                                    2017年11月27日，国务院印发了《关于深化“互联网+先进制造业”发展工业互联网的指导意见》，将“推进标识解析体系建设”列为主要任务之一。
                                </span>
                                <br>
                            </li>
                            <li>
                                <span class="node-icon" style="top: 65%;">
                                </span>
                                <span class="txt">
                                    2018年，工业和信息化部发布了《工业互联网发展行动计划（2018-2020年）》，提出“标识解析体系构建行动”。
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div style="margin-top: 60px;font-size: 0.9vw;">
                        <div style="text-indent: 1.9vw;">
                            其中，工业互联网标识解析二级节点是工业互联网标识解析体系的重要组成部分，向上对接标识解析国家顶级节点，向下对接企业标识节点及应用系统，
                            提供标识注册服务、标识解析服务、标识数据服务、运行监测等功能，是推动标识解析体系建设、应用发展和企业生态构建的重要环节。
                        </div>
                        <br>
                        <div style="text-indent: 1.9vw;">
                            目前，工业互联网标识解析二级节点收到业界的高度关注，一批二级节点正在各地快速展开建设和应用探索。
                        </div>
                    </div>
                </div>
                <div style="width: 50%;">
                    <img style="width: 100%;" :src="backgroundImg" alt="">
                </div>
            </div>
            <div class="qa-bg">
                <div class="qa-box">
                    <div class="identify-title"
                        style="text-align: center;    font-weight: bold;    margin-top: unset !important;    margin-bottom: 40px;">
                        资质许可
                    </div>
                    <div style="display: flex">
                        <div style="flex:0.3">
                            <div class="img-box">
                                <img style="width: 100%;" :src="qaImg" alt="">
                            </div>
                        </div>
                        <div style="flex:1;display: flex;align-items: center;margin-left:50px">
                            <div>
                                <div class="title">
                                    工业互联网标识注册服务机构许可证
                                </div>
                                <div class="content">
                                    南京复创智能制造技术有限公司作为国家双跨工业互联网平台的提供商、服务商，2023年率先获得国家工业互联网标识解析二级节点认证(代码 88.555)。
                                    截至目前，该二级节点已接入企业涵盖电子信息、材料和船舶、信息传播、软件和信息技术服务业等多个行业。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            backgroundImg: require("../assets/img/node-introduction/bg_2.png"),
            qaImg: require("../assets/img/node-introduction/qa.jpg")
        }
    }
}
</script>
<style lang="scss" scoped>
.identify {
    background: #F7F7F7;
    overflow: hidden;

    .identify-container {
        .identify-title {
            margin-right: 18%;
            margin-left: 18%;
            text-align: center;
            font-size: 1.4vw;
            margin-top: 60px;
            color: #000000;
            font-weight: bold;
        }

        .identify-des {
            margin-right: 18%;
            margin-left: 18%;
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 1.0vw;
            color: #000000;
            line-height: 1.9vw;
            text-indent: 2.0vw;
        }

        .identify-list {
            margin-right: 18%;
            margin-left: 18%;

            .track-list {
                position: relative;

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        position: relative;
                        padding: 30px 0 0 25px;
                        line-height: 32px;
                        border-left: 1px dashed #6D6E70;

                        .node-icon {
                            position: absolute;
                            left: -6px;
                            top: 50%;
                            width: 11px;
                            height: 11px;
                            background: #2580FD;
                            border-radius: 50%
                        }

                        .txt {
                            position: relative;
                            top: 10px;
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 0.85vw;
                        }
                    }
                }

                .first {
                    padding-top: 0;
                    border-left-color: #fff;
                }
            }
        }

        .qa-bg {
            background: #E7e8ed;
            margin: unset !important;
            padding-top: 40px;
            padding-bottom: 40px;

            .qa-box {
                width: 1200px;
                margin: auto;

                .title {
                    font-weight: bold;
                    margin-bottom: 30px;
                    font-size: 1.2vw;
                }

                .content {
                    line-height: 40px;
                    font-size: 0.85vw;
                }
            }
        }
    }
}
</style>