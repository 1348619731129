<!-- 供应商动态评价系统 -->
<template>
    <div class="supplier-evaluation-system">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemSaasPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"
                    :height="'180px'"
                ></ProductAdvantagesItemSaasPlatform>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box">
                    <ApplicationValueSupplierEvaluationSystem v-for="(item, index) in applicationValueInfos" :info="item" :key="index">
                    </ApplicationValueSupplierEvaluationSystem>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemSaasPlatform from "../components/product-advantages-item-SaasPlatform.vue";
import ApplicationValueSupplierEvaluationSystem from '../components/application-value-SupplierEvaluationSystem'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'SupplierEvaluationSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/supplier-evaluation-system/banner.png"),
                title: '供应商动态评价系统',
                text: '复创智云供应商动态评价系统，结合大数据分析算法及建模技术，对供应商全生命周期采取阶段连续性的评价方式，将供应商动态评价系统分为供应商进入评价、供应商运行评价、供应商问题辅导、改进评价几个方面，最后通过大数据分析提供供应商关系管理战略，为企业决策进行赋能。'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/supplier-evaluation-system/product-advantages-img1.png"),
                    title: '供应商进入评价体系',
                    text: '对供应商体系、资源管理与采购、产品实现、设计开发、生产运作、测量控制和分析改进等方面进行综合分析，以自定义标准的评分形式呈现。'
                },
                {
                    imgUrl: require("../assets/img/supplier-evaluation-system/product-advantages-img2.png"),
                    title: '供应商运行评价体系',
                    text: '采取日常业绩跟踪和阶段性评比的方法，采取QSTP加权标准，对供应商的业绩表现按需进行综合考核。'
                },
                {
                    imgUrl: require("../assets/img/supplier-evaluation-system/product-advantages-img3.png"),
                    title: '供应商持续跟踪',
                    text: '持续跟踪管理供应商，对部门供应商进行辅导和改进工作，另一方面，支持通过战略伙伴的评级体系，对供应商进一步全方位管理分析。'
                },
                {
                    imgUrl: require("../assets/img/supplier-evaluation-system/product-advantages-img4.png"),
                    title: '大数据指引决策',
                    text: '通过大数据AI技术手段，系统生成供应商评价模型、供应商奖惩机制模型、供应商淘汰模型以及供应商成长路径，为企业决策提供依据。'
                }
            ],
            functionIntroductionImg: require("../assets/img/supplier-evaluation-system/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/supplier-evaluation-system/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    title: '提高企业的运转效率',
                    text: '企业在不断变化的业务模式下，基于不断运转不断优化的供应商评价体系，可选择最适合的优秀供应商，降低企业的库存，有利于提高企业资金流转率。',
                    bgimg: require("../assets/img/supplier-evaluation-system/application-value-img1.png")
                },
                {
                    title: '提升企业综合竞争力',
                    text: '规范供方管理，搭建稳定、安全的供应链体系和基础，通过优胜劣汰的方式选择并积累优质供方资源，同时对不良供方进行限制和禁止，以保证合约采购质量，降低履约风险，提升公司综合竞争力。',
                    bgimg: require("../assets/img/supplier-evaluation-system/application-value-img2.png")
                },
                {
                    title: '与供应商实现双赢',
                    text: '通过供应商激励机制的强化，对供应商进行价格、订单、商誉等多方面的激励，提升其供货质量和绩效水平，与企业保持长久的合作关系，促进供需双方的利益双赢。',
                    bgimg: require("../assets/img/supplier-evaluation-system/application-value-img3.png")
                },
                {
                    title: '大数据赋能企业决策',
                    text: '大数据分析和预警危险供应商，或推荐优秀供应商，为企业决策提供依据，保证企业利益最大化。',
                    bgimg: require("../assets/img/supplier-evaluation-system/application-value-img4.png")
                },
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/supplier-evaluation-system/customer-case.png"),
                text: '复创大数据供应商动态评价系统'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemSaasPlatform, ApplicationValueSupplierEvaluationSystem, CustomerCasePages
    }
}
</script>

<style lang="scss" scoped>
.supplier-evaluation-system{
    width: 100%;
    min-width: 1440px;
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 28px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .product-advantages-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            width: 915px;
            height: 396px;
            margin: 0 auto;
            img{
                width: 915px;
                height: 396px;
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px 44px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }
}
</style>
