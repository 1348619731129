<!-- 解决方案，数字化农业解决方案，方案优势组件 -->
<template>
    <div class="scheme-advantages-item">
        <div class="header-box">
            <img :src="info.imgUrl">
            <div class="title">{{ info.title }}</div>
        </div>
        <div class="text">{{ info.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'SchemeAdvantagesDigitalAgricultureSolutions',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .scheme-advantages-item{
        box-sizing: border-box;
        width: 477px;
        margin-bottom: 50px;
        @media screen and (max-width:600px) {
            width: auto;
            margin-bottom: 0;
            padding-bottom: 50px;
        }
        .header-box{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            @media screen and (max-width:600px) {
              padding: 0 10px;
            }
            img{
                width: 40px;
                height: 40px;
                @media screen and (max-width:600px) {
                    width: 30px;
                    height: 30px;
               }
            }
            .title{
                height: 30px;
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 30px;
                margin-left: 20px;
            }
        }
        .text{
            padding-left: 60px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #191919;
            line-height: 26px;
            opacity: 0.8;
            @media screen and (max-width:600px) {
              padding-right: 10px;
            }
        }
    }
</style>
