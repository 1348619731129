<!-- 企业数字化管理平台及数据中台 -->
<template>
    <div class="enterprise-mng-platform">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemEnterpriseMngPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnterpriseMngPlatform>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box">
                    <ApplicationValue :infos="applicationValueInfos"></ApplicationValue>
                </div>
            </div>
        </div>
        <ApplicationValueMob :infos="applicationValueMobInfos" class="application-value-mob"></ApplicationValueMob>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo" class="customer-case-web"></CustomerCasePages>
        <CustomerCasePages :info="customerCasePagesInfoMob" class="customer-case-mob"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemEnterpriseMngPlatform from "../components/product-advantages-item-EnterpriseMngPlatform.vue";
import ApplicationValue from "../components/application-value.vue";
import CustomerCasePages from '../components/customer-case-pages.vue';
import ApplicationValueMob from '../components/application-value-mob';
export default {
    name: 'EnterpriseMngPlatform',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/enterprise-platform/banner.png"),
                title: '企业数字化管理平台及数据中台',
                text: '企业数字化管理平台及数据中台，利用数字化技术，针对企业不同产品与服务、不同生产方式、不同管理方式、不同商业模式进行重构，对其企业模式、生产方式、沟通方式、管理方式、决策方式进行重新定义，从而实现数字化转型'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/enterprise-platform/product-advantages-img1.png"),
                    title: '业务办公一体化',
                    text: '企业日常的人力行政管理中常用的功能，包括员工考勤、职工薪酬、绩效考核、流程审批、人才招聘、员工培训、工作汇报、往来合同、办公物资等。'
                },
                {
                    imgUrl: require("../assets/img/enterprise-platform/product-advantages-img2.png"),
                    title: '数字化协作',
                    text: '目标管理、过程管理（项目管理和任务中心）以及知识管理（文档中心、文件中心等）等各个部分既相互融合又各自自成体系，用户可以根据自己企业的实际情况组合使用或者使用其中的一部分。'
                },
                {
                    imgUrl: require("../assets/img/enterprise-platform/product-advantages-img3.png"),
                    title: '积累数据资产',
                    text: '以多角度、多层次、多粒度的主题数据为基础，针对信息架构、数据服务、安全管理等维度展开应用，从而形成数据资产。'
                },
                {
                    imgUrl: require("../assets/img/enterprise-platform/product-advantages-img4.png"),
                    title: '大数据应用',
                    text: '业务数据实时同步到搜索引擎库，利用搜索引擎技术实现海量数据的全文检索。数据仓库根据数据模型抽取业务数据，进行清洗转换，输出报表。'
                }
            ],
            functionIntroductionImg: require("../assets/img/enterprise-platform/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/enterprise-platform/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    title: '方便对企业对业务的统一管理',
                    text: '更好的感知客户需求，加快上市新产品和服务，给新渠道和业务带来业务增长，提高运营的响应速度、敏捷性和可负担性。'
                },
                {
                    title: '优化企业现有流程结构，降本增效',
                    text: '数字化管理有利于企业优化流程结构，缩减不必要的支出，从而达到降本增效的作用。'
                },
                {
                    title: '重复性任务自动执行，减少人工',
                    text: '帮助企业在一些重复性任务上省时省力，譬如每天业务员绩效上报、相关的单子的派遣等，都能通过数字化自动执行，免去了人工参与的成本。'
                },
                {
                    title: '多终端平台操作，提高工作效率',
                    text: '不受时间地点的限制，随时随地多端平台同时工作，提高员工协作能力和工作效率。'
                },
            ],
            applicationValueMobInfos: {
                title: '应用价值',
                bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg.png"),
                intru: [
                    {
                        title: '方便对企业对业务的统一管理',
                        text: '更好的感知客户需求，加快上市新产品和服务，给新渠道和业务带来业务增长，提高运营的响应速度、敏捷性和可负担性。',
                        width: '210px',
                        height: '216px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg1.png"),
                        color: '#FFFFFF',
                        titlePadding: '16px 46px 36px 11px',
                        textPadding:'0 19px 0 11px'
                    },
                    {
                        title: '优化企业现有流程结构，降本增效',
                        text: '数字化管理有利于企业优化流程结构，缩减不必要的支出，从而达到降本增效的作用。',
                        width: '125px',
                        height: '216px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg2.png"),
                        color: '#151515',
                        opacity: '0.8',
                        titlePadding: '16px 5px 16px 10px',
                        textPadding:'0 10px 0 10px'
                    },
                    {
                        title: '重复性任务自动执行，减少人工',
                        text: '帮助企业在一些重复性任务上省时省力，譬如每天业务员绩效上报、相关的单子的派遣等，都能通过数字化自动执行，免去了人工参与的成本。',
                        width: '125px',
                        height: '248px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg2.png"),
                        color: '#151515',
                        opacity: '0.8',
                        titlePadding: '16px 5px 16px 10px',
                        textPadding:'0 10px 0 10px'
                    },
                    {
                        title: '多终端平台操作，提高工作效率',
                        text: '不受时间地点的限制，随时随地多端平台同时工作，提高员工协作能力和工作效率。',
                        width: '210px',
                        height: '248px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg4.png"),
                        color: '#FFFFFF',
                        titlePadding: '16px 46px 16px 11px',
                        textPadding:'0 19px 0 11px'
                    },
                ],
            },
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/enterprise-platform/customer-case.png"),
                text: '复创大数据数字化管理平台和数据中台'
            },
            customerCasePagesInfoMob: {
                customerCaseImg: require("../assets/img/enterprise-platform/customer-case-mob.png"),
                text: '复创大数据数字化管理平台和数据中台'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemEnterpriseMngPlatform, ApplicationValue, CustomerCasePages, ApplicationValueMob
    }
}
</script>

<style lang="scss" scoped>
.enterprise-mng-platform{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1440px;
        margin: 0 auto;
        padding: 60px 0px 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .product-advantages-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .img-box{
            height: 406px;
            width: 793px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 345px;
                height: 178px;
                margin: 0 auto;
            }
            img{
                height: 406px;
                width: 793px;
                @media screen and (max-width: 600px) {
                    width: 345px;
                    height: 178px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 600px) {
            display: none;
        }
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
        }
    }
    .application-value-mob{
        display: none;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }
    .customer-case-web{
        display: block;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    .customer-case-mob{
        display: none;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }
}
</style>
