<!-- 供应商产能负荷监控系统 -->
<template>
    <div class="supplier-capacity-monitoring">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemDigitalCollPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemDigitalCollPlatform>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box" :style="{backgroundImage: `url(${applicationValueImg})`}">
                    <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfos" :key="index" :info="info"
                        @changeTextFlag="changeTextFlag"
                    ></ApplicationValueProjectMngSystem>
                </div>
                <div class="values-box-mob">
                    <div class="values-box-item-mob" :style="{ backgroundImage: `url(${applicationValueImgMob})`, marginBottom: '20px' }">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfosMob1" :key="index" :info="info"
                            @changeTextFlag="changeTextFlagMob1"
                        ></ApplicationValueProjectMngSystem>
                    </div>
                    <div class="values-box-item-mob" :style="{backgroundImage: `url(${applicationValueImgMob})`}">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfosMob2" :key="index" :info="info"
                            @changeTextFlag="changeTextFlagMob2"
                        ></ApplicationValueProjectMngSystem>
                    </div>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ApplicationValueProjectMngSystem from '../components/application-value-ProjectMngSystem.vue'
import ProductAdvantagesItemDigitalCollPlatform from "../components/product-advantages-item-DigitalCollPlatform.vue";
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'SupplierCapacityMonitoringSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/supplier-capacity-monitoring/banner.png"),
                title: '供应商产能负荷监控系统',
                text: '复创智云供应商产能负荷监控系统，企业基于自身的生产计划和物料需求计划，结合供应商产能负荷能力向供应商提交产能负荷预估清单，供应商PM通过协同系统进行确认。系统结合供应商实际供货情况、基本档案以及供应商产能负荷指数进行产能分析，并对供应商进行实时监控预警'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/supplier-capacity-monitoring/product-advantages-img1.png"),
                    title: '供应商产能预估协同',
                    text: '企业采购方需求方基于各个供应商的产能管理情况，新增供应商产能预估和工作量预估清单，供应商PM根据实际生产情况，在供应商协同系统中做出工作确认回应。'
                },
                {
                    imgUrl: require("../assets/img/supplier-capacity-monitoring/product-advantages-img2.png"),
                    title: '供应商产能负荷管理',
                    text: '建立供应商产能负荷模型，系统结合供应商产能预估清单、供应商供货记录、供应商基本信息，自动生成供应商产能负荷指数。'
                },
                {
                    imgUrl: require("../assets/img/supplier-capacity-monitoring/product-advantages-img3.png"),
                    title: '供应商产能分析',
                    text: '通过大数据AI技术手段，持续跟踪管理供应商产能负荷能力，结合供应商的基本画像，对供应商进行全方位管理分析。'
                },
                {
                    imgUrl: require("../assets/img/supplier-capacity-monitoring/product-advantages-img4.png"),
                    title: '产能监控及预警',
                    text: '基于人工智能算法，建立预警反馈的闭环管理机制，构建供应商产能预警业务体系，明确工作流程和工作内容，提高工作效率，确保供应商产能预警业务有序开展。'
                },
            ],
            functionIntroductionImg: require("../assets/img/supplier-capacity-monitoring/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/supplier-capacity-monitoring/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    index: 0,
                    title: '提高供应商关系管理效益',
                    showText: false,
                    text: ['根据供应商产能预警及现场核实信息，自动生成供应商产能预警月度分析报告及现场核实报告，减少了人工成本，提高了供应商关系管理工作的效率。']
                },
                {
                    index: 1,
                    title: '提高供应商的风险管理意识',
                    showText: false,
                    text: ['通过对供应商的产能负荷监控系统，帮助供应商建立风险管理体系，提高供应商管理意识，避免生产过剩、库存积压、产能不足等问题。']
                },
                {
                    index: 2,
                    title: '与供应商实现双赢',
                    showText: false,
                    text: ['通过供应商激励机制的强化，对供应商进行价格、订单、商誉等多方面的激励，提升其供货质量和绩效水平，与企业保持长久的合作关系，促进供需双方的利益双赢。']
                },
                {
                    index: 3,
                    title: '提高供应链整体效率',
                    showText: false,
                    text: ['解决供应商产能问题后，企业能大大提高产线的生产效率，从而解决向下游客户的交付问题，提高客户的满意度，时刻满足市场需求，提高供应链整体效率。']
                }
            ],
            applicationValueImg: require("../assets/img/supplier-capacity-monitoring/application-value-contentimg.png"),
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/supplier-capacity-monitoring/customer-case.png"),
                text: '复创大数据供应商产能负荷监控系统'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemDigitalCollPlatform, ApplicationValueProjectMngSystem, CustomerCasePages
    },
    methods: {
        changeTextFlag(arg){
            this.applicationValueInfos[arg[1]].showText = arg[0]
        }
    },
}
</script>

<style lang="scss" scoped>
.supplier-capacity-monitoring{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .product-advantages-box{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 600px) {
                justify-content: space-around;
            }
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 10px;
            }
        }
        .img-box{
            width: 800px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 345px;
            }
            img{
                width: 800px;
                @media screen and (max-width: 600px) {
                    width: 345px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-repeat: no-repeat;
                background-size: 100% 266px;
                border-radius: 0px 0px 4px 4px;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            .values-box-mob{
                display: none;
                @media screen and (max-width: 600px) {
                    display: block;
                }
                .values-box-item-mob{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    background-repeat: no-repeat;
                    background-size: 100% 180px;
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }
    }
}
</style>
