<!-- 工业制造业解决方案 -->
<template>
  <div>
    <div class="industrial-manu-solu">
        <BannerWeb :info="bannerInfo" :textWidth="1080"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="industryTrends"></a>
        <div class="industry-trends">
            <div class="title">行业趋势</div>
            <div class="industry-trends-box">
                <ProductAdvantagesItemEnergyMngSystem v-for="(item, index) in industryTrendsInfo" :info="item" :key="index"
                    :width="'512'" :textWidth="'382'"
                ></ProductAdvantagesItemEnergyMngSystem>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box">
                    <SchemeAdvantagesIndustrialManufacturingSolutions :infos="applicationValueInfos"></SchemeAdvantagesIndustrialManufacturingSolutions>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <div class="customer-case">
            <div class="title">客户案例</div>
            <div class="customer-case-box">
                <img :src="customerCaseImg">
            </div>
        </div>
    </div>
    <!-- 移动端 -->
    <div class="industrial-manu-solu_mob">
        <BannerWeb :info="Object.assign({}, bannerInfo, { bannerImg: bannerMobImg })" :textWidth="500"></BannerWeb>
        <div class="industry-trends_mob">
            <div class="title_mob">行业趋势</div>
            <div class="industry-trends-box_mob">
                <ProductAdvantagesItemEnergyMngSystem v-for="(item, index) in industryTrendsInfo" :info="item" :key="index"
                ></ProductAdvantagesItemEnergyMngSystem>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureMobImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <SchemeAdvantagesIndustrialManufacturingSolutions :infos="applicationValueInfos"></SchemeAdvantagesIndustrialManufacturingSolutions>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="customerCasePagesInfo" />
    </div>
  </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemEnergyMngSystem from "../components/product-advantages-item-EnergyMngSystem.vue";
import SchemeAdvantagesIndustrialManufacturingSolutions from '../components/scheme-advantages-IndustrialManufacturingSolutions'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'IndustrialManufacturingSolutions',
    data() {
        return {
            bannerMobImg: require("../assets/img/industrial-manu-solu/banner-mob.png"),
            bannerInfo: {
                bannerImg: require("../assets/img/industrial-manu-solu/banner.png"),
                title: '工业制造业解决方案',
                text: '复创智云工业制造业解决方案，为制造业企业量身定制成熟完善的制造业信息化管理系统，包括但不限于生产管理、库存管理、采购管理、销售管理、财务管理、成本管理、质量管理、人力资源管理等，满足大、中、小制造型企业管理需求，可随需应变；并且紧扣时代脉搏，融合当代的先进数字技术，与智能物流、PLM、MES无缝整合，帮助企业实现数字化转型升级，打造高效、精细化的智能运营模式'
            },
            anchorLinks: [
                {
                    name: '行业趋势',
                    link: 'industryTrends'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            industryTrendsInfo: [
                {
                    index: 0,
                    imgUrl: require("../assets/img/industrial-manu-solu/industry-trends-img1.png"),
                    title: '数字化、智能化管理需求',
                    text: ['中大型制造业对管控安全、提高质量、缩短交期、提升效率、降低成本的要求越来越高。']
                },
                {
                    index: 1,
                    imgUrl: require("../assets/img/industrial-manu-solu/industry-trends-img2.png"),
                    title: '行业竞争日趋激烈',
                    text: ['物联网、大数据等新技术的发展成熟促进了中国经济的飞速发展，中国制造业的竞争态势日趋激烈。']
                },
                {
                    index: 2,
                    imgUrl: require("../assets/img/industrial-manu-solu/industry-trends-img3.png"),
                    title: '生产模式变革趋势',
                    text: ['受内外部经济发展及竞争压力的影响，传统的通过人力分析管理制造弊端明显，先进的数字化技术，将为制造企业赢得显著的竞争优势。']
                },
                {
                    index: 3,
                    imgUrl: require("../assets/img/industrial-manu-solu/industry-trends-img4.png"),
                    title: '信息化协同趋势',
                    text: ['供应商、制造商、客户之间的信息不对称问题亟待解决，包括基础数据参差不齐，编码体系不统一，集成困难，孤岛纵横，数据反馈滞后等问题']
                },
            ],
            schemeStructureMobImg: require("../assets/img/industrial-manu-solu/scheme-structure-mob.png"),
            schemeStructureImg: require("../assets/img/industrial-manu-solu/scheme-structure.png"),
            schemeAdvantagesBgImg: require("../assets/img/industrial-manu-solu/scheme-advantages-bgimg.png"),
            applicationValueInfos: [
                {
                    title: '70%模块化+20%行业化+10%定制化！',
                    text: '系统通过集成化、精细化技术手段，可以为企业解决计划工单、生产执行、仓储物流、质量管理、设备管理以及工艺管理层面上的所有问题，为企业建设数字化工厂、智能化车间，全面提升企业竞争力！'
                },
                {
                    title: '数字孪生工厂',
                    text: '通过GIS、三维可视化技术对工厂的环境、设备、管线、人物角色等进行三维建模和虚拟化呈现，实现运维可视化、监控可视化、培训可视化。'
                },
                {
                    title: '智能安防系统',
                    text: '通过GIS、三维可视化技术对工厂的环境、设备、管线、人物角色等进行三维建模和虚拟化呈现，危险场所远程监控，实现数据在线查看及时预警。'
                },
                {
                    title: '提升企业产能和品牌影响力',
                    text: '提升追溯标准统一化、各端信息互通互享，提升企业安全管理和企业数字化进程，进一步提升企业产能，从而提升品牌影响力。'
                },
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/industrial-manu-solu/customer-case-mob.png"),
                text: '某知名板材企业数字化工厂解决方案'
            },
            customerCaseImg: require("../assets/img/industrial-manu-solu/customer-case.png"),
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemEnergyMngSystem, SchemeAdvantagesIndustrialManufacturingSolutions, CustomerCasePages
    }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .industrial-manu-solu {
        display: block;
    }
    .industrial-manu-solu_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .industrial-manu-solu {
        display: none;
    }
    .industrial-manu-solu_mob {
        display: block;
    }
}
.industrial-manu-solu{
    width: 100%;
    min-width: 1440px;
    .industry-trends{
        width: 1080px;
        margin: 0 auto;
        padding-top: 60px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .industry-trends-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            width: 800px;
            height: 611px;
            margin: 0 auto;
            img{
                width: 800px;
                height: 611px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
        }
    }
    .customer-case{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .customer-case-box{
            position: relative;
            img{
                width: 1080px;
                height: 360px;
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.industrial-manu-solu_mob {
   .industry-trends_mob, .scheme-structure_mob, .scheme-advantages_mob {
      .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
     }
     .img-box_mob {
        padding: 0 10px 10px;
        img {
            width: 100%;
        }
     }
   }
}
</style>