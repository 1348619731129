<!-- 头部背景图组件 -->
<template>
    <div class="banner-web" :style="{ backgroundImage: 'url(' + info.bannerImg + ')' }">
        <div class="content-box" :style="{ width: textWidth+'px' }">
            <div class="title">{{ info.title }}</div>
            <div class="text">{{ info.text }}</div>
            <div class="consult-btn" @click="$router.push('/contactUs')">立即咨询</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannerWeb',
    props:{
        info: {
            require: true
        },
        textWidth: {
            default: 738,
        }
    }
}
</script>

<style scoped lang="scss">
    .banner-web{
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 420px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 600px){
            height: 270px;
        }
        .content-box{
            text-align: center;
            margin: 0px auto;
            color: #FFFFFF;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                line-height: 56px;
                letter-spacing: 2px;
                margin-bottom: 24px;
                @media screen and (max-width: 600px){
                    height: 25px;
                    line-height: 25px;
                    font-size: 18px;
                    margin-bottom: 18px;
                }
            }
            .text{
                height: 84px;
                font-size: 17px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
                margin-bottom: 24px;
                @media screen and (max-width: 600px) {
                    height: auto;
                    font-size: 12px;
                    margin-bottom: 30px;
                    padding: 0 24px;
                    line-height: 20px;
                }
            }
            .consult-btn{
                width: 184px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 2px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4970E0;
                line-height: 32px;
                letter-spacing: 1px;
                cursor: pointer;
                margin: 0 auto;
                @media screen and (max-width: 600px) {
                    width: 116px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4970E0;
                }
                &:hover{
                    background: #4970E0;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>
