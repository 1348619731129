<!-- 应用价值组件，能耗管理系统 -->
<template>
    <div class="application-value-comp">
        <div class="top-box">
            <div class="right-item" :style="{ backgroundImage: `url(${img2})` }">
                <div class="title">{{ infos[0].title }}</div>
                <div class="text">{{ infos[0].text }}</div>
            </div>
            <div class="left-item" :style="{ backgroundImage: `url(${img1})` }">
                <div class="title">{{ infos[1].title }}</div>
                <div class="text">{{ infos[1].text }}</div>
            </div>
        </div>
        <div class="bottom-box">
            <div class="right-item" :style="{ backgroundImage: `url(${img3})` }">
                <div class="title">{{ infos[2].title }}</div>
                <div class="text">{{ infos[2].text }}</div>
            </div>
            <div class="left-item" :style="{ backgroundImage: `url(${img2})` }">
                <div class="title">{{ infos[3].title }}</div>
                <div class="text">{{ infos[3].text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            img1: require("../assets/img/saas-platform/application-value-img1.png"),
            img2: require("../assets/img/saas-platform/application-value-img2.png"),
            img3: require("../assets/img/saas-platform/application-value-img3.png")
        }
    },
    name: 'ApplicationValueEnergyMngSystem',
    props:{
        infos: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .application-value-comp{
        width: 1080px;
        margin: 0 auto;
        color: white;
        .title{
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            line-height: 33px;
            margin-bottom: 32px;
        }
        .text{
            width: 256px;
            height: 96px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 24px;
            color: rgb(104, 104, 104);
        }
        .top-box{
            display: flex;
            align-items: center;
            .left-item{
                box-sizing: border-box;
                width: 600px;
                height: 300px;
                margin-right: 28px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 70px 40px;
                .text{
                    color: #cfecff;
                }
            }
            .right-item{
                box-sizing: border-box;
                width: 450px;
                height: 300px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 70px 40px;
                color: black;
            }
        }
        .bottom-box{
            display: flex;
            align-items: center;
            margin-top: 25px;
            .right-item{
                box-sizing: border-box;
                width: 600px;
                height: 300px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 70px 40px;
                .text{
                    color: #cfecff;
                }
            }
            .left-item{
                box-sizing: border-box;
                width: 440px;
                height: 300px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 70px 40px;
                margin-right: 40px;
                color: black;
            }
        }
    }
</style>
