<!-- 数字化供应链协同平台 -->
<template>
    <div>
        <div class="saas-platform">
            <BannerWeb :info="bannerInfo"></BannerWeb>
            <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
            <a name="productAdvantages"></a>
            <div class="product-advantages">
                <div class="title">产品优势</div>
                <div class="product-advantages-box">
                    <ProductAdvantagesItemSaasPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemSaasPlatform>
                </div>
            </div>
            <a name="functionIntroduction"></a>
            <div class="function-introduction">
                <div class="title">功能介绍</div>
                <div class="img-box">
                    <img :src="functionIntroductionImg">
                </div>
            </div>
            <a name="applicationValue"></a>
            <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
                <div class="application-value-content">
                    <div class="title">应用价值</div>
                    <div class="values-box">
                        <ApplicationValue :infos="applicationValueInfos"></ApplicationValue>
                    </div>
                </div>
            </div>
            <a name="customerCase"></a>
            <div class="customer-case">
                <div class="title">客户案例</div>
                <div class="customer-case-box">
                    <CustomerCase></CustomerCase>
                </div>
            </div>
        </div>

        <div class="saas-platform-mob">
            <BannerWeb :info="bannerInfo"></BannerWeb>
            <div class="product-advantages" :style="{ backgroundImage: `url(${productAdvantagesBgImg})` }">
                <div class="title">产品优势</div>
                <div class="product-advantages-box">
                    <ProductAdvantagesItemSaasPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemSaasPlatform>
                </div>
            </div>
            <div class="function-introduction">
                <div class="title">功能介绍</div>
                <div class="img-box">
                    <img :src="functionIntroductionImg">
                </div>
            </div>
            <ApplicationValueMob :infos="applicationValueMobInfos"></ApplicationValueMob>
            <div class="customer-case-mob">
                <div class="customer-case-mob-title">客户案例</div>
                <CustomerCaseMob :caseImgs="mobImgCaseList"></CustomerCaseMob>
            </div>
        </div>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemSaasPlatform from "../components/product-advantages-item-SaasPlatform.vue";
import ApplicationValueMob from '../components/application-value-mob.vue'
import ApplicationValue from "../components/application-value.vue";
import CustomerCase from "../components/customer-case.vue";
import CustomerCaseMob from '../components/customer-case-mob-imgs'
export default {
    name: 'SaasPlatform',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/saas-platform/product-center-saas-banner.png"),
                title: 'SAAS防伪追溯平台',
                text: 'SAAS防伪追溯平台，基于工业互联网标识解析，实现企业从生产到终端消费的全流程跟踪，实现用户从终端消费到生产加工的溯源，实现政府解决市场流通产品存在的监管问题，为社会面各环节提供真正可靠可行的产品追溯、防伪及监管机制'
            },
            productAdvantagesBgImg: require("../assets/img/saas-platform/product-advantages-mob-bgimg.png"),
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/saas-platform/ywym.png"),
                    title: '一物一码',
                    text: '基于国家标识解析数字身份防伪功能，结合RFID、NFC等多重高级防伪手段，为每个商品分配唯一“身份标识”，建立基于标识的全流程质量追溯防伪体系。'
                },
                {
                    imgUrl: require("../assets/img/saas-platform/fwsy.png"),
                    title: '防伪溯源',
                    text: '在产品流通环节中，企业与消费者可基于标识写入的数据对产品质量进行追溯，同时基于标识的高级数字防伪与传统的标签防伪技术相结合，让用户通过扫码便可判断产品真伪。'
                },
                {
                    imgUrl: require("../assets/img/saas-platform/szyx.png"),
                    title: '数字营销',
                    text: '面向生产制造行业，产品进入流通领域后，在统一编码规范基础上，通过标识解析基础设施获取运营数据、消费数据，赋能企业搭建线上营销闭环；并通过标识大数据，辅助企业开展市场策略。'
                },
                {
                    imgUrl: require("../assets/img/saas-platform/zhsh.png"),
                    title: '智慧售后',
                    text: '基于标识的服务延伸能力，消费者通过商品标识二维码可直接链接产品维保与售后后台，提交售后需求，无需寻找购买平台多层传递，且服务全程数字化。'
                },
                {
                    imgUrl: require("../assets/img/saas-platform/dsjfc.png"),
                    title: '大数据防窜',
                    text: '基于客户扫码情况，进行大数据分析，呈现用户扫码及用户画像分析报告，帮助进行精准数据下的科学决策。'
                },
                {
                    imgUrl: require("../assets/img/saas-platform/qklcz.png"),
                    title: '区块链存证',
                    text: '各节点数据自动上链，共建共享可信安全数据环境。消费者、企业、政府监管部门可随时查询验证产品信息、流通信息等，随时随地完成数据验证。'
                },
            ],
            functionIntroductionImg: require("../assets/img/saas-platform/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/saas-platform/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    title: '帮助企业完成基础平台建设',
                    text: '完成企业节点、二级节点、顶级节点的连接，提供标识注册、标识解析、标识查询等基础功能，是运营管理平台及应用服务平台建设的基础。'
                },
                {
                    title: '帮助企业节约时间和学习成本',
                    text: '平台可分布式集群部署，提供标准接口，可快速对接创建运营管理和应用，节约时间和学习成本。'
                },
                {
                    title: '打造行业标准树立行业标杆',
                    text: '打造行业标准平台、工业互联网基础能力平台，促进自身工业智能化转型，占领行业高地，赋能行业。'
                },
                {
                    title: '提升平台持续造血的能力',
                    text: '建产业协同制造，开展规模化定制服务，推动面向产品管理、资产管理、物流及供应链管理等场景应用服务，形成二级节点平台的持续造血能力'
                },
            ],
            applicationValueMobInfos: {
                title: '应用价值',
                bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg.png"),
                intru: [
                    {
                        title: '帮助企业完成基础平台建设',
                        text: '完成企业节点、二级节点、顶级节点的连接，提供标识注册、标识解析、标识查询等基础功能，是运营管理平台及应用服务平台建设的基础。',
                        width: '210px',
                        height: '206px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg1.png"),
                        color: '#FFFFFF',
                        titlePadding: '16px 46px 16px 11px',
                        textPadding:'0 19px 0 11px'
                    },
                    {
                        title: '帮助企业节约时间和学习成本',
                        text: '平台可分布式集群部署，提供标准接口，可快速对接创建运营管理和应用，节约时间和学习成本。',
                        width: '125px',
                        height: '206px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg2.png"),
                        color: '#151515',
                        opacity: '0.8',
                        titlePadding: '16px 5px 16px 10px',
                        textPadding:'0 10px 0 10px'
                    },
                    {
                        title: '打造行业标准树立行业标杆',
                        text: '打造行业标准平台、工业互联网基础能力平台，促进自身工业智能化转型，占领行业高地，赋能行业。',
                        width: '125px',
                        height: '206px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg2.png"),
                        color: '#151515',
                        opacity: '0.8',
                        titlePadding: '16px 5px 16px 10px',
                        textPadding:'0 10px 0 10px'
                    },
                    {
                        title: '提升平台持续造血的能力',
                        text: '建产业协同制造，开展规模化定制服务，推动面向产品管理、资产管理、物流及供应链管理等场景应用服务，形成二级节点平台的持续造血能力',
                        width: '210px',
                        height: '206px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg4.png"),
                        color: '#FFFFFF',
                        titlePadding: '16px 46px 16px 11px',
                        textPadding:'0 19px 0 11px'
                    },
                ],
            },
            mobImgCaseList: [
                require("../assets/img/rice-mob.png"),
                require("../assets/img/crab-mob.png"),
                require("../assets/img/tea-mob.png"),
                require("../assets/img/drink-mob.png"),
            ]
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemSaasPlatform, ApplicationValue, CustomerCase, ApplicationValueMob, CustomerCaseMob
    }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 601px) { 
    .saas-platform { display: block; }
    .saas-platform-mob { display: none; }
}
@media screen and (max-width: 600px) { 
    .saas-platform { display: none; }
    .saas-platform-mob { display: block; }
}
.saas-platform{
    width: 100%;
    min-width: 1440px;
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 28px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .product-advantages-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            width: 800px;
            margin: 0 auto;
            img{
                width: 800px;
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
        }
    }
    .customer-case{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
    }
}
.saas-platform-mob{
    width: 100%;
    .product-advantages{
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 20px 14px;
        box-sizing: border-box;
        .title{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 25px;
            text-align: center;
        }
        .product-advantages-box{
            width: 345px;
            margin: 0 auto;
        }
    }
    .function-introduction{
        padding: 20px 15px;
        box-sizing: border-box;
        .title{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 25px;
            text-align: center;
        }
        .img-box{
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            justify-content: space-around;
            img{
                width: 345px;
            }
        }
    }
    .customer-case-mob{
        width: 100%;
        padding: 20px 0px;
        box-sizing: border-box;
        .customer-case-mob-title{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 25px;
            text-align: center;
            margin-bottom: 20px;
        }
    }
}
</style>
