<!-- 了解if在线 -->
<template>
    <div class="news-center">
        <BannerWebOnlytitle :info="bannerInfo"></BannerWebOnlytitle>
        <div class="news-box">
            <div class="content-box">
                <div class="input-box">
                    <el-input v-model="newsParam" placeholder="输入新闻标题或新闻内容搜索"></el-input>
                    <img :src="searchIcon" @click="searchNews">
                </div>
                <NewsCard v-for="(item, index) in searchedNewsList" :key="index" :info="item"></NewsCard>
            </div>
        </div>
    </div>
</template>

<script>
import BannerWebOnlytitle from '../components/banner-web-onlytitle'
import NewsCard from '../components/news-card'
export default {
    name: 'NewsCenter',
    mounted() {
        this.searchedNewsList = [...this.newsCardData]
    },
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/news-center/banner.png"),
                title: ['新闻动态'],
            },
            newsParam: '',
            searchIcon: require('../assets/img/news-center/search-icon.png'),
            newsCardData: [
                {
                    newsImg: require("../assets/img/news-center/news-img4.png"),
                    title:'一图读懂',
                    content: '复创智云全产业链质量追溯防伪平台',
                    time: '2022-08-03',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483779&idx=1&sn=ea52cda2f9fa79f37057d94b69896f93&chksm=c06d23c7f71aaad19dba27a9b67e9df9fde3f087acf41890d26373fe188cf1f455085687af97&mpshare=1&scene=23&srcid=0805Zkppn6dZX5VlCoS6FjeJ&sharer_sharetime=1659690949983&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../assets/img/news-center/news-img1.jpg"),
                    title:'产业赋能',
                    content: '多维视角看工业互联网平台发展质量',
                    time: '2022-07-29 17:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483740&idx=1&sn=9a3c490966c62eb6b630427efaa452e6&chksm=c06d2318f71aaa0ef87f20774a805b99396154c2b7f0ac9f33b12349b9038757feea23a6333c&mpshare=1&scene=23&srcid=0803KCZNPobCxt74NEUEaWZ9&sharer_sharetime=1659518625646&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../assets/img/news-center/news-img2.jpg"),
                    title:'要闻聚焦',
                    content: '工信部：我国工业互联网产业规模超过万亿元',
                    time: '2022-07-25 10:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483724&idx=1&sn=d6e287f869c6975e52242b2cff513801&chksm=c06d2308f71aaa1ecb0bc9bc46d9c6a372d2a58302d0f1ba0963ca469b6c8afe2351d9c90f9a&mpshare=1&scene=23&srcid=0803vSssWVYeC2poyQLRsgFw&sharer_sharetime=1659518655723&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../assets/img/news-center/news-img3.png"),
                    title:'复说政策',
                    content: '工信部印发《工业互联网专项工作组2022年工作计划》（附图解）',
                    time: '2022-07-15 16:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483706&idx=1&sn=f08b8d5ce57944ddc28644c8ef0dcbed&chksm=c06d237ef71aaa68a846779f9f676e96f8a1224fbb37ec57e528b319abde675faff8874e1775&mpshare=1&scene=23&srcid=0803TX1JbzzijWyA8PKkUd3s&sharer_sharetime=1659518665318&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
            ],
            searchedNewsList: []
        }
    },
    methods: {
        searchNews(){
            this.searchedNewsList = []
            let arr = []
            this.newsCardData.forEach(element => {
                if(element.title.search(this.newsParam) != -1 || element.content.search(this.newsParam) != -1){
                    arr.push(element)
                }
            });
            this.searchedNewsList = [...arr]
        }
    },
    components:{
        BannerWebOnlytitle, NewsCard
    }
}
</script>

<style lang="scss" scoped>
.news-center{
    width: 100%;
    .news-box{
        background: #F7F7F7;
        padding: 42px 0px 28px 0px;
        .content-box{
            width: 1080px;
            margin: 0 auto;
            ::v-deep .el-input__inner{
                height: 60px;
                background: #FFFFFF;
                box-shadow: 0px 5px 11px 4px rgba(0,0,0,0.06);
                border-radius: 33px;
                padding: 0 40px;
            }
            .input-box{
                position: relative;
                margin-bottom: 42px;
                img{
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 18px;
                    right: 40px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
