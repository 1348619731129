<!-- 锚点导航组件 -->
<template>
    <div class="anchor-navigation">
        <div class="anchor-links-item" v-for="(item, index) in anchorLinks" :key="index">
            <a :href="`#${item.link}`">{{ item.name }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnchorNavigation',
    props:{
        anchorLinks: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .anchor-navigation{
        width: 100%;
        height: 108px;
        background-color: rgb(251, 251, 251);
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(73, 112, 224, 0.1);
        .anchor-links-item{
            width: 184px;
            height: 108px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            line-height: 108px;
            text-align: center;
            margin: 0px 10px;
            a{
                color: black;
                text-decoration-line: none;
                display: inline-block;
                width: 100%;
                height: 100%;
            }
            &:hover{
                background-color: rgba(73, 112, 224, 0.1);
                color: #4970E0;
            }
        }
    }
</style>
