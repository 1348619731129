<template>
    <div class="footer-web">
        <!-- 带有背景的头部和form -->
        <div class="forter-content-middle" :style="{ backgroundImage: 'url(' + banner + ')', height: showHeader?'50vh':'390px' }" v-if="!notShowForm">
            <div class="header-box" v-if="showHeader">
                <div class="menu-img-box" v-show="!headerBoxOpen"><img :src="menuImg" @click="handleHeaderMenu"></div>
                <el-collapse-transition name="el-zoom-in-left">
                    <HeaderWebOpen v-show="headerBoxOpen" @cancelHeaderMenu="cancelHeaderMenu"
                        :class=" [ !headerBoxOpen ? 'header-web-open' : ''] "
                    ></HeaderWebOpen>
                </el-collapse-transition>
            </div>
            <div class="form-content" :style="{marginTop: showHeader?'15vh':'0vh', top: showHeader?'-72px':'60px' }">
                <div class="title">联系我们，链接数智未来</div>
                <div class="botttom-border"></div>
                <div class="contact-us-box">
                    <div class="contact-title">留下您的预约信息，我们将第一时间联系您！</div>
                    <div class="contact-box">
                        <el-form :model="contactUsInfo" :rules="contactFormRules" ref="contactRuleForm" label-width="100px" class="contact-ruleForm">
                            <el-form-item prop="companyName">
                                <el-input v-model="contactUsInfo.companyName" placeholder="请输入您的公司名称" class="contact-input"></el-input>
                            </el-form-item>
                            <el-form-item prop="name">
                                <el-input v-model="contactUsInfo.name" placeholder="请输入您的姓名" class="contact-input"></el-input>
                            </el-form-item>
                            <el-form-item prop="position">
                                <el-input v-model="contactUsInfo.position" placeholder="请输入您的职位" class="contact-input"></el-input>
                            </el-form-item>
                            <el-form-item prop="contact">
                                <el-input v-model="contactUsInfo.contact" placeholder="请输入您的手机号码" class="contact-input"></el-input>
                            </el-form-item>
                            <div class="submit-btn" @click="commitContactInfo">提交信息</div>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <div class="links-box">
                <!-- 最后一列应该与下方二维码对齐 -->
                <div class="links-item" v-for="(item, index) in links" :key="index" :style="{marginRight: index==links.length-1?'10px':''}">
                    <div class="links-title">{{ item.linksTitle }}</div>
                    <div class="link-item" v-for="(ele, i) in item.links" :key="i" @click="changePage(ele.path)">{{ ele.name }}</div>
                </div>
                <div class="links-copyr">
                    <div class="links-title">投诉热线</div>
                    <div class="links-text">400-106-2918</div>
                    <div class="links-title">投诉邮箱</div>
                    <div class="links-text">cn88555@fc3de.com</div>
                    <!-- <img :src="qrcodeImg" style="width: 70px"> -->
                </div>
            </div>
            <div class="contact-information">
                <div class="phone-copyr">
                    <div class="phone-title">咨询热线：</div>
                    <div class="phone">400-106-2918</div>
                    <div class="copyright">Copyright 2022-2027 fc3de.com 南京复创智能制造技术有限公司版权所有 <a href="http://beian.miit.gov.cn/">苏ICP备2022027126号-1</a></div>
                </div>
                <div class="qrcode-boxs">
                    <div class="box-item" v-for="(item, index) in QrCodes" :key="index">
                        <img :src="item.qrcode" class="qrcode">
                        <div class="qrcode-name">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderWebOpen from "../home-menu-open.vue";
import { Message } from 'element-ui';
export default {
    name: 'FooterWeb',
    data() {
        var checkPhoneNumber = (rule, value, callback) => {
            var reg = /^1[3-9]\d{9}$/
            if (value === '') {
                callback(new Error('请输入手机号码'));
            } else if (!reg.test(value)) {
                callback(new Error('手机号码格式不正确！'));
            } else {
                callback();
            }
        };
        return {
            menuImg: require("../../assets/img/menu.png"),
            banner: require("../../assets/img/home-banner6.png"),
            qrcodeImg: require("../../assets/img/qrcode.png"),
            // 脚部链接
            links: [
                {
                    linksTitle: '工业互联网平台',
                    links: [
                        {
                            name: 'SaaS防伪追溯平台',
                            path: '/pages/saasPlatform'
                        },
                        {
                            name: '数字化供应链协同平台',
                            path: '/pages/digitalCollPlatform'
                        },
                        {
                            name: '企业数字化管理平台及数据中台',
                            path: '/pages/enterpriseMngPlatform'
                        },
                    ]
                },
                {
                    linksTitle: '工业互联网技术',
                    links: [
                        {
                            name: '项目管理系统',
                            path: '/pages/projectMngSystem'
                        },
                        {
                            name: '知识管理系统',
                            path: '/pages/knowledgeMngSystem'
                        },
                        {
                            name: '能耗管理系统',
                            path: '/pages/energyMngSystem'
                        },
                        {
                            name: '供应商质量分析系统',
                            path: '/pages/supplierQualityAnalysisSystem'
                        },
                        {
                            name: '供应商产能负荷监控系统',
                            path: '/pages/supplierCapacityMonitoringSystem'
                        },
                        {
                            name: '供应商动态评价系统',
                            path: '/pages/supplierEvaluationSystem'
                        },
                    ]
                },
                {
                    linksTitle: '工业互联网人才学院',
                    links: [
                        {
                            name: '智能制造学院',
                            path: '/pages/intelManuCollege'
                        }
                    ]
                },
                {
                    linksTitle: '二级节点',
                    links: [
                        {
                            name: '二级节点介绍',
                            path: '/pages/nodeIntroduction'
                        },
                        {
                            name: '服务细则',
                            path: '/pages/nodeServiceDetail'
                        }
                    ]
                },
                {
                    linksTitle: '行业解决方案',
                    links: [
                        {
                            name: '数字化农业解决方案',
                            path: '/pages/digiAgriculSolu'
                        },
                        {
                            name: '工业制造业解决方案',
                            path: '/pages/industManuSolu'
                        },
                        {
                            name: '社会管理解决方案',
                            path: '/pages/socialMngSolutions'
                        },
                        {
                            name: '商业管理解决方案',
                            path: '/pages/businessMngSolutions'
                        }
                    ]
                },
                {
                    linksTitle: '技术解决方案',
                    links: [
                        {
                            name: '政府监管防伪追溯解决方案',
                            path: '/pages/goveCounSolution'
                        },
                        {
                            name: '产品全生命周期管理解决方案',
                            path: '/pages/productLifeMngSolution'
                        },
                        {
                            name: '数字化工艺解决方案',
                            path: '/pages/digitalProcessSolutions'
                        },
                        {
                            name: '数字化工厂解决方案',
                            path: '/pages/digitalFactorySolutions'
                        }
                    ]
                },
                {
                    linksTitle: '了解IF在线',
                    links: [
                        {
                            name: '关于IF在线',
                            path: '/pages/konwIfWeline'
                        },
                        {
                            name: '新闻动态',
                            path: '/pages/newsCenter'
                        }
                    ]
                }
            ],
            // 二维码
            QrCodes: [
                {
                    title:'复创大数据',
                    qrcode: require("../../assets/img/fc-big-data.png")
                },
                {
                    title:'复创智能制造学院',
                    qrcode: require("../../assets/img/fc-school.png")
                },
                {
                    title:'复创智云',
                    qrcode: require("../../assets/img/fc-cloud.png")
                },
            ],
            // 第六页的提交信息
            contactUsInfo: {
                companyName: '',
                name: '',
                position: '',
                contact: '',
            },
            // 表单的校验
            contactFormRules: {
                companyName: [
                    { required: true, message: '请输入您的公司名称', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                position: [
                    { required: true, message: '请输入您的职位', trigger: 'blur' }
                ],
                contact: [
                    { validator: checkPhoneNumber, trigger: 'blur' }
                ]
            },
        }
    },
    methods: {
        // 提交咨询信息
        commitContactInfo(){
            this.$refs['contactRuleForm'].validate((valid) => {
                if (valid) {
                    this.$axios.post('ifweline/fcapi/contactUs/addItem',{
                        tenantId: 1,
                        companyName: this.contactUsInfo.companyName,
                        name: this.contactUsInfo.name,
                        contact: this.contactUsInfo.contact,
                        position: this.contactUsInfo.position,
                    }).then(res => {
                        if(res.data&&res.data.code == 200){
                            this.contactUsInfo = {
                                companyName: '',
                                name: '',
                                position: '',
                                contact: '',
                            }
                            Message.success({offset: 92, message: "预约成功！请耐心等候消息。"})
                        }else{
                            Message.error({offset: 92, message: "预约失败！" + res.data.message})
                        }
                    }).catch(() => {
                        Message.error({offset: 92, message: "预约失败，网络异常！请稍后重试。"})
                    })
                } else {
                    return false;
                }
            });
        },
        // 触发目录切换事件
        handleHeaderMenu(){
            this.$emit("headerBoxOpenChange", !this.headerBoxOpen)
        },
        // 关闭菜单
        cancelHeaderMenu(){
            this.handleHeaderMenu()
        },
        scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
        },
        changePage(path){
            this.scrollToTop();
            this.$router.push(path);
        }
    },
    components:{
        HeaderWebOpen
    },
    props:{
        // 是否展示头部
        showHeader:{
            default: false
        },
        // 控制头部是否打开目录
        headerBoxOpen:{
            default: false
        },
        notShowForm:{
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
    .footer-web{
        width: 100%;
        min-width: 1080px;
        height: 100%;
        min-width: 1400px;
        background-repeat: no-repeat;
        background-size: 100% 52%;
        .header-box{
            height: 72px;
            .menu-img-box{
                height: 72px;
                display: flex;
                align-items: center;
                margin-left: 24px;
                img{
                    width: 81px;
                    height: 25px;
                    cursor: pointer;
                }
            }
            .header-web-open{
                position: relative;
                bottom: 72px;
            }
        }
        .forter-content-middle{
            height: 50vh;
            width: 100%;
            min-width: 1400px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .form-content{
                width: 1080px;
                margin: 0 auto;
                box-sizing: border-box;
                margin-top: 15vh;
                position: relative;
                top: -72px;
                .title{
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 56px;
                    letter-spacing: 1px;
                    margin-bottom: 3vh;
                }
                .botttom-border{
                    background-color: #4970E0;
                    width: 116px;
                    border-radius: 2px;
                    border: 2px solid #4970E0;
                    margin-bottom: 6vh;
                }
                .contact-us-box{
                    height: 78px;
                    .contact-title{
                        height: 22px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 22px;
                        margin-bottom: 24px;
                    }
                    .contact-box{
                        .contact-ruleForm{
                            display: flex;
                            height: 32px;
                            ::v-deep .el-form-item{
                                height: 32px;
                                margin-bottom: 0px;
                                width: 216px;
                                position: relative;
                                bottom: 4px;
                            }
                            ::v-deep .el-form-item__content{
                                height: 32px !important;
                                margin-left: 0px !important;
                            }
                            .contact-input{
                                width: 200px;
                                height: 32px;
                                margin-right: 16px;
                                ::v-deep .el-input__inner{
                                    height: 32px;
                                }
                            }
                            .submit-btn{
                                margin-left: 12px;
                                height: 32px;
                                line-height: 32px;
                                width: 100px;
                                background: #4970E0;
                                border-radius: 2px;
                                text-align: center;
                                cursor: pointer;
                                color: #FFFFFF;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }
        }
        .footer-box{
            width: 1080px;
            margin: 0px auto;
            padding: 24px 0px;
            .links-box{
                display: flex;
                width: 1080px;
                justify-content: space-between;
                .links-item{
                    .links-title{
                        height: 21px;
                        font-size: 15px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 21px;
                        letter-spacing: 1px;
                        margin-bottom: 12px;
                    }
                    .link-item{
                        height: 18px;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgb(104, 104, 104);
                        line-height: 18px;
                        margin-bottom: 8px;
                        cursor: pointer;
                        &:hover{
                            color: #4970E0;
                        }
                    }
                }
                .links-copyr {
                    .links-title{
                        height: 21px;
                        font-size: 13px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 15px;
                    }
                    .links-text{
                        height: 20px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgb(104, 104, 104);
                        line-height: 15px;
                    }
                }
            }
            .contact-information{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 116px;
                .phone-copyr{
                    padding-top: 23px;
                    .phone-title{
                        height: 21px;
                        font-size: 15px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 21px;
                        margin-bottom: 8px;
                    }
                    .phone{
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgb(104, 104, 104);
                        line-height: 20px;
                        margin-bottom: 24px;
                    }
                    .copyright{
                        height: 20px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgb(198, 198, 198);
                        line-height: 20px;
                        a {
                            color: rgb(198, 198, 198);
                            text-decoration: none;
                        }
                        a:hover {
                            color: #ba2636;
                            text-decoration: underline;
                        }
                    }
                }
                .qrcode-boxs{
                    display: flex;
                    height: 116px;
                    .box-item{
                        height: 116px;
                        margin-left: 80px;
                        text-align: center;
                        .qrcode{
                            width: 88px;
                            height: 88px;
                            margin-bottom: 8px;
                        }
                        .qrcode-name{
                            text-align: center; 
                            height: 20px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: rgb(104, 104, 104);
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
</style>
