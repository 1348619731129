<!-- 社会管理解决方案 -->
<template>
    <div>
     <div class="social-mng-solutions">
        <BannerWeb :info="bannerInfo" :textWidth="1080"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="industryTrends"></a>
        <div class="industry-trends">
            <div class="title">行业趋势</div>
            <div class="industry-trends-box">
                <IndustryTrendsSocialMngSolutions v-for="(item, index) in industryTrendsInfo" :info="item" :key="index"></IndustryTrendsSocialMngSolutions>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box">
                    <div class="img-box"><img :src="applicationValueImg1"></div>
                    <div class="introduction-item" v-for="(item, index) in applicationValueInfos" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }"
                    >
                        <div class="content-box">
                            <div class="introduction-item-title">{{ item.title }}</div>
                            <div class="introduction-item-text">{{ item.text }}</div>
                        </div>
                    </div>
                    <div class="img-box"><img :src="applicationValueImg2"></div>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
    <!-- 移动端 -->
    <div class="social-mng-solutions_mob">
        <BannerWeb :info="bannerInfo" :textWidth="500"></BannerWeb>
        <div class="industry-trends_mob">
            <div class="title_mob">行业趋势</div>
            <div class="industry-trends-box_mob">
                <IndustryTrendsSocialMngSolutions v-for="(item, index) in industryTrendsInfo" :info="item" :key="index"></IndustryTrendsSocialMngSolutions>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <div class="values-box-item_mob">
                    <div class="img-box-item_mob"><img :src="applicationValueImg1"></div>
                    <div class="introduction-item_mob" v-for="(item, index) in applicationValueInfos.filter((ele, eleIndex) => eleIndex < 2)" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }">
                        <div class="content-box_mob">
                            <div class="introduction-item-title_mob">{{ item.title }}</div>
                            <div class="introduction-item-text_mob">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
                <div class="values-box-item_mob">
                    <div class="img-box-item_mob"><img :src="applicationValueImg2"></div>
                    <div class="introduction-item_mob" v-for="(item, index) in applicationValueInfos.filter((ele, eleIndex) => eleIndex >= 2)" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }">
                        <div class="content-box_mob">
                            <div class="introduction-item-title_mob">{{ item.title }}</div>
                            <div class="introduction-item-text_mob">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
</div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import IndustryTrendsSocialMngSolutions from "../components/industry-trends-SocialMngSolutions.vue";
// import SchemeAdvantagesIndustrialManufacturingSolutions from '../components/scheme-advantages-IndustrialManufacturingSolutions'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'SocialMngSolutions',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/social-mng-solutions/banner.png"),
                title: '社会管理解决方案',
                text: '复创智云社会管理解决方案，以消费者为中心，打造全域、全时与消费者的交互触点，为提供个性化服务、商品奠定基础。面向消费者运营，提供覆盖洞察、服务、留存、复购拉回和消费产出等各环节的数字化能力和效率提升工具；面向商铺运营，提供覆盖服务响应、经营数据采集、经营分析、决策优化、品牌优化、运营管理与人员效率提升的数字化工具'
            },
            anchorLinks: [
                {
                    name: '行业趋势',
                    link: 'industryTrends'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            industryTrendsInfo: [
                {
                    imgUrl: require("../assets/img/social-mng-solutions/industry-trends-img1.png"),
                    title: '合作共治与复合治理',
                    text: '从行政力量的一元化管理或单一部门的碎片化治理转向多元主体的合作共治与复合治理。'
                },
                {
                    imgUrl: require("../assets/img/social-mng-solutions/industry-trends-img2.png"),
                    title: '信息化管理与网络化治理',
                    text: '随着信息技术、特别是互联网在中国社会的迅速普及，如何在信息化、网络化时代进行社会管理和社区治理这一问题越来越被提上了日程。'
                },
                {
                    imgUrl: require("../assets/img/social-mng-solutions/industry-trends-img3.png"),
                    title: '注重思想引导和共识凝聚',
                    text: '社会的柔性化管理还有赖于老百姓对主流价值观的认同和共识的形成，提高社会管理水平的一个重要环节就是提高社会共识。'
                },
                {
                    imgUrl: require("../assets/img/social-mng-solutions/industry-trends-img4.png"),
                    title: '虚拟社区与智慧城市建设',
                    text: '打造与实体社区相对应的虚拟社区以及以数字化、感知化、互联化、智能化为特征的智慧城市，已成为全国各地政府的发展方向。'
                },
            ],
            schemeStructureImg: require("../assets/img/social-mng-solutions/scheme-structure.png"),
            schemeAdvantagesBgImg: require("../assets/img/social-mng-solutions/scheme-advantages-bgimg.png"),
            applicationValueImg2: require("../assets/img/social-mng-solutions/scheme-advantages-img1.png"),
            applicationValueImg1: require("../assets/img/social-mng-solutions/scheme-advantages-img2.png"),
            applicationValueInfos: [
                {
                    title: '应急智慧调度及时高效',
                    text: '通过社会综治方案推动政务改革，实现应急调度及时化、信息互通的方便化、会议召开的随时随地化、便民服务的多元化。',
                    bgColor: '#4970E0',
                    color: '#FFFFFF'
                },
                {
                    title: '部门协作效率高',
                    text: '实现政府部门各类行政会议、基层经验交流会议等不同行政部门间的协同沟通，为辖区内部发送各种信息。',
                    bgColor: '#D6E0FC',
                    color: '#151515'
                },
                {
                    title: '提高群众共同监督的积极性',
                    text: '把治安防范措施进一步延伸到群众身边，远程视频会议系统拉近了政府和普通群众的距离，利于打造务实高效的政府。',
                    bgColor: '#4970E0',
                    color: '#FFFFFF'
                },
                {
                    title: '智能设备赋能实时监管',
                    text: '全方位覆盖视频监管终端，做到市域监管无死角。重点区域采用移动软终端和智能监控向结合，实现各级各部门可视化通信。',
                    bgColor: '#D6E0FC',
                    color: '#151515'
                },
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/social-mng-solutions/customer-case.png"),
                text: '社会管理解决方案'
            }
        }
    },
    components:{
        // SchemeAdvantagesIndustrialManufacturingSolutions
        BannerWeb, AnchorNavigation, IndustryTrendsSocialMngSolutions, CustomerCasePages
    }
}
</script>
<!-- B端 -->
<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .social-mng-solutions {
        display: block;
    }
    .social-mng-solutions_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .social-mng-solutions {
        display: none;
    }
    .social-mng-solutions_mob {
        display: block;
    }
}
.social-mng-solutions{
    width: 100%;
    min-width: 1440px;
    .industry-trends{
        width: 1080px;
        margin: 0 auto;
        padding-top: 60px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .industry-trends-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            width: 1080px;
            height: 674px;
            margin: 0 auto;
            img{
                width: 1080px;
                height: 674px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: white;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
            .values-box{
                display: flex;
                flex-wrap: wrap;
                .img-box{
                    width: 540px;
                    height: 236px;
                    img{
                        width: 540px;
                        height: 236px;
                    }
                }
                .introduction-item{
                    width: 270px;
                    height: 236px;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .content-box{
                            .introduction-item-text{
                                animation: textShowFromBottomIntelManuCollegeValues 1s;
                                -webkit-animation: textShowFromBottomIntelManuCollegeValues 1s; /* Safari 与 Chrome */
                                display: block;
                            }
                        }
                    }
                    .content-box{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        .introduction-item-title{
                            width: 219px;
                            font-size: 18px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            line-height: 25px;
                            margin-bottom: 18px;
                            text-align: center;
                        }
                        .introduction-item-text{
                            display: none;
                            margin: 0 auto;
                            width: 197px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            line-height: 25px;
                            height: 100px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.social-mng-solutions_mob {
   .industry-trends_mob, .scheme-structure_mob, .scheme-advantages_mob {
    .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
     }
     .industry-trends-box_mob {
        display: flex;
        flex-wrap: wrap;
     }
     .business-challenges-box_mob {
        display: flex;
        flex-wrap: wrap;
     }
    .img-box_mob {
        padding: 0 10px 10px;
        img {
            width: 100%;
        }
     }
     .values-box_mob{
        padding: 0 0 20px;
                .values-box-item_mob {
                    padding: 0 10px;
                    display: flex;
                    flex-wrap: wrap;
                    .img-box-item_mob{
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .introduction-item_mob{
                    width: 50%;
                    height: 236px;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .content-box_mob{
                            .introduction-item-text_mob{
                                animation: textShowFromBottomIntelManuCollegeValues 1s;
                                -webkit-animation: textShowFromBottomIntelManuCollegeValues 1s; /* Safari 与 Chrome */
                                display: block;
                            }
                        }
                    }  
                    .content-box_mob{
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        width: 90%;
                        .introduction-item-title_mob{
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            line-height: 25px;
                            margin-bottom: 15px;
                            text-align: center;
                        }
                        .introduction-item-text_mob{
                            display: none;
                            margin: 0 auto;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            line-height: 25px;
                            height: 100px;
                        }
                    }
                }
                }
            }
   }
}
</style>