<!-- 首页，使用轮播图组件每个轮播图item是一个屏幕的高度 -->
<template>
    <div>
        <div class="home-page-box">
            <el-carousel height="100vh" direction="vertical" :autoplay="false" ref="carousel" :loop="false">
                <el-carousel-item>
                    <div class="home-page">
                        <video autoplay ref="video" muted>
                            <source :src="page1Video">
                        </video>
                        <div @mouseenter="showHeaderMenuOpen = true" @mouseleave="showHeaderMenuOpen = false">
                            <HeaderWeb :fontColor="'white'" :logoImg="logoImgWhite" v-show="!showHeaderMenuOpen"></HeaderWeb>
                            <HeaderWebOpen v-show="showHeaderMenuOpen" :class="[ showHeaderMenuOpen ? 'header-web-open-show' : '' ]"  :showImg="false"
                            ></HeaderWebOpen>
                        </div>
                    
                        <div class="page1-content">
                            <div>
                                <div>工业互联网</div>
                                <div>技术解决方案提供商</div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="home-page" :style="{ backgroundImage: 'url(' + homeBanner2 + ')',  }">
                        <div class="header-box">
                            <div class="menu-img-box" v-show="!showHeaderMenu"><img :src="menuImg" @click="showHeaderMenu = true"></div>
                            <el-collapse-transition name="el-zoom-in-left">
                                <HeaderWebOpen v-show="showHeaderMenu" @cancelHeaderMenu="cancelHeaderMenu" 
                                    :class=" [ !showHeaderMenu ? 'header-web-open' : ''] "
                                ></HeaderWebOpen>
                            </el-collapse-transition>
                        </div>
                    
                        <div class="page2-content">
                            <div :class="['left-content', index != currentPageTwoContent?'left-content-hide':'']" v-for="(item, index) in pageTwoLeftContent" :key="index">
                                <div v-if="index == currentPageTwoContent" :class="index == currentPageTwoContent?'hide-to-right':''">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="botttom-border"></div>
                                    <div class="text">{{ item.text }}</div>
                                    <div class="tags-box" :style="{ width: item.tags.length > 3 ? '516px' : '242px' }">
                                        <div class="tag" v-for="(item, index) in item.tags" :key="index" @click="goItemPage(item.path)">{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-box">
                                <div class="back-circlr">
                                    <div class="text-circle">IF WELINE</div>
                                </div>
                                <div class="right-top-circle" @mouseenter="changeCurrInfo(1)">
                                    <img :src="pageTwoRightBtns[1].platform" :class="pageTwoRightBtns[1].showBig?'img-big':'img-small'">
                                </div>
                                <div class="left-circle" @mouseenter="changeCurrInfo(0)">
                                    <img :src="pageTwoRightBtns[0].technology" :class="pageTwoRightBtns[0].showBig?'img-big':'img-small'">
                                </div>
                                <div class="right-bottom-circle" @mouseenter="changeCurrInfo(2)">
                                    <img :src="pageTwoRightBtns[2].personnel" :class="pageTwoRightBtns[2].showBig?'img-big':'img-small'">
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="home-page" :style="{ backgroundImage: 'url(' + homeBanner3 + ')',  }">
                        <div class="header-box">
                            <div class="menu-img-box" v-show="!showHeaderMenu"><img :src="menuImg" @click="showHeaderMenu = true"></div>
                            <el-collapse-transition name="el-zoom-in-left">
                                <HeaderWebOpen v-show="showHeaderMenu" @cancelHeaderMenu="cancelHeaderMenu" 
                                    :class=" [ !showHeaderMenu ? 'header-web-open' : ''] "
                                ></HeaderWebOpen>
                            </el-collapse-transition>
                        </div>
                    
                        <div class="page3-content">
                            <div class="title">满足多种业务需求的行业解决方案</div>
                            <div class="botttom-border"></div>
                            <div class="content-box">
                                <div class="card-item" v-for="(item, index) in pageThreeCards" :key="index" :style="{ backgroundImage: 'url(' + item.bgimg + ')' }">
                                    <div class="card-content">
                                        <div class="card-title">{{ item.title }}</div>
                                        <div class="card-text">{{ item.text }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="home-page" :style="{ backgroundImage: 'url(' + homeBanner4 + ')',  }">
                        <div class="header-box">
                            <div class="menu-img-box" v-show="!showHeaderMenu"><img :src="menuImg" @click="showHeaderMenu = true"></div>
                            <el-collapse-transition name="el-zoom-in-left">
                                <HeaderWebOpen v-show="showHeaderMenu" @cancelHeaderMenu="cancelHeaderMenu" 
                                    :class=" [ !showHeaderMenu ? 'header-web-open' : ''] "
                                ></HeaderWebOpen>
                            </el-collapse-transition>
                        </div>
                    
                        <div class="page4-content">
                            <div class="title">成功客户案例</div>
                            <div class="botttom-border"></div>
                            <CustomerCase></CustomerCase>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="home-page">
                        <div class="header-box">
                            <div class="menu-img-box" v-show="!showHeaderMenu"><img :src="menuImg" @click="showHeaderMenu = true"></div>
                            <el-collapse-transition name="el-zoom-in-left">
                                <HeaderWebOpen v-show="showHeaderMenu" @cancelHeaderMenu="cancelHeaderMenu" 
                                    :class=" [ !showHeaderMenu ? 'header-web-open' : ''] "
                                ></HeaderWebOpen>
                            </el-collapse-transition>
                        </div>
                    
                        <div class="page5-content">
                            <div class="title">合作伙伴</div>
                            <div class="botttom-border"></div>
                            <slider ref="slider" :options="classOptionSwiper" class="logos-box">
                                <slideritem v-for="(item, index) in pageFiveLogos" :key="index" class="logo-item"><img :src="item"></slideritem>
                            </slider>
                            <div class="title">新闻动态</div>
                            <div class="botttom-border"></div>
                            <div class="new-box">
                                <div class="latest-new-box" @click="goNewsPage(pageFiveNewsData[0].link)">
                                    <div class="news-img"><img :src="pageFiveNewsData[0].newsImg"></div>
                                    <div class="content-box">
                                        <div class="title">{{ pageFiveNewsData[0].title }}：{{ pageFiveNewsData[0].content }}</div>
                                        <div class="time">{{ pageFiveNewsData[0].time }}</div>
                                    </div>
                                </div>
                                <div class="other-news-box">
                                    <div class="news-item" v-for="(item, index) in pageFiveNewsData" :key="index">
                                        <div class="page5-new-box" v-if="index" :style="{ paddingTop: index == 1 ? '0px' : '32px' }">
                                            <div class="title">{{ item.title }}：{{ item.content }}</div>
                                            <div class="bottom-box">
                                                <div class="time">{{ item.time }}</div>
                                                <div class="view-btn" @click="goNewsPage(item.link)">立即查看<i class="el-icon-arrow-right"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <FooterWeb class="footer-web" :showHeader="true" :headerBoxOpen="showHeaderMenu" @headerBoxOpenChange="headerBoxOpenChange"></FooterWeb>
                </el-carousel-item>
            </el-carousel>
        </div>

        <div class="home-page-mob">
            <HeaderMob></HeaderMob>
            <div class="home-content">
                <div class="video-box" :style="{ backgroundImage: 'url(' + homeBanner1Mob + ')' }">
                    <div class="banner-text">工业互联网</div>
                    <div class="banner-text">技术解决方案提供商</div>
                </div>
                <div class="pages-box" :style="{ backgroundImage: 'url(' + homeBanner2Mob + ')' }">
                    <div class="content-item-title">{{ pageTwoLeftContent[currentPageTwoContent].title }}</div>
                    <div class="content-item-line"></div>
                    <div class="content-item-intru">{{ pageTwoLeftContent[currentPageTwoContent].text }}</div>
                    <div class="content-item-content">
                        <div class="content-btns">
                            <div class="center-box">
                                <img :src="centerButton" style="width: 128px;">
                            </div>
                        </div>
                        <img :src="pageTwoRightBtns[1].platform" :class="[ pageTwoRightBtns[1].showBig?'img-big1':'img-small1', 'top-anim-circle' ]"
                            @click="changeCurrInfo(1)"
                        />
                        <img :src="pageTwoRightBtns[0].technology" :class="[ pageTwoRightBtns[0].showBig?'img-big2':'img-small2', 'left-anim-circle' ]"
                            @click="changeCurrInfo(0)"
                        />
                        <img :src="pageTwoRightBtns[2].personnel" :class="[ pageTwoRightBtns[2].showBig?'img-big3':'img-small3', 'right-anim-circle' ]"
                            @click="changeCurrInfo(2)"
                        />
                    </div>
                    <div class="content-tags-box">
                        <div class="content-tag-item" v-for="(item, index) in pageTwoLeftContent[currentPageTwoContent].tags" :key="index"
                            @click="goItemPage(item.path)"
                        >{{ item.name }}</div>
                    </div>
                </div>
                <div class="solution-box-mob" :style="{ backgroundImage: 'url(' + homeBanner3Mob + ')' }">
                    <div class="content-item-title">满足多种业务需求的行业解决方案</div>
                    <div class="content-item-line"></div>
                    <div class="solution-card-box">
                        <div class="solution-card-item" v-for="(item, index) in pageThreeCards" :key="index">
                            <div class="solution-card-img" :style="{ backgroundImage: 'url(' + item.bgimg + ')' }">
                                <div class="solution-card-title">{{ item.title }}</div>
                            </div>
                            <div class="solution-card-intru">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
                <div class="case-box-mob" :style="{ backgroundImage: 'url(' + homeBanner4Mob + ')' }">
                    <div class="content-item-title">成功客户案例</div>
                    <div class="content-item-line"></div>
                    <CustomerCaseMob :caseImgs="mobImgCaseList"></CustomerCaseMob>
                </div>
                <div class="friend-news-box-mob">
                    <div class="content-item-title">合作伙伴</div>
                    <div class="content-item-line"></div>
                    <div class="logos-box-mob">
                        <img :src="item" v-for="(item, index) in pageFiveLogos" :key="index">
                    </div>
                    <div class="content-item-title">新闻动态</div>
                    <div class="content-item-line"></div>
                    <div class="news-box-mob">
                        <div class="first-new-box" @click="goNewsPage(pageFiveNewsData[0].link)">
                            <img :src="pageFiveNewsData[0].newsImg" class="first-new-img">
                            <div class="first-new-intru">
                                <div class="first-new-title">{{ pageFiveNewsData[0].title }}：{{ pageFiveNewsData[0].content }}</div>
                                <div class="first-new-time">{{ pageFiveNewsData[0].time }}</div>
                            </div>
                        </div>
                        <div v-for="(item, index) in pageFiveNewsData" :key="index">
                            <div :class="['other-news-box-mob', index == pageFiveNewsData.length-1 ? 'last-news-box-mob' : '']" v-if="index">
                                <div class="other-news-title">{{ item.title }}：{{ item.content }}</div>
                                <div class="other-news-info">
                                    <div class="other-news-time">{{ item.time }}</div>
                                    <div class="other-news-link" @click="goNewsPage(item.link)"><img :src="viewNewBtn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMob></FooterMob>
        </div>
    </div>
</template>

<script>
import HeaderWeb from "../components/header-web/header-web.vue";
import HeaderWebOpen from "../components/home-menu-open.vue";
import { slider, slideritem } from 'vue-concise-slider'
import FooterWeb from '../components/footer-web/footer-web'
import CustomerCase from "../components/customer-case.vue";
import HeaderMob from '../components/mobileComp/header-mob.vue'
import FooterMob from '../components/mobileComp/footer-mob.vue'
import CustomerCaseMob from '../components/customer-case-mob-imgs'
import "../assets/css/animate.css";

export default {
    name: 'HomePage',
    mounted(){
        // 监听鼠标的滚轮事件切换页面
        window.addEventListener('DOMMouseScroll', this.debounce(this.changeHomePage, 300), true)
         || window.addEventListener("mousewheel", this.debounce(this.changeHomePage, 300),false);

        this.$refs.video.play()
        setInterval(this.autoplayPageTwoContent, 5000)
    },
    data() {
        return {
            // 是否展示首页的目录
            showHeaderMenu: false,
            // 是否打开二级菜单
            showHeaderMenuOpen: false,
            // 第二个页面的展示内容
            currentPageTwoContent: 0,
            pageTwoLeftContent: [
                {
                    title: '工业互联网技术',
                    text: '是各大应用平台的实现手段，助力企业数智化转型',
                    tags: [
                        {
                            name: '项目管理系统',
                            path: '/pages/projectMngSystem'
                        },
                        {
                            name: '知识管理系统',
                            path: '/pages/knowledgeMngSystem'
                        },
                        {
                            name: '能耗管理系统',
                            path: '/pages/energyMngSystem'
                        },
                        {
                            name: '供应商质量分析系统',
                            path: '/pages/supplierQualityAnalysisSystem'
                        },
                        {
                            name: '供应商产能负荷监控系统',
                            path: '/pages/supplierCapacityMonitoringSystem'
                        },
                        {
                            name: '供应商动态评价系统',
                            path: '/pages/supplierEvaluationSystem'
                        },
                    ]
                },
                {
                    title: '工业互联网平台',
                    text: '构建丰富多彩的工业互联网应用生态，实现万物互联',
                    tags: [
                        {
                            name: 'SaaS防伪追溯平台',
                            path: '/pages/saasPlatform'
                        },
                        {
                            name: '数字化供应链协同平台',
                            path: '/pages/digitalCollPlatform'
                        },
                        {
                            name: '企业数字化管理平台及数据中台',
                            path: '/pages/enterpriseMngPlatform'
                        },
                    ]
                },
                {
                    title: '工业互联网人才',
                    text: '人才是先进生产力和先进文化的重要创造者和传播者，是社会发展的基础',
                    tags: [
                        {
                            name: '智能制造学院',
                            path: '/pages/intelManuCollege'
                        }
                    ]
                },
            ],
            pageTwoContentIndex: 1,
            menuImg: require('../assets/img/menu.png'),
            logoImgWhite: require("../assets/img/ifweline-logo-white.png"),
            // 五个页面的背景图
            page1Video: require('../assets/video/fc.mp4'),
            homeBanner1: require('../assets/img/home-banner1.png'),
            homeBanner1Mob: require('../assets/img/home-mob-banner.png'),
            homeBanner2: require('../assets/img/home-banner2.png'),
            homeBanner2Mob: require('../assets/img/home-banner2-mob.png'),
            homeBanner3: require('../assets/img/home-banner3.png'),
            homeBanner3Mob: require('../assets/img/home-banner3-mob.png'),
            homeBanner4: require('../assets/img/home-banner4.png'),
            homeBanner4Mob: require('../assets/img/home-banner4-mob.png'),
            homeBanner6: require('../assets/img/home-banner6.png'),
            // 第二页三个图片的悬浮事件的图片，暂时为了动画效果流畅只用了小图
            centerButton: require('../assets/img/center-button.png'),
            pageTwoRightBtns: [
                {
                    technology: require("../assets/img/technology.png"),
                    technologyBig: require("../assets/img/technology-big.png"),
                    showBig: true
                },
                {
                    platform: require("../assets/img/platform.png"),
                    platformBig: require("../assets/img/platform-big.png"),
                    showBig: false
                },
                {
                    personnel: require("../assets/img/personnel.png"),
                    personnelBig: require("../assets/img/personnel-big.png"),
                    showBig: false
                },
            ],
            // 第三页的卡片信息
            pageThreeCards: [
                {
                    bgimg: require("../assets/img/page3-manufacturing.png"),
                    title: '制造业',
                    text: '为制造业提供新的经济增长点，帮助制造业做大做强'
                },
                {
                    bgimg: require("../assets/img/page3-construction.png"),
                    title: '建筑业',
                    text: '帮助建筑行业提高核心竞争力，实现品牌、业态、模式的创新和引领'
                },
                {
                    bgimg: require("../assets/img/page3-fisheries.png"),
                    title: '渔业',
                    text: '促进渔业生产过程与监督管理的智能化和信息化，提升管理决策能力'
                },
                {
                    bgimg: require("../assets/img/page3-agriculture.png"),
                    title: '农业',
                    text: '促进农业企业生产管理标准化、资源管理信息化以及运营管理平台化发展'
                },
                {
                    bgimg: require("../assets/img/page3-social-management.png"),
                    title: '社会管理',
                    text: '以智能、安全、融合为设计理念，提供给社会管理一体化方案，助力政府单位数字化社会管理'
                },
                {
                    bgimg: require("../assets/img/page3-business.png"),
                    title: '商业',
                    text: '提供商业全栈云方案，加速商业数字化转型'
                },
            ],
            mobImgCaseList: [
                require("../assets/img/rice-mob.png"),
                require("../assets/img/crab-mob.png"),
                require("../assets/img/tea-mob.png"),
                require("../assets/img/drink-mob.png"),
            ],
            // 第五页轮播图的logo
            pageFiveLogos: [
                require("../assets/img/logos/zenio.png"),
                require("../assets/img/logos/zghtkg.png"),
                require("../assets/img/logos/njgt.png"),
                require("../assets/img/logos/zqcz.png"),
                require("../assets/img/logos/zgzc.png"),
                require("../assets/img/logos/jljt.png"),
                require("../assets/img/logos/spotlight.png"),
                require("../assets/img/logos/bdxt.png"),
                require("../assets/img/logos/fnkj.png"),
                require("../assets/img/logos/dfqc.png"),
                // require("../assets/img/logos/hkgyhy.png"),
            ],
            // 第五页轮播图的配置项
            classOptionSwiper: {
                currentPage: 0,
                thresholdDistance: 176,// 滑动判定距离
                thresholdTime: 2000,// 滑动判定时间
                autoplay:2000,// 自动滚动[ms]
                loop:true, // 循环滚动
                loopedSlides:11,// 无限滚动前后遍历数
                slidesToScroll:1,// 每次滑动项数
                pagination: false
            },
            // 第五页的新闻咨询信息
            viewNewBtn: require('../assets/img/view-new-mob.png'),
            pageFiveNewsData: [
                {
                    newsImg: require("../assets/img/news-img4.png"),
                    title:'一图读懂',
                    content: '复创智云全产业链质量追溯防伪平台',
                    time: '2022-08-05 17:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483779&idx=1&sn=ea52cda2f9fa79f37057d94b69896f93&chksm=c06d23c7f71aaad19dba27a9b67e9df9fde3f087acf41890d26373fe188cf1f455085687af97&mpshare=1&scene=23&srcid=0805Zkppn6dZX5VlCoS6FjeJ&sharer_sharetime=1659690949983&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../assets/img/news-img1.jpg"),
                    title:'产业赋能',
                    content: '多维视角看工业互联网平台发展质量',
                    time: '2022-07-29 17:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483740&idx=1&sn=9a3c490966c62eb6b630427efaa452e6&chksm=c06d2318f71aaa0ef87f20774a805b99396154c2b7f0ac9f33b12349b9038757feea23a6333c&mpshare=1&scene=23&srcid=0803KCZNPobCxt74NEUEaWZ9&sharer_sharetime=1659518625646&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../assets/img/news-img2.jpg"),
                    title:'要闻聚焦',
                    content: '工信部：我国工业互联网产业规模超过万亿元',
                    time: '2022-07-25 10:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483724&idx=1&sn=d6e287f869c6975e52242b2cff513801&chksm=c06d2308f71aaa1ecb0bc9bc46d9c6a372d2a58302d0f1ba0963ca469b6c8afe2351d9c90f9a&mpshare=1&scene=23&srcid=0803vSssWVYeC2poyQLRsgFw&sharer_sharetime=1659518655723&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../assets/img/news-img3.png"),
                    title:'复说政策',
                    content: '工信部印发《工业互联网专项工作组2022年工作计划》（附图解）',
                    time: '2022-07-15 16:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483706&idx=1&sn=f08b8d5ce57944ddc28644c8ef0dcbed&chksm=c06d237ef71aaa68a846779f9f676e96f8a1224fbb37ec57e528b319abde675faff8874e1775&mpshare=1&scene=23&srcid=0803TX1JbzzijWyA8PKkUd3s&sharer_sharetime=1659518665318&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
            ],
        }
    },
    components:{
        HeaderWeb, HeaderWebOpen, slider, slideritem, FooterWeb, CustomerCase, HeaderMob, FooterMob, CustomerCaseMob
    },
    methods: {
        // 函数防抖
        debounce(func, wait) {
            let timeout;
            return function () {
                let context = this;
                let args = arguments;
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                    func.apply(context, args)
                }, wait);
            }
        },
        // 关闭0333279D.png菜单
        cancelHeaderMenu(){
            this.showHeaderMenu = false
        },
        // 监听鼠标滚轮事件滚动轮播图
        changeHomePage(e){
            // 兼容火狐浏览器不能触发滚轮向下滚动
            /Firefox/i.test(navigator.userAgent) ? e.detail > 0 ? this.$refs.carousel.next() 
                : this.$refs.carousel.prev() : e.deltaY > 0 ? this.$refs.carousel.next() : this.$refs.carousel.prev()
        },
        // 第二屏鼠标悬浮到图片切换内容
        changeCurrInfo(i){
            this.currentPageTwoContent = i
            for (let index = 0; index < this.pageTwoRightBtns.length; index++) {
                const element = this.pageTwoRightBtns[index];
                index == i ? element.showBig = true : element.showBig = false
            }
            this.pageTwoContentIndex += 1
            if(this.pageTwoContentIndex > 2){
                this.pageTwoContentIndex = 0
            }
        },
        // 打开新闻咨询外链
        goNewsPage(link){
            window.open(link)
        },
        // 脚部的展示目录事件
        headerBoxOpenChange(flag){
            this.showHeaderMenu = flag
        },
        goItemPage(path){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
            this.$router.push(path)
        },
        autoplayPageTwoContent(){
            this.changeCurrInfo(this.pageTwoContentIndex)
        }
    },
}
</script>

<style scoped lang="scss">
    @media screen and (min-width: 601px) { 
        .home-page-box { display: block; }
        .home-page-mob { display: none; }
    }
    @media screen and (max-width: 600px) { 
        .home-page-box { display: none; }
        .home-page-mob { display: block; }
    }
    .home-page-box{
        width: 100%;
        min-width: 1440px;
        ::v-deep .el-carousel__indicator--vertical .el-carousel__button{
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #4970E0;
        }
        ::v-deep .el-carousel__indicator--vertical{
            padding: 10px 12px;
        }
        .header-web-open-show{
            animation: headerWebOpenShow 1s;
            -webkit-animation: headerWebOpenShow 1s; /* Safari 与 Chrome */
        }
        video {
            position: fixed;// 视频定位方式设为固定
            right: 0;
            bottom: 0;// 视频位置
            min-width: 100%;
            min-height: 100%; // 不会因视频尺寸造成页面需要滚动
            width: auto;
            height: auto; // 尺寸保持原视频大小
            z-index: -100; // z轴定位，小于0即可
            -webkit-filter: grayscale(20%);// 添加灰度蒙版，如果设定为100%则视频显示为黑白
        }
        .home-page{
            height: 100%;
            width: 100%;
            min-width: 1440px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .page1-content{
                height: calc(100% - 72px);
                margin: 0 auto;
                width: 1080px;
                font-size: 56px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 110px;
                letter-spacing: 3px;
                display: flex;
                align-items: center;
            }
            .page2-content{
                height: 100%;
                width: 1080px;
                margin: 0 auto;
                box-sizing: border-box;
                margin-top: 15vh;
                display: flex;
                position: relative;
                top: -72px;
                left: -30px;
                .left-content-hide{
                    width: 0px !important;
                    margin: 0 !important;
                }
                .left-content{
                    height: 91.5%;
                    width: 516px;
                    margin-right: 180px;
                    .title{
                        height: 56px;
                        font-size: 40px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 56px;
                        letter-spacing: 1px;
                        margin-bottom: 3vh;
                    }
                    .botttom-border{
                        background-color: #4970E0;
                        width: 116px;
                        border-radius: 2px;
                        border: 2px solid #4970E0;
                        margin-bottom: 7vh;
                    }
                    .text{
                        width: 516px;
                        height: 68px;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 34px;
                        letter-spacing: 1px;
                        margin-bottom: 7vh;
                    }
                    .tags-box{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .tag{
                            width: 242px;
                            height: 42px;
                            line-height: 42px;
                            background: #4970E0;
                            border-radius: 2px;
                            color: #FFFFFF;
                            text-align: center;
                            margin-bottom: 20px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            cursor: pointer;
                        }
                    }
                    .hide-to-right{
                        animation: hideToRight 1s;
                        -webkit-animation: hideToRight 1s; /* Safari 与 Chrome */
                    }
                }
                .right-box{
                    position: relative;
                    top: 18%;
                    width: 504px;
                    height: 500px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .back-circlr{
                        border-radius: 205px;
                        width: 410px;
                        height: 410px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #FFFFFF;
                        .text-circle{
                            width: 158px;
                            height: 158px;
                            line-height: 158px;
                            text-align: center;
                            border-radius: 79px;
                            color: rgb(44, 85, 197);
                            font-weight: 400;
                            font-size: 26px;
                            background: linear-gradient(90deg, #E6F6FB 0%, #E5F7FD 100%);
                            box-shadow: 0px 4px 11px 3px rgba(0,0,0,0.0600);
                        }
                    }
                    .right-top-circle{
                        position: absolute;
                        top: -4%;
                        right: 20%;
                        cursor: pointer;
                    }
                    .left-circle{
                        position: absolute;
                        bottom: 32%;
                        right: 83%;
                        cursor: pointer;
                    }
                    .right-bottom-circle{
                        position: absolute;
                        bottom: 22%;
                        right: -13%;
                        cursor: pointer;
                    }
                    .img-small{
                        width: 122px;
                        height: 122px;
                        animation: imgChangeToSmall 1s;
                        -webkit-animation: imgChangeToSmall 1s; /* Safari 与 Chrome */
                    }
                    .img-big{
                        width: 156px;
                        height: 156px;
                        animation: imgChangeToBig 1s;
                        -webkit-animation: imgChangeToBig 1s; /* Safari 与 Chrome */
                    }
                }
            }
            .page3-content{
                height: 100%;
                width: 1080px;
                margin: 0 auto;
                box-sizing: border-box;
                margin-top: 15vh;
                position: relative;
                top: -72px;
                .title{
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 56px;
                    letter-spacing: 1px;
                    margin-bottom: 3vh;
                }
                .botttom-border{
                    background-color: #4970E0;
                    width: 116px;
                    border-radius: 2px;
                    border: 2px solid #4970E0;
                    margin-bottom: 6vh;
                }
                .content-box{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .card-item{
                        width: 340px;
                        height: 232px;
                        margin-bottom: 30px;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        .card-content{
                            margin-top: 172px;
                            width: 340px;
                            height: 60px;
                            background: rgba(0, 0, 0, 0.45);
                            color: #FFFFFF;
                            overflow: hidden;
                            &:hover{
                                animation: cardHeightChange 1s;
                                -webkit-animation: cardHeightChange 1s; /* Safari 与 Chrome */
                                height: 232px;
                                margin-top: 0px;
                                .card-title{
                                    padding-top: 28px;
                                    padding-bottom: 12px;
                                }
                            }
                            .card-title{
                                height: 60px;
                                font-size: 20px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                line-height: 60px;
                                text-align: center;
                            }
                            .card-text{
                                padding: 0px 40px;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 24px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .page4-content{
                height: 100%;
                width: 1080px;
                margin: 0 auto;
                box-sizing: border-box;
                margin-top: 15vh;
                position: relative;
                top: -72px;
                .title{
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 56px;
                    letter-spacing: 1px;
                    margin-bottom: 3vh;
                }
                .botttom-border{
                    background-color: #4970E0;
                    width: 116px;
                    border-radius: 2px;
                    border: 2px solid #4970E0;
                    margin-bottom: 6vh;
                }
            }
            .page5-content{
                height: 100%;
                width: 1080px;
                margin: 0 auto;
                box-sizing: border-box;
                margin-top: 10vh;
                position: relative;
                top: -72px;
                overflow: auto;
                .title{
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 56px;
                    letter-spacing: 1px;
                    margin-bottom: 3vh;
                }
                .botttom-border{
                    background-color: #4970E0;
                    width: 116px;
                    border-radius: 2px;
                    border: 2px solid #4970E0;
                    margin-bottom: 3vh;
                }
                .logos-box{
                    height: 136px;
                    width: 1060px;
                    overflow: hidden;
                    margin-bottom: 3vh;
                    .logo-item{
                        width: 136px;
                        height: 136px;
                        margin: 0px 20px;
                        img{
                            width: 136px;
                            height: 136px;
                        }
                    }
                }
                .new-box{
                    display: flex;
                    justify-content: space-between;
                    .latest-new-box{
                        width: 436px;
                        height: 267px;
                        box-shadow: 0px 4px 11px 1px rgba(0,0,0,0.0700);
                        border-radius: 4px;
                        cursor: pointer;
                        .news-img{
                            width: 436px;
                            height: 180px;
                            img{
                                width: 436px;
                                height: 180px;
                            }
                        }
                        .content-box{
                            padding: 16px;
                            .title{
                                height: 25px;
                                font-size: 18px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #151515;
                                line-height: 25px;
                                margin-bottom: 12px;
                            }
                            .time{
                                height: 18px;
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: rgb(198, 198, 198);
                                line-height: 18px;
                            }
                        }
                    }
                    .other-news-box{
                        width: 591px;
                        .page5-new-box{
                            height: 54px;
                            border-bottom: 2px solid #F2F2F2;
                            padding: 32px 0px 12px 0px;
                            .title{
                                width: 591px;
                                height: 21px;
                                font-size: 15px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #151515;
                                line-height: 21px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-bottom: 16px;
                            }
                            .bottom-box{
                                display: flex;
                                justify-content: space-between;
                                .time{
                                    height: 17px;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #adadad;
                                    line-height: 17px;
                                }
                                .view-btn{
                                    height: 17px;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #4970E0;
                                    line-height: 17px;
                                    cursor: pointer;
                                    i{
                                        color: #4970E0;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .page5-content::-webkit-scrollbar { width: 0 !important }
            .header-box{
                height: 72px;
                .menu-img-box{
                    height: 72px;
                    display: flex;
                    align-items: center;
                    margin-left: 24px;
                    img{
                        width: 81px;
                        height: 25px;
                        cursor: pointer;
                    }
                }
                .header-web-open{
                    position: relative;
                    bottom: 72px;
                }
            }
        }
    }
    .home-page-mob{
        padding-top: 40px;
        box-sizing: border-box;
        .home-content{
            width: 100%;
            .content-item-title{
                height: 25px;
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                margin-bottom: 10px;
            }
            .content-item-line{
                width: 70px;
                height: 4px;
                border-radius: 2px;
                background: #4970E0;
                margin-bottom: 20px;
            }
            .video-box{
                width: 100%;
                height: 270px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding-top: 70px;
                box-sizing: border-box;
                .banner-text{
                    margin-bottom: 12px;
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 25px;
                    letter-spacing: 1px;
                    text-align: center;
                }
            }
            .pages-box{
                width: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 20px 15px;
                box-sizing: border-box;
                .content-item-intru{
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
                    height: 44px;
                }
                .content-item-content{
                    width: 242px;
                    height: 263px;
                    margin: 0 auto;
                    padding-top: 100px;
                    box-sizing: border-box;
                    position: relative;
                    margin-bottom: 28px;
                    .content-btns{
                        margin: 0 auto;
                        width: 162px;
                        height: 162px;
                        background: #FFFFFF;
                        border-radius: 81px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                    }
                    .img-small1{
                        width: 62px;
                        height: 62px;
                        bottom: 150px;
                        left: 90px;
                        animation: imgChangeToSmallMob1 1s;
                        -webkit-animation: imgChangeToSmallMob1 1s; /* Safari 与 Chrome */
                    }
                    .img-big1{
                        width: 96px;
                        height: 96px;
                        bottom: 150px;
                        left: 75px;
                        animation: imgChangeToBigMob1 1s;
                        -webkit-animation: imgChangeToBigMob1 1s; /* Safari 与 Chrome */
                    }
                    .img-small2{
                        width: 62px;
                        height: 62px;
                        bottom: 2px;
                        left: 5px;
                        animation: imgChangeToSmallMob2 1s;
                        -webkit-animation: imgChangeToSmallMob2 1s; /* Safari 与 Chrome */
                    }
                    .img-big2{
                        width: 96px;
                        height: 96px;
                        bottom: 2px;
                        left: -35px;
                        animation: imgChangeToBigMob2 1s;
                        -webkit-animation: imgChangeToBigMob2 1s; /* Safari 与 Chrome */
                    }
                    .img-small3{
                        width: 62px;
                        height: 62px;
                        bottom: 2px;
                        left: 175px;
                        animation: imgChangeToSmallMob3 1s;
                        -webkit-animation: imgChangeToSmallMob3 1s; /* Safari 与 Chrome */
                    }
                    .img-big3{
                        width: 96px;
                        height: 96px;
                        bottom: 2px;
                        left: 182px;
                        animation: imgChangeToBigMob3 1s;
                        -webkit-animation: imgChangeToBigMob3 1s; /* Safari 与 Chrome */
                    }
                    .top-anim-circle{
                        position: absolute;
                    }
                    .left-anim-circle{
                        position: absolute;
                    }
                    .right-anim-circle{
                        position: absolute;
                    }
                }
                .content-tags-box{
                    width: 242px;
                    margin: 0 auto;
                    .content-tag-item{
                        margin-top: 12px;
                        width: 100%;
                        height: 32px;
                        text-align: center;
                        line-height: 32px;
                        color: #FFFFFF;
                        background: #4970E0;
                        cursor: pointer;
                        font-size: 13px;
                    }
                }
            }
            .solution-box-mob{
                width: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 20px 15px;
                box-sizing: border-box;
                .solution-card-box{
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .solution-card-item{
                        width: 150px;
                        .solution-card-img{
                            width: 150px;
                            height: 90px;
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            padding-top: 58px;
                            box-sizing: border-box;
                            .solution-card-title{
                                height: 32px;
                                line-height: 32px;
                                text-align: center;
                                color: #FFFFFF;
                                background-color: rgba(0, 0, 0, 0.4);
                                font-size: 14px;
                            }
                        }
                        .solution-card-intru{
                            margin-top: 10px;
                            margin-bottom: 20px;
                            padding: 0 2px;
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #151515;
                            line-height: 18px;
                            text-align: center;
                        }
                    }
                }
            }
            .case-box-mob{
                width: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 20px 15px;
                box-sizing: border-box;
                .case-img-box{
                    margin: 0 auto;
                    padding-top: 8px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    img{
                        height: 240px;
                    }
                }
            }
            .friend-news-box-mob{
                padding: 20px 15px;
                .logos-box-mob{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0px 15px;
                    margin-bottom: 40px;
                    img{
                        width: 66px;
                        height: 66px;
                        margin-right: 10px;
                    }
                }
                .news-box-mob{
                    width: 100%;
                    .first-new-box{
                        width: 100%;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 11px 1px rgba(0,0,0,0.07);
                        border-radius: 4px;
                        margin-bottom: 20px;
                        .first-new-img{
                            width: 100%;
                        }
                        .first-new-intru{
                            width: 100%;
                            padding: 12px 15px;
                            box-sizing: border-box;
                            .first-new-title{
                                height: 21px;
                                font-size: 15px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #151515;
                                line-height: 21px;
                            }
                            .first-new-time{
                                margin-top: 8px;
                                height: 18px;
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #151515;
                                line-height: 18px;
                            }
                        }
                    }
                    .other-news-box-mob{
                        width: 100%;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #F2F2F2;
                        .other-news-title{
                            width: 100%;
                            height: 21px;
                            font-size: 15px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #151515;
                            line-height: 21px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: 8px;
                            white-space: nowrap;
                        }
                        .other-news-info{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .other-news-time{
                                height: 18px;
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #151515;
                                line-height: 18px;
                            }
                            .other-news-link{
                                img{
                                    width: 67px;
                                }
                            }
                        }
                    }
                    .last-news-box-mob{
                        padding: 0;
                        margin: 0;
                        border: none;
                    }
                }
            }
        }
    }
</style>
