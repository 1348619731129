<!-- 客户案例组件 -->
<template>
    <div class="customer-case-comp">
        <div @mouseenter="changeImg(0)">
            <img :src="imgs[0].riceImgSmall" v-show="!imgs[0].showBig" :class="!imgs[0].showBig?'img-card-change-small':''">
            <img :src="imgs[0].riceImgbig" v-show="imgs[0].showBig" :class="imgs[0].showBig?'img-card-change':''">
        </div>
        <div @mouseenter="changeImg(1)">
            <img :src="imgs[1].carbImgSmall" v-show="!imgs[1].showBig" :class="!imgs[1].showBig?'img-card-change-small':''">
            <img :src="imgs[1].carbImgbig" v-show="imgs[1].showBig" :class="imgs[1].showBig?'img-card-change':''">
        </div>
        <div @mouseenter="changeImg(2)">
            <img :src="imgs[2].teaImgSmall" v-show="!imgs[2].showBig" :class="!imgs[2].showBig?'img-card-change-small':''">
            <img :src="imgs[2].teaImgbig" v-show="imgs[2].showBig" :class="imgs[2].showBig?'img-card-change':''">
        </div>
        <div @mouseenter="changeImg(3)">
            <img :src="imgs[3].drinkImgSmall" v-show="!imgs[3].showBig" :class="!imgs[3].showBig?'img-card-change-small':''">
            <img :src="imgs[3].drinkImgbig" v-show="imgs[3].showBig" :class="imgs[3].showBig?'img-card-change':''">
        </div>
    </div>
</template>

<script>
import "../assets/css/animate.css";
export default {
    name: 'CustomerCase',
    data() {
        return {
            // 第四页的图片
            imgs: [
                {
                    riceImgSmall: require("../assets/img/page4-rice.png"),
                    riceImgbig: require("../assets/img/page4-rice-cover.png"),
                    showBig: false
                },
                {
                    carbImgSmall: require("../assets/img/page4-crab.png"),
                    carbImgbig: require("../assets/img/page4-crab-cover.png"),
                    showBig: true
                },
                {
                    teaImgSmall: require("../assets/img/page4-tea.png"),
                    teaImgbig: require("../assets/img/page4-tea-cover.png"),
                    showBig: false
                },
                {
                    drinkImgSmall: require("../assets/img/page4-drink.png"),
                    drinkImgbig: require("../assets/img/page4-drink-cover.png"),
                    showBig: false
                },
            ],
        }
    },
    methods: {
        // 第四屏图片悬浮事件
        changeImg(i){
            for (let index = 0; index < this.imgs.length; index++) {
                const element = this.imgs[index];
                index == i ? element.showBig = true : element.showBig = false
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .customer-case-comp{
        width: 1080px;
        height: 460px;
        display: flex;
        margin: 0 auto;
        img{
            height: 460px;
        }
        .img-card-change{
            animation: imgCardChange 1s;
            -webkit-animation: imgCardChange 1s; /* Safari 与 Chrome */
        }
        .img-card-change-small{
            animation: imgCardChangeSmall 1s;
            -webkit-animation: imgCardChangeSmall 1s; /* Safari 与 Chrome */
        }
    }
</style>
