<!-- 不带三级菜单的头部组件 -->
<template>
    <div class="header-web">
        <div class="logo-box">
            <img :src="logoImg">
        </div>
        <div class="menu-box" :style="{ color: fontColor }">
            <div :class="['menu-item', currPage==1?'color-blue':'']" @click="changePage('/')" @mouseenter="cancelHeaderMenuMore">首页</div>
            <!-- 鼠标悬浮事件 -->
             
            <div :class="['menu-item', currPage==2?'color-blue':'']" @mouseenter="openHeaderMenu(1)">产品中心<i class="el-icon-arrow-down"></i></div>
            <div :class="['menu-item', currPage==3?'color-blue':'']" @mouseenter="openHeaderMenu(2)">解决方案<i class="el-icon-arrow-down"></i></div>
            <div :class="['menu-item', currPage==4?'color-blue':'']" @click="changePage('/pages/customerCase')" @mouseenter="cancelHeaderMenuMore">客户案例</div>
            <div :class="['menu-item', currPage==5?'color-blue':'']" @mouseenter="openHeaderMenu(4)">了解IF在线<i class="el-icon-arrow-down"></i></div>
            <div :class="['menu-item', currPage==6?'color-blue':'']" @mouseenter="cancelHeaderMenuMore" @click="changePage('/contactUs')">联系我们</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderWeb',
    mounted() {
        this.changePageColor(this.$router.currentRoute.path)
    },
    data() {
        return {
            currPage: ''
        }
    },
    props:{
        fontColor: {
            default: 'black'
        },
        logoImg: {
            default: require("../../assets/img/ifweline-logo-blue.png")
        }
    },
    watch:{
        $route(to){
            let path = to.path
            this.changePageColor(path)
        }
    },
    methods: {
        openHeaderMenu(index){
            this.$emit('openHeaderMenu', index)
        },
        cancelHeaderMenuMore(){
            this.$emit('cancelHeaderMenuMore')
        },
        changePage(path){
            this.scrollToTop()
            this.$router.push(path)
        },
        scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
        },
        changePageColor(path){
            if(path == '/'){
                this.currPage = 1
            }else if(path == '/pages/saasPlatform'||path == '/pages/digitalCollPlatform'||path == '/pages/enterpriseMngPlatform'||path == '/pages/projectMngSystem'||
                path == '/pages/knowledgeMngSystem'||path == '/pages/energyMngSystem'||path == '/pages/supplierQualityAnalysisSystem'||path == '/pages/supplierCapacityMonitoringSystem'||
                path == '/pages/supplierEvaluationSystem'||path == '/pages/intelManuCollege'
            ){
                this.currPage = 2
            }else if(path == '/pages/digiAgriculSolu'||path == '/pages/industManuSolu'||path == '/pages/socialMngSolutions'||path == '/pages/businessMngSolutions'||
                path == '/pages/goveCounSolution'||path == '/pages/productLifeMngSolution'||path == '/pages/digitalProcessSolutions'||path == '/pages/digitalFactorySolutions'
            ){
                this.currPage = 3
            }else if(path == '/pages/customerCase'){
                this.currPage = 4
            }else if(path == '/pages/konwIfWeline'||path == '/pages/newsCenter'){
                this.currPage = 5
            }else if(path == '/contactUs'){
                this.currPage = 6
            }
        }
    },
}
</script>

<style scoped lang="scss">
    .header-web{
        height: 72px;
        width: 1080px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-box{
            width: 90px;
            height: 40px;
            img{
                width: 90px;
                height: 40px;
            }
        }
        .menu-box{
            display: flex;
            .menu-item{
                margin-left: 40px;
                height: 24px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                line-height: 24px;
                cursor: pointer;
                &:hover{
                    color: #4970E0;
                }
                i{
                    margin-left: 5px;
                }
            }
            .color-blue{
                color: #4970E0;
            }
        }
    }
</style>
