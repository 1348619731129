<!-- 产品中心，智能制造学院，产品优势组件 -->
<template>
    <div class="product-advantages-item">
        <div class="content" :style="{ backgroundImage: `url(${info.imgUrl})` }">
            <div class="content-box">
                {{ info.content }}
            </div>
        </div>
        <div class="title">{{ info.title }}</div>
    </div>
</template>

<script>
import '../assets/css/animate.css'
export default {
    name: 'ProductAdvantagesItemIntelManuCollege',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        width: 240px;
        height: 310px;
        .content{
            width: 240px;
            height: 260px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            position: relative;
            &:hover{
                .content-box{
                    visibility: visible;
                    animation: textShowFromBottomIntelManuCollege 1s;
                    -webkit-animation: textShowFromBottomIntelManuCollege 1s; /* Safari 与 Chrome */
                }
            }
            .content-box{
                height: 260px;
                width: 240px;
                background: rgba($color: #05206D, $alpha: 0.7);
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 36px;
                padding: 20px 20px 0px;
                visibility: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                display: flex;
                align-items: center;
                transform: translate(-50%,-50%);
                box-sizing: border-box;
            }
        }
        .title{
            width: 240px;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            text-align: center;
            box-shadow: 3px 2px 8px -2px rgba(0,0,0,0.14);
            border-radius: 0px 0px 4px 4px;
        }
    }
</style>
