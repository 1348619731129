<!-- 产品中心，供应商动态评价系统，应用价值组件 -->
<template>
    <div class="mob-application-value-item" :style="{ backgroundImage: `url(${info.bgMobImg})` }">
        <div class="title">{{ info.title }}</div>
        <div class="text">{{ info.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'MobapplicationValueSupplierEvaluationSystem',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .mob-application-value-item{
        width: 50%;
        height: 310px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 90px 20px 0px 20px;
        box-sizing: border-box;
        .title{
            text-align: center;
            height: 45px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 15px;
        }
        .text{
            text-align: center;
            height: 120px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgb(104, 104, 104);
            line-height: 20px;
        }
    }
</style>