<!-- 产品中心，智能制造学院，产品优势组件 -->
<template>
    <div class="product-advantages-item">
        <div class="content" :style="{ backgroundImage: `url(${info.imgUrl})` }"></div>
        <div class="title">{{ info.title }}</div>
        <div class="content-box">{{ info.content }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProductAdvantagesItemIntelManuCollege',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        width: 240px;
        height: 310px;
        @media screen and (max-width: 600px) {
            width: calc(50% - 20px);
            padding: 0 10px;
            height: auto;
        }
        .content{
            width: 240px;
            height: 260px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            position: relative;
            @media screen and (max-width: 600px) {
              width: auto;
              height: 200px;
            }
            &:hover{
                .content-box{
                    visibility: visible;
                    animation: textShowFromBottomIntelManuCollege 1s;
                    -webkit-animation: textShowFromBottomIntelManuCollege 1s; /* Safari 与 Chrome */
                }
            }
        }
        .title{
            width: 240px;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            text-align: center;
            box-shadow: 3px 2px 8px -2px rgba(0,0,0,0.14);
            border-radius: 0px 0px 4px 4px;
            @media screen and (max-width:600px) {
                width: auto;
                height: auto;
            }
        }
        .content-box{
            font-size: 14px;
            padding: 10px;
            line-height: 1.6;
            letter-spacing: 1px;
            color: #151515;
        }
    }
</style>
