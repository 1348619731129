<!-- 社会管理解决方案，行业趋势组件 -->
<template>
    <div class="industry-trends-item">
        <div class="img-box"><img :src="info.imgUrl"></div>
        <div class="title">{{ info.title }}</div>
        <div class="text">{{ info.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'IndustryTrendsSocialMngSolutions',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .industry-trends-item{
        margin: 0px 100px 60px;
        box-sizing: border-box;
        width: 300px;
        text-align: center;
        @media screen and (max-width: 600px) {
            margin: 0 auto 60px;
        }
        .img-box{
            width: 46px;
            height: 46px;
            margin: 0 auto;
            img{
                width: 46px;
                height: 46px;
            }
        }
        .title{
            margin-top: 16px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4970E0;
            line-height: 28px;
        }
        .text{
            margin-top: 16px;
            width: 300px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #151515;
            line-height: 25px;
        }
    }
</style>
