<!-- 政府监管防伪追溯解决方案 -->
<template>
  <div>
    <div class="gove-coun-solution">
        <BannerWeb :info="bannerInfo" :textWidth="995"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="businessChallenges"></a>
        <div class="business-challenges">
            <div class="title">业务挑战</div>
            <div class="business-challenges-box">
                <ProductAdvantagesItemDigitalCollPlatform v-for="(item, index) in businessChallengesInfo" :info="item" :key="index"
                    :height="'160px'" :paddingTop="'36px'"
                ></ProductAdvantagesItemDigitalCollPlatform>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box">
                    <ApplicationValueSupplierEvaluationSystem v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"></ApplicationValueSupplierEvaluationSystem>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <div class="customer-case">
            <div class="title">客户案例</div>
            <div class="customer-case-box">
                <div class="customer-case-comp">
                    <div @mouseenter="changeImg(0)">
                        <img :src="imgs[0].riceImgSmall" v-show="!imgs[0].showBig" :class="!imgs[0].showBig?'img-card-change-small':''">
                        <img :src="imgs[0].riceImgbig" v-show="imgs[0].showBig" :class="imgs[0].showBig?'img-card-change':''">
                    </div>
                    <div @mouseenter="changeImg(1)">
                        <img :src="imgs[1].carbImgSmall" v-show="!imgs[1].showBig" :class="!imgs[1].showBig?'img-card-change-small':''">
                        <img :src="imgs[1].carbImgbig" v-show="imgs[1].showBig" :class="imgs[1].showBig?'img-card-change':''">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 移动端 -->
    <div class="gove-coun-solutio_mob">
        <BannerWeb :info="bannerInfo" :textWidth="995"></BannerWeb>
        <div class="business-challenges_mob">
            <div class="title_mob">业务挑战</div>
            <div class="business-challenges-box_mob">
                <ProductAdvantagesItemDigitalCollPlatform v-for="(item, index) in businessChallengesInfo" :info="item" :key="index"
                    :height="'160px'" :paddingTop="'36px'"
                ></ProductAdvantagesItemDigitalCollPlatform>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureMobImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <MobapplicationValueSupplierEvaluationSystem v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"></MobapplicationValueSupplierEvaluationSystem>
                </div>
            </div>
        </div>
        <div class="customer-case_mob">
            <div class="title_mob">客户案例</div>
            <div class="customer-case-box_mob">
                <div class="customer-case-comp_mob">
                    <img :src="mobImgs.riceImg" />
                    <img :src="mobImgs.carbImg" />
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ApplicationValueSupplierEvaluationSystem from '../components/application-value-SupplierEvaluationSystem'
import MobapplicationValueSupplierEvaluationSystem from '../components/mobApplication-value-SupplierEvaluationSystem.vue'
import ProductAdvantagesItemDigitalCollPlatform from "../components/product-advantages-item-DigitalCollPlatform.vue";
export default {
    name: 'SupplierCapacityMonitoringSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/gove-coun-solution/banner.png"),
                title: '政府监管防伪追溯解决方案',
                text: '利用互联网+、大数据、先进的防伪溯源验证等信息技术手段，发挥先进防伪溯源技术作用，形成“来源可查、去向可追、责任可究”的信息链条，为政府部门提供假冒情况监测、态势分析等信息服务，打造地方产品追溯平台'
            },
            anchorLinks: [
                {
                    name: '业务挑战',
                    link: 'businessChallenges'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            businessChallengesInfo: [
                {
                    imgUrl: require("../assets/img/gove-coun-solution/business-challenges-img1.png"),
                    title: '假冒伪劣产品的恶劣影响',
                    text: '市场上造假、知假、售假的行为屡禁不止，假冒伪劣产品的出现即影响了企业的利益和品牌形象，也危害着消费者的健康。'
                },
                {
                    imgUrl: require("../assets/img/gove-coun-solution/business-challenges-img2.png"),
                    title: '窜货行为影响市场',
                    text: '许多企业实行区域化销售，针对不同的地区，不同的渠道商采取不同的价格政策，窜货行为无法监管，影响市场。'
                },
                {
                    imgUrl: require("../assets/img/gove-coun-solution/business-challenges-img3.png"),
                    title: '产品质量、食品安全问题得不到保证',
                    text: '购物当中，消费者最关心的就是产品的质量问题以及食品的安全问题，生产环节信息无处可查。'
                },
                {
                    imgUrl: require("../assets/img/gove-coun-solution/business-challenges-img4.png"),
                    title: '缺乏公信力的平台',
                    text: '很多大型企业自有一套防伪体系，但追溯体系仍停留在表面，并且在消费者中社会公信力比较低。'
                },
            ],
            schemeStructureMobImg: require("../assets/img/gove-coun-solution/scheme-structure-mob.png"),
            schemeStructureImg: require("../assets/img/gove-coun-solution/scheme-structure.png"),
            schemeAdvantagesBgImg: require("../assets/img/gove-coun-solution/scheme-advantages-bgimg.png"),
            schemeAdvantagesInfos: [
                {
                    bgimg: require("../assets/img/gove-coun-solution/scheme-advantages-img1.png"),
                    bgMobImg: require("../assets/img/gove-coun-solution/scheme-advantages-img1-mob.png"),
                    title: '追溯产品，方便监管',
                    text: '政府可快速跟踪问题商品的流通痕迹，迅速发现问题所在，及时对问题商品进行封存或召回，将危害限制在最小范围'
                },
                {
                    bgimg: require("../assets/img/gove-coun-solution/scheme-advantages-img2.png"),
                    bgMobImg: require("../assets/img/gove-coun-solution/scheme-advantages-img2-mob.png"),
                    title: '市场良性竞争',
                    text: '遏制假货鉴别真伪，保护企业的产权，企业的市场竞争力得到提升，有利于市场良性竞争和发展。'
                },
                {
                    bgimg: require("../assets/img/gove-coun-solution/scheme-advantages-img3.png"),
                    bgMobImg: require("../assets/img/gove-coun-solution/scheme-advantages-img3-mob.png"),
                    title: '保护消费者权益',
                    text: '“验证平台”统一防伪溯源查验入口，提高社会公信力，为消费者提供便捷权威的验证服务，信息透明化，保护消费者权益。'
                },
                {
                    bgimg: require("../assets/img/gove-coun-solution/scheme-advantages-img4.png"),
                    bgMobImg: require("../assets/img/gove-coun-solution/scheme-advantages-img4-mob.png"),
                    title: '有利于社会共治',
                    text: '以“依法治理、打建结合、技术支撑、统筹协作、社会共治”为原则，充分发挥第三方机构、产业领域和消费者的积极性，开创防伪打假社会共治的新局面。'
                }
            ],
            imgs: [
                {
                    riceImgSmall: require("../assets/img/gove-coun-solution/rice.png"),
                    riceImgbig: require("../assets/img/gove-coun-solution/rice-cover.png"),
                    showBig: false
                },
                {
                    carbImgSmall: require("../assets/img/gove-coun-solution/crab.png"),
                    carbImgbig: require("../assets/img/gove-coun-solution/crab-cover.png"),
                    showBig: true
                },
            ],
            mobImgs: {
                riceImg: require("../assets/img/gove-coun-solution/rice-cover-mob.png"),
                carbImg: require("../assets/img/gove-coun-solution/crab-cover-mob.png")
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemDigitalCollPlatform, ApplicationValueSupplierEvaluationSystem, MobapplicationValueSupplierEvaluationSystem
    },
    methods: {
        changeImg(i){
            for (let index = 0; index < this.imgs.length; index++) {
                const element = this.imgs[index];
                index == i ? element.showBig = true : element.showBig = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .gove-coun-solution {
        display: block;
    }
    .gove-coun-solution_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .gove-coun-solution {
        display: none;
    }
    .gove-coun-solution_mob {
        display: block;
    }
}
.gove-coun-solution{
    width: 100%;
    min-width: 1440px;
    .business-challenges{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .business-challenges-box{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            height: 350px;
            width: 1010px;
            margin: 0 auto;
            img{
                height: 350px;
                width: 1010px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px 27px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-repeat: no-repeat;
                background-size: 100% 266px;
                border-radius: 0px 0px 4px 4px;
            }
        }
    }
    .customer-case{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .customer-case-comp{
            width: 1080px;
            height: 460px;
            display: flex;
            margin: 0 auto;
            img{
                height: 460px;
            }
            .img-card-change{
                animation: imgCardChangeGove 1s;
                -webkit-animation: imgCardChangeGove 1s; /* Safari 与 Chrome */
            }
            .img-card-change-small{
                animation: imgCardChangeSmallGove 1s;
                -webkit-animation: imgCardChangeSmallGove 1s; /* Safari 与 Chrome */
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.gove-coun-solutio_mob {
    .business-challenges_mob, .scheme-structure_mob, .scheme-advantages_mob, .customer-case_mob {
        .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
        }
        .img-box_mob {
            padding: 0 10px;
            img {
                width: 100%;
            }
        }
        .business-challenges-box_mob {
            margin: 0 auto;
            width: 345px;
        }
        .values-box_mob {
            display: flex;
            flex-wrap: wrap;
        }
        .customer-case-comp_mob {
            display: flex;
            width: calc(100% - 20px);
            padding: 10px;
            img {
                &:first-child {
                   width: 40%;
                }
                &:last-child {
                    width: 60%;
                }
            }
        }
    }
}
</style>
