<!-- 产品中心，供应商质量分析系统，产品优势组件 -->
<template>
    <div class="product-advantages-item">
        <div class="img-box"><img :src="info.imgUrl"></div>
        <div class="title">{{ info.title }}</div>
        <div class="text">{{ info.text }}</div>
        <div class="bottom-border"></div>
    </div>
</template>

<script>
export default {
    name: 'ProductAdvantagesItemSupplierQualityAnalysis',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        box-sizing: border-box;
        width: 460px;
        height: 300px;
        padding-top: 32px;
        box-shadow: 2px 2px 15px -1px rgba(0,0,0,0.08);
        text-align: center;
        border-radius: 4px;
        margin-bottom: 48px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
            margin: 0 10px;
            margin-bottom: 20px;
            width: calc(50% - 20px);
            padding-bottom: 20px;
            height: auto;
        }
        &:hover{
            padding-top: 31px;
            border: 1px solid #4970E0;
            @media screen and (max-width: 600px) {
                padding-top: 32px;
                border: none;
            }
            .bottom-border{
                visibility: visible;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }
        .img-box{
            width: 66px;
            height: 66px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 40px;
                height: 40px;
            }
            img{
                width: 66px;
                height: 66px;
                @media screen and (max-width: 600px) {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .title{
            margin: 0 auto;
            margin-top: 28px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4970E0;
            line-height: 28px;
            margin-bottom: 16px;
            @media screen and (max-width: 600px) {
                height: 21px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4970E0;
                line-height: 21px;
                text-align: center;
                margin: 15px 0;
            }
        }
        .text{
            margin: 0 auto;
            width: 280px;
            height: 123px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #151515;
            line-height: 25px;
            opacity: 0.7;
            @media screen and (max-width: 600px) {
                width: auto;
                padding: 0px 10px;
                height: 125px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 25px;
                height: auto;
            }
        }
        .bottom-border{
            visibility: hidden;
            width: 164px;
            height: 6px;
            background: #4970E0;
            border-radius: 3px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }
</style>
