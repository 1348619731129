<template>
    <div class="news-card">
        <div class="img-box"><img :src="info.newsImg"></div>
        <div class="content-box">
            <div>
                <div class="title">{{ info.title }}</div>
                <div class="content-text">{{ info.content }}</div>
                <div class="bottom">
                    <div class="time">{{ info.time }}</div>
                    <div class="view-btn">
                        <a :href="info.link" target="_blank">
                            <img :src="viewBtn">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsCard',
    data() {
        return {
            viewBtn: require("../assets/img/news-center/view-btn.png")
        }
    },
    props: {
        info:{}
    },
};
</script>
<style lang="scss" scoped>
    .news-card{
        width: 1080px;
        height: 263px;
        display: flex;
        margin-bottom: 32px;
        .img-box{
            width: 288px;
            height: 263px;
            img{
                width: 288px;
                height: 263px;
            }
        }
        .content-box{
            width: 792px;
            background-color: #ffffff;
            padding: 0px 32px 0px 40px ;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .title{
                height: 68px;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 34px;
                margin-bottom: 20px;
            }
            .content-text{
                height: 60px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 28px;
                margin-bottom: 17px;
                opacity: 0.8;
            }
            .bottom{
                width: 720px;
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .time{
                    height: 28px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    line-height: 28px;
                    opacity: 0.35;
                }
                .view-btn{
                    cursor: pointer;
                    height: 34px;
                    img{
                        width: 97px;
                        height: 34px;
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
