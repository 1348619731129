<!-- 产品中心，saas平台，产品优势组件 -->
<template>
    <div class="product-advantages-item">
        <div class="img-box"><img :src="info.imgUrl"></div>
        <div class="right-content">
            <div class="title">{{ info.title }}</div>
            <div class="text">{{ info.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductAdvantagesItemSaasPlatform',
    props:{
        info: {
            require: true
        },
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        width: 520px;
        background: #FFFFFF;
        box-shadow: 3px 0px 14px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 24px 20px;
        display: flex;
        margin-bottom: 32px;
        @media screen and (max-width: 600px){
            background: #FFFFFF;
            box-shadow: 3px 0px 14px 1px rgba(0,0,0,0.06);
            border-radius: 4px;
            padding: 10px 15px;
            display: flex;
            margin: 0 15px;
            width: calc(100% - 30px);
            margin-bottom: 0;
            margin-top: 15px;
        }
        .img-box{
            width: 99px;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 20px;
            @media screen and (max-width: 600px){
                display: block;
                width: 42px;
                margin-right: 15px;
                padding-top: 34px;
                box-sizing: border-box;
            }
            img{
                width: 99px;
                @media screen and (max-width: 600px){
                    width: 42px;
                }
            }
        }
        .right-content{
            .title{
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4970E0;
                line-height: 28px;
                margin-bottom: 16px;
                @media screen and (max-width: 600px){
                    height: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #4970E0;
                    line-height: 21px;
                    margin-bottom: 10px;
                }
            }
            .text{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgb(104, 104, 104);
                line-height: 25px;
                @media screen and (max-width: 600px){
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    line-height: 20px;
                }
            }
        }
    }
</style>
