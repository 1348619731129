<!-- ifweiline等页面的logo组件 -->
<template>
    <div class="logos-comp" :style="{ backgroundImage: `url(${bgImg})` }">
        <div class="logos-box">
            <div class="logo-item" v-for="(img, index) in logos" :key="index">
                <img :src="img">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogosComp',
    data() {
        return {
            bgImg: require("../assets/img/logos-comp/bgimg.png"),
            logos: [
                require("../assets/img/logos-comp/logo1.png"),
                require("../assets/img/logos-comp/logo2.png"),
                require("../assets/img/logos-comp/logo3.png"),
                require("../assets/img/logos-comp/logo4.png"),
                require("../assets/img/logos-comp/logo5.png"),
                require("../assets/img/logos-comp/logo6.png"),
                require("../assets/img/logos-comp/logo7.png"),
                require("../assets/img/logos-comp/logo8.png"),
                require("../assets/img/logos-comp/logo9.png"),
                require("../assets/img/logos-comp/logo10.png"),
                require("../assets/img/logos-comp/logo11.png"),
                require("../assets/img/logos-comp/logo12.png"),
            ]
        }
    },
}
</script>

<style scoped lang="scss">
    .logos-comp{
        width: 100%;
        height: 424px;
        background-repeat: no-repeat;
        background-size: 1005 100%;
        padding: 60px 0px 36px 0px;
        box-sizing: border-box;
        .logos-box{
            width: 1080px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .logo-item{
                width: 160px;
                height: 140px;
                margin-bottom: 24px;
                img{
                    width: 160px;
                    height: 140px;
                }
            }
        }
    }
</style>
