<!-- 产品中心，供应商动态评价系统，应用价值组件 -->
<template>
    <div class="application-value-item" :style="{ backgroundImage: `url(${info.bgimg})` }">
        <div class="title">{{ info.title }}</div>
        <div class="text">{{ info.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'ApplicationValueSupplierEvaluationSystem',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .application-value-item{
        width: 488px;
        height: 340px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 147px 66px 0px 62px;
        box-sizing: border-box;
        margin-bottom: 16px;
        .title{
            text-align: center;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 28px;
            margin-bottom: 20px
        }
        .text{
            text-align: center;
            height: 104px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgb(104, 104, 104);
            line-height: 26px;
        }
    }
</style>
