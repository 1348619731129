<!-- 移动端脚组件 -->
<template>
    <div>
        <div class="contact-us-box" :style="{ backgroundImage: 'url(' + contactUsBgimg + ')' }">
            <div class="content-item-title">联系我们，链接数智未来</div>
            <div class="content-item-line"></div>
            <div class="contact-us-text">留下您的预约信息，我们将第一时间联系您！</div>
            <el-form :model="contactUsInfo" :rules="contactFormRules" ref="contactRuleFormMob" class="contact-ruleForm">
                <el-form-item prop="companyName">
                    <el-input v-model="contactUsInfo.companyName" placeholder="请输入您的公司名称"></el-input>
                </el-form-item>
                <el-form-item prop="name">
                    <el-input v-model="contactUsInfo.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item prop="position">
                    <el-input v-model="contactUsInfo.position" placeholder="请输入您的职位"></el-input>
                </el-form-item>
                <el-form-item prop="contact">
                    <el-input v-model="contactUsInfo.contact" placeholder="请输入您的手机号码"></el-input>
                </el-form-item>
                <div class="submit-btn" @click="commitContactInfo">提交信息</div>
            </el-form>
        </div>
        <div class="footer-mob-menu">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item :title="item.name" :name="index" v-for="(item, index) in menuList" :key="index">
                    <div class="menu-content">
                        <div class="menu-item" v-for="(ele, i) in item.child" :key="i" @click="changePage(ele)">{{ ele.name }}</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="footer-mob-qrcodes">
            <div class="qrcode-item-box" v-for="(item, index) in QrCodes" :key="index">
                <img :src="item.qrcode" class="qrcode-img">
                <div class="qrcode-name">{{ item.title }}</div>
            </div>
        </div>
        <div class="footer-footer-info">
            <div class="text1">咨询热线：</div>
            <div class="text1">400-106-2918</div>
            <div class="text2">Copyright 2022-2027 fc3de.com</div>
            <div class="text2">南京复创智能制造技术有限公司版权所有 <a href="http://beian.miit.gov.cn/">苏ICP备2022027126号-1</a></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterMob',
    data() {
        var checkPhoneNumber = (rule, value, callback) => {
            var reg = /^1[3-9]\d{9}$/
            if (value === '') {
                callback(new Error('请输入手机号码'));
            } else if (!reg.test(value)) {
                callback(new Error('手机号码格式不正确！'));
            } else {
                callback();
            }
        };
        return {
            menuList: [
                {
                    name: '首页',
                    child: [
                        {
                            name: '首页',
                            path: '/'
                        },
                    ]
                },
                {
                    name: '工业互联网平台',
                    child: [
                        {
                            name: 'SaaS防伪追溯平台',
                            path: '/pages/saasPlatform'
                        },
                        {
                            name: '数字化供应链协同平台',
                            path: '/pages/digitalCollPlatform'
                        },
                        {
                            name: '企业数字化管理平台及数据中台',
                            path: '/pages/enterpriseMngPlatform'
                        },
                    ]
                },
                {
                    name: '工业互联网技术',
                    child: [
                        {
                            name: '项目管理系统',
                            path: '/pages/projectMngSystem'
                        },
                        {
                            name: '知识管理系统',
                            path: '/pages/knowledgeMngSystem'
                        },
                        {
                            name: '能耗管理系统',
                            path: '/pages/energyMngSystem'
                        },
                        {
                            name: '供应商质量分析系统',
                            path: '/pages/supplierQualityAnalysisSystem'
                        },
                        {
                            name: '供应商产能负荷监控系统',
                            path: '/pages/supplierCapacityMonitoringSystem'
                        },
                        {
                            name: '供应商动态评价系统',
                            path: '/pages/supplierEvaluationSystem'
                        },
                    ]
                },
                {
                    name: '工业互联网人才学院',
                    child: [
                        {
                            name: '智能制造学院',
                            path: '/pages/intelManuCollege'
                        }
                    ]
                },
                {
                    name: '行业解决方案',
                    child: [
                        {
                            name: '数字化农业解决方案',
                            path: '/pages/digiAgriculSolu'
                        },
                        {
                            name: '工业制造业解决方案',
                            path: '/pages/industManuSolu'
                        },
                        {
                            name: '社会管理解决方案',
                            path: '/pages/socialMngSolutions'
                        },
                        {
                            name: '商业管理解决方案',
                            path: '/pages/businessMngSolutions'
                        }
                    ]
                },
                {
                    name: '技术解决方案',
                    child: [
                        {
                            name: '政府监管防伪追溯解决方案',
                            path: '/pages/goveCounSolution'
                        },
                        {
                            name: '产品全生命周期管理解决方案',
                            path: '/pages/productLifeMngSolution'
                        },
                        {
                            name: '数字化工艺解决方案',
                            path: '/pages/digitalProcessSolutions'
                        },
                        {
                            name: '数字化工厂解决方案',
                            path: '/pages/digitalFactorySolutions'
                        }
                    ]
                },
                {
                    name: '客户案例',
                    child: [
                        {
                            name: '客户案例',
                            path: '/pages/customerCase'
                        },
                    ]
                },
                {
                    name: '了解IF在线',
                    child: [
                        {
                            name: '新闻动态',
                            path: '/pages/newsCenter'
                        },
                        {
                            name: '关于IF在线',
                            path: '/pages/konwIfWeline'
                        },
                    ]
                },
                {
                    name: '联系我们',
                    child: [
                        {
                            name: '联系我们',
                            path: '/contactUs'
                        },
                    ]
                },
            ],
            activeName: '0',
            contactUsBgimg: require("../../assets/img/footer-bgimg.png"),
            // 第六页的提交信息
            contactUsInfo: {
                companyName: '',
                name: '',
                position: '',
                contact: '',
            },
            // 表单的校验
            contactFormRules: {
                companyName: [
                    { required: true, message: '请输入您的公司名称', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                position: [
                    { required: true, message: '请输入您的职位', trigger: 'blur' }
                ],
                contact: [
                    { validator: checkPhoneNumber, trigger: 'blur' }
                ]
            },
            // 二维码
            QrCodes: [
                {
                    title:'复创大数据',
                    qrcode: require("../../assets/img/fc-big-data.png")
                },
                {
                    title:'复创智能制造学院',
                    qrcode: require("../../assets/img/fc-school.png")
                },
                {
                    title:'复创智云',
                    qrcode: require("../../assets/img/fc-cloud.png")
                },
            ],
        }
    },
    methods: {
        // 提交咨询信息
        commitContactInfo(){
            this.$refs['contactRuleFormMob'].validate((valid) => {
                if (valid) {
                    this.$axios.post('ifweline/fcapi/contactUs/addItem',{
                        tenantId: 1,
                        companyName: this.contactUsInfo.companyName,
                        name: this.contactUsInfo.name,
                        contact: this.contactUsInfo.contact,
                        position: this.contactUsInfo.position,
                    }).then(res => {
                        if(res.data&&res.data.code == 200){
                            this.contactUsInfo = {
                                companyName: '',
                                name: '',
                                position: '',
                                contact: '',
                            }
                            Message.success({offset: 92, message: "预约成功！请耐心等候消息。"})
                        }else{
                            Message.error({offset: 92, message: "预约失败！" + res.data.message})
                        }
                    }).catch(() => {
                        Message.error({offset: 92, message: "预约失败，网络异常！请稍后重试。"})
                    })
                } else {
                    return false;
                }
            });
        },
        changePage(ele) {
            this.scrollToTop()
            this.$router.push(ele.path)
            sessionStorage.setItem('headerTitle', ele.name);
        },
        scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
        },
    }
}
</script>

<style scoped lang="scss">
    .contact-us-box{
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 20px 15px;
        box-sizing: border-box;
        width: 100%;
        .content-item-title{
            height: 25px;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            margin-bottom: 10px;
        }
        .content-item-line{
            width: 70px;
            height: 4px;
            border-radius: 2px;
            background: #4970E0;
            margin-bottom: 20px;
        }
        .contact-us-text{
            height: 22px;
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
        .contact-ruleForm{
            width: 295px;
            margin: 0 auto;
        }
        ::v-deep .el-form-item{
            margin-bottom: 12px;
        }
        ::v-deep .el-input__inner{
            height: 32px;
            background: #FFFFFF;
            border-radius: 2px;
        }
        .submit-btn{
            height: 32px;
            background: #4970E0;
            border-radius: 2px;
            text-align: center;
            cursor: pointer;
            color: #FFFFFF;
            line-height: 32px;
            margin-top: 18px;
        }
        ::v-deep .el-form-item__error{
            padding-top: 0px;
        }
    }
    .footer-mob-menu{
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        margin-bottom: 30px;
        ::v-deep .el-collapse-item__header{
            height: 42px;
            line-height: 42px;
        }
        ::v-deep .el-collapse-item__content{
            padding-bottom: 0px;
        }
        .menu-content{
            box-sizing: border-box;
            padding: 0px 12px;
            background: #F9F9F9;
            .menu-item{
                height: 38px;
                line-height: 38px;
                opacity: 0.7;
                border-bottom: 1px solid #FFFFFF;
            }
        }
    }
    .footer-mob-qrcodes{
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        .qrcode-item-box{
            text-align: center;
            .qrcode-img{
                width: 88px;
            }
            .qrcode-name{
                height: 18px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 18px;
            }
        }
    }
    .footer-footer-info{
        padding: 0px 15px 12px;
        .text1{
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 21px;
            text-align: center;
            margin-bottom: 8px;
        }
        .text2{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #151515;
            line-height: 17px;
            text-align: center;
            height: 17px;
            margin-top: 7px;
            a {
                color: #151515;
                text-decoration: none;
             }
            a:hover {
                color: #ba2636;
                text-decoration: underline;
            }
        }
    }
</style>
