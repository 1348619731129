<!-- 数字化工厂解决方案 -->
<template>
    <div>
        <div class="digital-factory-solutions">
        <BannerWeb :info="bannerInfo" :textWidth="1080"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="businessChallenges"></a>
        <div class="industry-trends">
            <div class="title">业务挑战</div>
            <div class="industry-trends-box">
                <ProductAdvantagesItemEnterpriseMngPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnterpriseMngPlatform>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box">
                    <SchemeAdvantagesDigitalFactorySolutions :infos="schemeAdvantagesInfos"></SchemeAdvantagesDigitalFactorySolutions>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
    <!-- 移动端 -->
    <div class="digital-factory-solutions_mob">
        <BannerWeb :info="bannerInfo" :textWidth="500"></BannerWeb>
        <div class="industry-trends_mob">
            <div class="title_mob">业务挑战</div>
            <div class="industry-trends-box_mob">
                <ProductAdvantagesItemEnterpriseMngPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnterpriseMngPlatform>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <SchemeAdvantagesDigitalFactorySolutions :infos="schemeAdvantagesInfos"></SchemeAdvantagesDigitalFactorySolutions>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
  </div>
    
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemEnterpriseMngPlatform from "../components/product-advantages-item-EnterpriseMngPlatform.vue";
import SchemeAdvantagesDigitalFactorySolutions from "../components/scheme-advantages-DigitalFactorySolutions.vue";
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'DigitalFactorySolutions',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/digital-factory-solutions/banner.png"),
                title: '数字化工厂解决方案',
                text: '数字化工厂解决方案，采用成熟、先进、兼容性强的技术构建,平台汇集数据采集、数据建模、虚拟仿真、标准接口等组件,构建数字工厂通用的规范、规则、方法,并形成平台的资源集聚能力和组件管理能力,实现数字孪生应用服务,形成可开放共享、安全稳定的平台'
            },
            anchorLinks: [
                {
                    name: '业务挑战',
                    link: 'businessChallenges'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/digital-factory-solutions/industry-trends-img1.png"),
                    title: '市场竞争激烈',
                    text: '在制造业竞争日益激烈，客户需求多样化，制造技术复杂，市场对质量和效率的要求不断提高'
                },
                {
                    imgUrl: require("../assets/img/digital-factory-solutions/industry-trends-img2.png"),
                    title: '产线布局试错成本高',
                    text: '企业容易因环节信息不匹配造成成本损失，产线布局风险大，试错成本高，且生产效率低，容易出现生产故障和风险'
                },
                {
                    imgUrl: require("../assets/img/digital-factory-solutions/industry-trends-img3.png"),
                    title: '生产及管理困难',
                    text: '设备意外停机频发，造成大量损失；数据容易被篡改，追责管理困难'
                },
                {
                    imgUrl: require("../assets/img/digital-factory-solutions/industry-trends-img4.png"),
                    title: '数字化发展趋势',
                    text: '随着数字化时代的发展，企业的运营模式也发生变化，周期越来越短、非标准化需求越来越多，对数字化的要求也越来越高'
                },
            ],
            schemeStructureImg: require("../assets/img/digital-factory-solutions/scheme-structure.png"),
            schemeAdvantagesBgImg: require("../assets/img/digital-factory-solutions/scheme-advantages-bgimg.png"),
            schemeAdvantagesInfos: [
                {
                    title: '全要素、全流程、全业务数据集成化管控模式',
                    text: '依托统一的数据标准,采集工厂人、机、料、法、环等要素的数据,并对数据进行归集与标签化,在信息空间中建立数字工厂的虚拟镜像,建立统一数字孪生平台来打通数据流和信息流。'
                },
                {
                    title: '实现物理空间与数字空间的精确映射与精准执行',
                    text: '实现数字孪生体与实时生产过程管控、设备运行状态管控、过程质量管控和物料管控同步，提高企业整线设备的使用能力,提升生产管控水平。'
                },
                {
                    title: '建立可复用、可调用信息模型',
                    text: '形成可重复使用的各类模型，使企业具备虚实联动的能力、模型驱动生产的能力,为数字孪生打下基础。'
                },
                {
                    title: '智能化、数字化工厂管理',
                    text: '通过多系统信息流实现工厂信息全集成,时刻感知工厂运行状况,进行智能化的决策和调整,提升效率和质量,降低成本。'
                },
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/digital-factory-solutions/customer-case.png"),
                text: '复创智云数字化工厂解决方案'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemEnterpriseMngPlatform, SchemeAdvantagesDigitalFactorySolutions, CustomerCasePages
    },
}
</script>
<!-- B端 -->
<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .digital-factory-solutions {
        display: block;
    }
    .digital-factory-solutions_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .digital-factory-solutions {
        display: none;
    }
    .digital-factory-solutions_mob {
        display: block;
    }
}
.digital-factory-solutions{
    width: 100%;
    min-width: 1440px;
    .industry-trends{
        width: 1440px;
        margin: 0 auto;
        padding-top: 60px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .industry-trends-box{
            display: flex;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            height: 489px;
            width: 745px;
            margin: 0 auto;
            img{
                height: 489px;
                width: 745px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px 28px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 40px;
            }
            .values-box{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.digital-factory-solutions_mob {
    .industry-trends_mob, .scheme-structure_mob, .scheme-advantages_mob {
     .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
     }
     .industry-trends-box_mob {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
     }
    .img-box_mob {
        padding: 0 10px 10px;
        img {
            width: 100%;
        }
     }
     .values-box_mob {
        padding: 0 0 10px;
     }
   }
}
</style>