<!-- 客户案例组件,除home页面外其他只有一个客户案例的组件 -->
<template>
    <div class="customer-case">
        <div class="title">客户案例</div>
        <div class="customer-case-box" :style="{backgroundImage: `url(${info.customerCaseImg})`}">
            <div class="text">{{ info.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerCasePages',
    props: {
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .customer-case{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 60px 0px;
        @media screen and (max-width: 600px) {
            padding: 20px 15px;
            width: auto;
            box-sizing: border-box;
            height: auto;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                margin-bottom: 20px;
            }
        }
        .customer-case-box{
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 311px 0 24px 24px;
            @media screen and (max-width: 600px) {
                padding: 203px 0 12px 12px;
            }
            .text{
                height: 25px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 25px;
                @media screen and (max-width: 600px) {
                    height: 25px;
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 25px;
                }
            }
        }
    }
</style>
