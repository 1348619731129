<!-- 数字化工艺解决方案 -->
<template>
    <div>
        <div class="digital-process-solutions">
        <BannerWeb :info="bannerInfo" :textWidth="995"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="businessChallenges"></a>
        <div class="business-challenges">
            <div class="title">业务挑战</div>
            <div class="business-challenges-box">
                <ProductAdvantagesItemIntelManuCollege v-for="(item, index) in businessChallengesInfo" :info="item" :key="index"></ProductAdvantagesItemIntelManuCollege>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box">
                    <div class="img-box"><img :src="applicationValueImg1"></div>
                    <div class="introduction-item" v-for="(item, index) in applicationValueInfos" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }"
                    >
                        <div class="content-box">
                            <div class="introduction-item-title">{{ item.title }}</div>
                            <div class="introduction-item-text">{{ item.text }}</div>
                        </div>
                    </div>
                    <div class="img-box"><img :src="applicationValueImg2"></div>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
    <!-- 移动端 -->
    <div class="digital-process-solutions_mob">
        <BannerWeb :info="bannerInfo" :textWidth="500"></BannerWeb>
        <div class="business-challenges_mob">
            <div class="title_mob">业务挑战</div>
            <div class="business-challenges-box_mob">
                <MobProductAdvantagesItemIntelManuCollege v-for="(item, index) in businessChallengesInfo" :info="item" :key="index"></MobProductAdvantagesItemIntelManuCollege>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <div class="values-box-item_mob">
                    <div class="img-box-item_mob"><img :src="applicationValueImg1"></div>
                    <div class="introduction-item_mob" v-for="(item, index) in applicationValueInfos.filter((ele, eleIndex) => eleIndex < 2)" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }">
                        <div class="content-box_mob">
                            <div class="introduction-item-title_mob">{{ item.title }}</div>
                            <div class="introduction-item-text_mob">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
                <div class="values-box-item_mob">
                    <div class="img-box-item_mob"><img :src="applicationValueImg2"></div>
                    <div class="introduction-item_mob" v-for="(item, index) in applicationValueInfos.filter((ele, eleIndex) => eleIndex >= 2)" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }">
                        <div class="content-box_mob">
                            <div class="introduction-item-title_mob">{{ item.title }}</div>
                            <div class="introduction-item-text_mob">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
</div>
    
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemIntelManuCollege from '../components/product-advantages-item-IntelligentManufacturingCollege'
import MobProductAdvantagesItemIntelManuCollege from '../components/mobProduct-advantages-item-IntelligentManufacturingCollege'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'DigitalProcessSolutions',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/digital-process-solutions/banner.png"),
                title: '数字化工艺解决方案',
                text: '数字化工艺解决方案，旨在推进数字化技术应用并覆盖于产品的设计、工艺、制造全生命周期，通过工艺数字化信息平台建设，打通设计、工艺、制造之间数据流，实现上下游高效协同，从而实现企业智能制造目标'
            },
            anchorLinks: [
                {
                    name: '业务挑战',
                    link: 'businessChallenges'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            businessChallengesInfo: [
                {
                    imgUrl: require("../assets/img/digital-process-solutions/product-advantages-img1.png"),
                    title: '工艺设计效率低',
                    content: '发生设计更改时，工艺的更改落实工作费时费力。复杂装配工艺设计时，容易发生零件漏装情况，需事后验证纠错。'
                },
                {
                    imgUrl: require("../assets/img/digital-process-solutions/product-advantages-img2.png"),
                    title: '设计试错成本高',
                    content: '缺乏工艺、制造仿真手段，主要以实体样机对工艺规划过程及工艺方法进行验证。'
                },
                {
                    imgUrl: require("../assets/img/digital-process-solutions/product-advantages-img3.png"),
                    title: '工艺管理不规范',
                    content: '没有实现“工序”粒度的管理，导致更改的快速性、工艺知识重用性、工艺信息的配置性难以保证，难以实现精细化管理。'
                },
                {
                    imgUrl: require("../assets/img/digital-process-solutions/product-advantages-img4.png"),
                    title: '工艺发布手段落后',
                    content: 'ERP/MES等所需的工艺时序信息无法直接从上游信息系统中及时自动获取。工艺信息汇总手段落后、效率低、易出错。'
                }
            ],
            schemeStructureImg: require("../assets/img/digital-process-solutions/scheme-structure.png"),
            schemeAdvantagesBgImg: require("../assets/img/digital-process-solutions/scheme-advantages-bgimg.png"),
            applicationValueImg1: require("../assets/img/digital-process-solutions/scheme-advantages-img1.png"),
            applicationValueImg2: require("../assets/img/digital-process-solutions/scheme-advantages-img2.png"),
            applicationValueInfos: [
                {
                    title: '核心工艺数据（PPR）管理',
                    text: '以PPR数据模型为核心构建结构化工艺数据。实现从产品结构到制造结构、工艺路线、工艺资源等的强关联。',
                    bgColor: '#4970E0',
                    color: '#FFFFFF'
                },
                {
                    title: '数字化工艺平台',
                    text: '建设新一代数字化工艺平台，实现工艺规划设计与仿真协同。同时基于数字化工艺平台，实现项目、物料、工作流、变更管理。',
                    bgColor: '#D6E0FC',
                    color: '#151515'
                },
                {
                    title: '基于数字化样机的业务协同',
                    text: '基于数据统一、流程统一、功能统一、实时沟通、全三维可视的协同环境提高设计、工艺、制造、供应商等并行协同能力。',
                    bgColor: '#4970E0',
                    color: '#FFFFFF'
                },
                {
                    title: '实现设计-工艺-制造数据及业务的贯通',
                    text: '具备同前端研发、后端生产执行、管理系统集成能力。实现从设计到工艺，从工艺到制造、从制造到工艺的数据与业务贯通。',
                    bgColor: '#D6E0FC',
                    color: '#151515'
                },
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/digital-process-solutions/customer-case.png"),
                text: '复创智云数字化工艺解决方案'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemIntelManuCollege, CustomerCasePages, MobProductAdvantagesItemIntelManuCollege
    },
    methods: {
        changeImg(i){
            for (let index = 0; index < this.imgs.length; index++) {
                const element = this.imgs[index];
                index == i ? element.showBig = true : element.showBig = false
            }
        },
    },
}
</script>
<!-- B端 -->
<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .digital-process-solutions {
        display: block;
    }
    .digital-process-solutions_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .digital-process-solutions {
        display: none;
    }
    .digital-process-solutions_mob {
        display: block;
    }
}
.digital-process-solutions{
    width: 100%;
    min-width: 1440px;
    .business-challenges{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .business-challenges-box{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            height: 543px;
            width: 722px;
            margin: 0 auto;
            img{
                height: 543px;
                width: 722px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: white;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
            .values-box{
                display: flex;
                flex-wrap: wrap;
                .img-box{
                    width: 540px;
                    height: 236px;
                    img{
                        width: 540px;
                        height: 236px;
                    }
                }
                .introduction-item{
                    width: 270px;
                    height: 236px;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .content-box{
                            .introduction-item-text{
                                animation: textShowFromBottomIntelManuCollegeValues 1s;
                                -webkit-animation: textShowFromBottomIntelManuCollegeValues 1s; /* Safari 与 Chrome */
                                display: block;
                            }
                        }
                    }
                    .content-box{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        .introduction-item-title{
                            width: 219px;
                            font-size: 18px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            line-height: 25px;
                            margin-bottom: 18px;
                            text-align: center;
                        }
                        .introduction-item-text{
                            display: none;
                            margin: 0 auto;
                            width: 197px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            line-height: 25px;
                            height: 100px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.digital-process-solutions_mob {
    .business-challenges_mob, .scheme-structure_mob, .scheme-advantages_mob {
     .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
     }
     .industry-trends-box_mob {
        display: flex;
        flex-wrap: wrap;
     }
     .business-challenges-box_mob {
        display: flex;
        flex-wrap: wrap;
     }
    .img-box_mob {
        padding: 0 10px 10px;
        img {
            width: 100%;
        }
     }
     .values-box_mob{
        padding: 0 0 20px;
                .values-box-item_mob {
                    padding: 0 10px;
                    display: flex;
                    flex-wrap: wrap;
                    .img-box-item_mob{
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .introduction-item_mob{
                    width: 50%;
                    height: 236px;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .content-box_mob{
                            .introduction-item-text_mob{
                                animation: textShowFromBottomIntelManuCollegeValues 1s;
                                -webkit-animation: textShowFromBottomIntelManuCollegeValues 1s; /* Safari 与 Chrome */
                                display: block;
                            }
                        }
                    }  
                    .content-box_mob{
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        width: 90%;
                        .introduction-item-title_mob{
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            line-height: 25px;
                            margin-bottom: 15px;
                            text-align: center;
                        }
                        .introduction-item-text_mob{
                            display: none;
                            margin: 0 auto;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            line-height: 25px;
                            height: 100px;
                        }
                    }
                }
                }
            }
   }
}
</style>