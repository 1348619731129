import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css';
// 动画
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import router from './router'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios = axios
// 当前页面路径，方便跳转
Vue.prototype.$fcRouterPath = '/'
Vue.component(CollapseTransition.name, CollapseTransition)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
