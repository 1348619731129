<!-- 产品中心，能耗管理系统，产品优势组件 -->
<template>
    <div class="product-advantages-item" :style="{ width: width+'px' }">
        <div class="right-content" :style="{ width: textWidth+'px' }">
            <div class="title">{{ info.title }}</div>
            <div class="text" :style="{ width: textWidth+'px' }" v-for="(item, index) in info.text" :key="index">{{ item }}</div>
        </div>
        <div :class="['img-box', 'img-float-left']"><img :src="info.imgUrl"></div>
        <div style="clear: both;"></div>
    </div>
</template>

<script>
export default {
    name: 'ProductAdvantagesItemEnergyMngSystem',
    props:{
        info: {
            require: true
        },
        width: {
            default: '540'
        },
        textWidth: {
            default: '400'
        }
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 70px;
        @media screen and (max-width: 600px) {
            width: 100% !important;
            margin-bottom: 10px;
        }
        .img-float-left{
            float: left;
            margin-right: 20px;
            @media screen and (max-width: 600px) {
                margin-right: 0;
            }
        }
        .img-float-right{
            float: right;
            margin-left: 20px;
        }
        .img-box{
            width: 110px;
            height: 100%;
            display: flex;
            align-items: center;
            display: inline-block;
            @media screen and (max-width: 600px) {
                margin-left: 4px;
                width: 78px;
                display: block;
            }
            img{
                width: 110px;
                @media screen and (max-width: 600px) {
                    width: 78px;
                }
            }
        }
        .right-content{
            display: inline-block;
            padding-top: 10px;
            @media screen and (max-width: 600px) {
                width: calc(100% - 85px) !important;
            }
            .title{
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4970E0;
                line-height: 28px;
                margin-bottom: 16px;
                overflow: visible;
                @media screen and (max-width: 600px) {
                    height: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #4970E0;
                    line-height: 21px;
                    margin-bottom: 10px;
                }
            }
            .text{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgb(104, 104, 104);
                line-height: 25px;
                @media screen and (max-width: 600px) {
                    width: auto !important;
                    padding: 0 10px 0 0;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    line-height: 18px;
                }
            }
        }
    }
</style>
