<!-- 应用价值组件 -->
<template>
    <div class="application-value-mob-comp" :style="{ backgroundImage: `url(${infos.bgimg})` }">
        <div class="application-value-title">{{ infos.title }}</div>
        <div class="application-value-content">
            <div class="application-value-content-item" v-for="(item, index) in infos.intru" :info="item" :key="index"
                :style="{ backgroundImage: `url(${item.bgimg})`, width: item.width, height: item.height, color: item.color }"
            >
                <div class="content-title" :style="{ padding: item.titlePadding }">{{ item.title }}</div>
                <div class="content-text" :style="{ opacity: item.opacity, padding: item.textPadding }">{{ item.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ApplicationValueMob',
    props:{
        infos: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .application-value-mob-comp{
        padding: 20px 15px;
        width: 100%;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .application-value-title{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 25px;
            margin-bottom: 10px;
            text-align: center;
        }
        .application-value-content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .application-value-content-item{
                margin-top: 10px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                box-shadow: 3px 0px 14px 1px rgba(0,0,0,0.07);
                .content-title{
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    line-height: 20px;
                }
                .content-text{
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
</style>
