import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage.vue';
import NotHome from "../views/NotHome.vue";
import SaasPlatform from "../views/SaasPlatform.vue";
import DigitalCollPlatform from "../views/DigitalCollPlatform.vue";
import EnterpriseMngPlatform from '../views/EnterpriseMngPlatform.vue';
import ProjectMngSystem from "../views/ProjectMngSystem.vue";
import KnowledgeMngSystem from '../views/KnowledgeMngSystem.vue'
import EnergyMngSystem from '../views/EnergyMngSystem.vue'
import SupplierQualityAnalysisSystem from '../views/SupplierQualityAnalysisSystem.vue'
import SupplierCapacityMonitoringSystem from '../views/SupplierCapacityMonitoringSystem.vue'
import SupplierEvaluationSystem from '../views/SupplierEvaluationSystem.vue'
import IntelligentManufacturingCollege from '../views/IntelligentManufacturingCollege.vue'
import KonwIfWeline from '../views/KonwIfWeline.vue'
import CustomerCaseView from '../views/CustomerCaseView.vue'
import NewsCenter from '../views/NewsCenter.vue'
import ContactUs from '../views/ContactUs.vue'
import DigitalAgricultureSolutions from "../views/DigitalAgricultureSolutions.vue";
import IndustrialManufacturingSolutions from '../views/IndustrialManufacturingSolutions.vue'
import SocialMngSolutions from '../views/SocialMngSolutions.vue'
import BusinessMngSolutions from '../views/BusinessMngSolutions.vue'
import GoveCounSolution from '../views/GoveCounSolution.vue'
import ProductLifeMngtSolution from '../views/ProductLifeMngtSolution.vue'
import DigitalProcessSolutions from '../views/DigitalProcessSolutions.vue'
import DigitalFactorySolutions from '../views/DigitalFactorySolutions.vue'
import NodeServiceDetail from '../views/NodeServiceDetail.vue'
import NodeIntroduction from '../views/NodeIntroduction.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法, 取消跳转当前路由的报错
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/pages',
    name: 'pages',
    component: NotHome,
    children: [
      {
        path: 'saasPlatform',
        name: 'saasPlatform',
        component: SaasPlatform
      },
      {
        path: 'digitalCollPlatform',
        name: 'digitalCollPlatform',
        component: DigitalCollPlatform
      },
      {
        path: 'enterpriseMngPlatform',
        name: 'enterpriseMngPlatform',
        component: EnterpriseMngPlatform
      },
      {
        path: 'projectMngSystem',
        name: 'projectMngSystem',
        component: ProjectMngSystem
      },
      {
        path: 'knowledgeMngSystem',
        name: 'knowledgeMngSystem',
        component: KnowledgeMngSystem
      },
      {
        path: 'energyMngSystem',
        name: 'energyMngSystem',
        component: EnergyMngSystem
      },
      {
        path: 'supplierQualityAnalysisSystem',
        name: 'supplierQualityAnalysisSystem',
        component: SupplierQualityAnalysisSystem
      },
      {
        path: 'supplierCapacityMonitoringSystem',
        name: 'supplierCapacityMonitoringSystem',
        component: SupplierCapacityMonitoringSystem
      },
      {
        path: 'supplierEvaluationSystem',
        name: 'supplierEvaluationSystem',
        component: SupplierEvaluationSystem
      },
      {
        path: 'intelManuCollege',
        name: 'intelManuCollege',
        component: IntelligentManufacturingCollege
      },
      {
        path: 'konwIfWeline',
        name: 'konwIfWeline',
        component: KonwIfWeline
      },
      {
        path: 'customerCase',
        name: 'customerCase',
        component: CustomerCaseView
      },
      {
        path: 'newsCenter',
        name: 'newsCenter',
        component: NewsCenter
      },
      {
        path: 'digiAgriculSolu',
        name: 'digiAgriculSolu',
        component: DigitalAgricultureSolutions
      },
      {
        path: 'industManuSolu',
        name: 'industManuSolu',
        component: IndustrialManufacturingSolutions
      },
      {
        path: 'socialMngSolutions',
        name: 'socialMngSolutions',
        component: SocialMngSolutions
      },
      {
        path: 'businessMngSolutions',
        name: 'businessMngSolutions',
        component: BusinessMngSolutions
      },
      {
        path: 'goveCounSolution',
        name: 'goveCounSolution',
        component: GoveCounSolution
      },
      {
        path: 'productLifeMngSolution',
        name: 'productLifeMngSolution',
        component: ProductLifeMngtSolution
      },
      {
        path: 'digitalProcessSolutions',
        name: 'digitalProcessSolutions',
        component: DigitalProcessSolutions
      },
      {
        path: 'digitalFactorySolutions',
        name: 'digitalFactorySolutions',
        component: DigitalFactorySolutions
      },
      {
        path: 'nodeServiceDetail',
        name: 'nodeServiceDetail',
        component: NodeServiceDetail
      },
      {
        path: 'nodeIntroduction',
        name: 'nodeIntroduction',
        component: NodeIntroduction
      }
    ]
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs
  },
]

const router = new VueRouter({
  routes
})

export default router
