<!-- 了解if在线 -->
<template>
    <div class="customer-case-view">
        <BannerWebOnlytitle :info="bannerInfo"></BannerWebOnlytitle>
        <div class="datas-box">
            <div class="datas-item" v-for="(item, index) in datasInfo" :key="index">
                <div class="data-box">
                    <div class="number">{{ item.number }}</div>
                    <img :src="arrowTopImg">
                </div>
                <div class="img-box"><img :src="item.img"></div>
                <div class="title">{{ item.title }}</div>
            </div>
        </div>
        <LogosComp></LogosComp>
        <div class="customer-case-table" :style="{ backgroundImage: `url(${customerCaseBgImg})` }">
            <div class="case-card-box">
                <div class="card-item" v-for="(item, index) in caseCards" :key="index">
                    <div class="img-box"><img :src="item.imgUrl" :width="item.imgWidth" :height="item.imgHeight"></div>
                    <div class="company-name">{{ item.companyName }}</div>
                    <div class="text">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BannerWebOnlytitle from '../components/banner-web-onlytitle'
import LogosComp from '../components/logos-comp'
export default {
    name: 'CustomerCaseView',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/customer-case-page/banner.png"),
                title: ['客户 & 案例'],
            },
            datasInfo: [
                {
                    number: '3543',
                    img: require('../assets/img/customer-case-page/data-img1.png'),
                    title: '服务项目'
                },
                {
                    number: '1821',
                    img: require('../assets/img/customer-case-page/data-img2.png'),
                    title: '公共服务'
                },
                {
                    number: '2386',
                    img: require('../assets/img/customer-case-page/data-img3.png'),
                    title: '赋能企业'
                },
            ],
            arrowTopImg: require('../assets/img/customer-case-page/arrow-top.png'),
            customerCaseBgImg: require('../assets/img/customer-case-page/customer-case-bgimg.png'),
            caseCards: [
                {
                    imgUrl: require("../assets/img/customer-case-page/customer-case-img1.png"),
                    imgWidth: '50px',
                    imgHeight: '70px',
                    companyName: '安徽丰石农业科技有限公司',
                    text: '石台硒米全流程追溯解决方案'
                },
                {
                    imgUrl: require("../assets/img/customer-case-page/customer-case-img2.png"),
                    imgWidth: '254px',
                    imgHeight: '33px',
                    companyName: '中国电子科技集团公司第十四研究所',
                    text: '协同攻关中心展陈制作及系统工程'
                },
                {
                    imgUrl: require("../assets/img/customer-case-page/customer-case-img3.png"),
                    imgWidth: '172px',
                    imgHeight: '54px',
                    companyName: '北斗星通智联科技有限责任公司',
                    text: '产品全生命周期系统(PLM)实施'
                },
                {
                    imgUrl: require("../assets/img/customer-case-page/customer-case-img4.png"),
                    imgWidth: '118px',
                    imgHeight: '56px',
                    companyName: '南京钢铁股份有限公司',
                    text: '基于智能大数据分析的调质钢Q960E稳定性提升'
                },
            ],
            pageConfig:{
                pageSize: 9,
                pageIndex: 1,
            }
        }
    },
    methods: {
        pageSizeChange(pageSize){
            this.pageConfig.pageSize = pageSize
        },
        pageIndexChange(pageIndex){
            this.pageConfig.pageIndex = pageIndex
        }
    },
    components:{
        BannerWebOnlytitle, LogosComp
    }
}
</script>

<style lang="scss" scoped>
.customer-case-view{
    width: 100%;
    .datas-box{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .data-box{
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            .number{
                height: 112px;
                font-size: 80px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4970E0;
                line-height: 112px;
                margin-right: 8px;
            }
            img{
                width: 28px;
                height: 31px;
                position: relative;
                top: 16px;
            }
        }
        .img-box{
            width: 180px;
            height: 124px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #E0E8FF;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 24px;
            img{
                width: 60px;
                height: 60px;
                margin-top: 32px;
            }
        }
        .title{
            text-align: center;
            height: 25px;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 25px;
        }
    }
    .customer-case-table{
        width: 100%;
        height: 616px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 80px 0px 0px 0px;
        box-sizing: border-box;
        .case-card-box{
            width: 1080px;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .card-item{
                width: 320px;
                padding-bottom: 28px;
                margin-bottom: 52px;
                cursor: pointer;
                &:hover{
                    background-color: rgba($color: #ffffff, $alpha: 0.2);
                    border-radius: 4px;
                }
                .img-box{
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    margin: 28px 0px 24px;
                }
                .company-name{
                    height: 22px;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 22px;
                    margin-bottom: 12px;
                    text-align: center;
                }
                .text{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    text-align: center;
                    width: 210px;
                    margin-left: 55px;
                }
            }
        }
        .pagination{
            display: flex;
            justify-content: center;
            ::v-deep .active{
                background-color: #4970E0 !important;
            }
        }
    }
}
</style>
