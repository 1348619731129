<!-- 移动端头组件 -->
<template>
    <div>
        <div class="header-mob">
            <div class="seat-box"></div>
            <div class="header-title">{{ headerTitle }}</div>
            <div class="operate-img">
                <img :src="menuOpenState ? cancelImg : moreImg" @click="menuOpenState = !menuOpenState">
            </div>
        </div>
        <div v-if="menuOpenState"  :class="[ 'header-mob-open', 'header-mob-open-animate' ]">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item :title="item.name" :name="index" v-for="(item, index) in menuList" :key="index">
                    <div class="menu-content">
                        <div class="menu-item" v-for="(ele, i) in item.child" :key="i" @click="changePage(ele)">{{ ele.name }}</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderMob',
    mounted() {
        this.headerTitle = sessionStorage.getItem('headerTitle') ? sessionStorage.getItem('headerTitle') : '首页';
    },
    data() {
        return {
            moreImg: require("../../assets/img/header-mob-open.png"),
            cancelImg: require("../../assets/img/header-mob-cancel.png"),
            headerTitle: '首页',
            menuOpenState: false,
            menuList: [
                {
                    name: '首页',
                    child: [
                        {
                            name: '首页',
                            path: '/'
                        },
                    ]
                },
                {
                    name: '工业互联网平台',
                    child: [
                        {
                            name: 'SaaS防伪追溯平台',
                            path: '/pages/saasPlatform'
                        },
                        {
                            name: '数字化供应链协同平台',
                            path: '/pages/digitalCollPlatform'
                        },
                        {
                            name: '企业数字化管理平台及数据中台',
                            path: '/pages/enterpriseMngPlatform'
                        },
                    ]
                },
                {
                    name: '工业互联网技术',
                    child: [
                        {
                            name: '项目管理系统',
                            path: '/pages/projectMngSystem'
                        },
                        {
                            name: '知识管理系统',
                            path: '/pages/knowledgeMngSystem'
                        },
                        {
                            name: '能耗管理系统',
                            path: '/pages/energyMngSystem'
                        },
                        {
                            name: '供应商质量分析系统',
                            path: '/pages/supplierQualityAnalysisSystem'
                        },
                        {
                            name: '供应商产能负荷监控系统',
                            path: '/pages/supplierCapacityMonitoringSystem'
                        },
                        {
                            name: '供应商动态评价系统',
                            path: '/pages/supplierEvaluationSystem'
                        },
                    ]
                },
                {
                    name: '工业互联网人才学院',
                    child: [
                        {
                            name: '智能制造学院',
                            path: '/pages/intelManuCollege'
                        }
                    ]
                },
                {
                    name: '行业解决方案',
                    child: [
                        {
                            name: '数字化农业解决方案',
                            path: '/pages/digiAgriculSolu'
                        },
                        {
                            name: '工业制造业解决方案',
                            path: '/pages/industManuSolu'
                        },
                        {
                            name: '社会管理解决方案',
                            path: '/pages/socialMngSolutions'
                        },
                        {
                            name: '商业管理解决方案',
                            path: '/pages/businessMngSolutions'
                        }
                    ]
                },
                {
                    name: '技术解决方案',
                    child: [
                        {
                            name: '政府监管防伪追溯解决方案',
                            path: '/pages/goveCounSolution'
                        },
                        {
                            name: '产品全生命周期管理解决方案',
                            path: '/pages/productLifeMngSolution'
                        },
                        {
                            name: '数字化工艺解决方案',
                            path: '/pages/digitalProcessSolutions'
                        },
                        {
                            name: '数字化工厂解决方案',
                            path: '/pages/digitalFactorySolutions'
                        }
                    ]
                },
                {
                    name: '客户案例',
                    child: [
                        {
                            name: '客户案例',
                            path: '/pages/customerCase'
                        },
                    ]
                },
                {
                    name: '了解IF在线',
                    child: [
                        {
                            name: '新闻动态',
                            path: '/pages/newsCenter'
                        },
                        {
                            name: '关于IF在线',
                            path: '/pages/konwIfWeline'
                        },
                    ]
                },
                {
                    name: '联系我们',
                    child: [
                        {
                            name: '联系我们',
                            path: '/contactUs'
                        },
                    ]
                },
            ],
            activeName: '0',
        }
    },
    methods: {
        changePage(ele) {
            this.menuOpenState = false
            this.scrollToTop()
            this.$router.push(ele.path)
            this.headerTitle = ele.name
            sessionStorage.setItem('headerTitle', ele.name);
        },
        scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
        },
    },
    watch: {
        $route(){
            this.headerTitle = sessionStorage.getItem('headerTitle') ? sessionStorage.getItem('headerTitle') : '首页';
        }
    }
}
</script>

<style scoped lang="scss">
    .header-mob{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        width: 100%;
        background: #FFFFFF;
        z-index: 999999;
        .seat-box{
            margin-left: 15px;
            width: 16px;
            height: 16px;
        }
        .operate-img{
            margin-right: 15px;
            img{
                width: 16px;
            }
        }
    }
    .header-mob-open-animate{
        animation: headerWebOpenMob 1s;
        -webkit-animation: headerWebOpenMob 1s; /* Safari 与 Chrome */
    }
    .header-mob-open{
        padding: 0 15px;
        position: fixed;
        top: 40px;
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        z-index: 999999;
        ::v-deep .el-collapse-item__header{
            height: 38px;
            line-height: 38px;
        }
        ::v-deep .el-collapse-item__content{
            padding-bottom: 0px;
        }
        .menu-content{
            box-sizing: border-box;
            padding: 0px 12px;
            background: #F9F9F9;
            .menu-item{
                height: 38px;
                line-height: 38px;
                opacity: 0.7;
                border-bottom: 1px solid #FFFFFF;
            }
        }
    }
</style>
