<!-- 产品中心，数字化供应链协同平台，应用价值组件 -->
<template>
    <div class="application-value-item" :style="{ backgroundImage: `url(${info.bgimg})` }">
        <div class="title">{{ info.title }}</div>
        <div class="text">{{ info.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'ApplicationValueDigitalCollPlatform',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .application-value-item{
        width: 348px;
        height: 358px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 147px 66px 0px 62px;
        box-sizing: border-box;
        margin-bottom: 32px;
        @media screen and (max-width: 600px) {
            width: 193px;
            height: 256px;
            padding: 80px 30px 0px;
            box-sizing: border-box;
            margin-right: -12px;
            margin-bottom: -10px;
        }
        .title{
            text-align: center;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 28px;
            margin-bottom: 20px;
            @media screen and (max-width: 600px) {
                height: 21px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 21px;
                margin-bottom: 15px;
            }
        }
        .text{
            text-align: center;
            height: 104px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgb(104, 104, 104);
            line-height: 26px;
            @media screen and (max-width: 600px) {
                height: 108px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 18px;
                opacity: 0.75;
            }
        }
    }
</style>
