import { render, staticRenderFns } from "./mobProduct-advantages-item-IntelligentManufacturingCollege.vue?vue&type=template&id=3ee177f9&scoped=true"
import script from "./mobProduct-advantages-item-IntelligentManufacturingCollege.vue?vue&type=script&lang=js"
export * from "./mobProduct-advantages-item-IntelligentManufacturingCollege.vue?vue&type=script&lang=js"
import style0 from "./mobProduct-advantages-item-IntelligentManufacturingCollege.vue?vue&type=style&index=0&id=3ee177f9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ee177f9",
  null
  
)

export default component.exports