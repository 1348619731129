<!-- home页面打开二级菜单组件，含有三级菜单 -->
<template>
    <div class="menu-open" @mouseleave="cancelHeaderMenuMore">
        <div class="img-box" :style="{ paddingLeft: showImg?'50px':'' }">
            <img :src="cancelMenu" @click="cancelHeaderMenu" v-show="showImg" :class="[showImg?'show-img-class':'']">
            <!-- 没有图片时占位格 -->
            <div class="no-img" v-show="!showImg" :style="{ paddingLeft: !showImg?'105px':'' }"></div>
            <HeaderWeb class="header-open" @openHeaderMenu="openHeaderMenu" @cancelHeaderMenuMore="cancelHeaderMenuMore"></HeaderWeb>
        </div>
        <div v-show="showMoreMenu" :class="[ showMoreMenu&&currMoreMenuIndex==1 ? 'header-web-open-show-more1' : '', 
            showMoreMenu&&currMoreMenuIndex==2 ? 'header-web-open-show-more2' : '', showMoreMenu&&currMoreMenuIndex==4 ? 'header-web-open-show-more4' : '',
        'header-menu-more' ]">
            <div class='header-menu-more-box' :style="{paddingRight: currMoreMenuIndex == 2?'60px':'0px'}">
                <div class="menu-list-box" v-for="(item, index) in moreMenuLst" :key="index">
                    <div class="menu-title">{{ item.menuLevel2 }}</div>
                    <div class="menu-item" v-for="(itemMenu, i) in item.childMenu" :key="i" @click="changePage(itemMenu.path)"
                        :style="{ color: pagePath==itemMenu.path?'#4970E0':'black'}"
                    >{{ itemMenu.name }}</div>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderWeb from "./header-web/header-web.vue";
import "../assets/css/animate.css";

export default {
    name: 'HeaderWebOpen',
    mounted() {
        this.pagePath = this.$router.currentRoute.path
    },
    data() {
        return {
            showMoreMenu: false,
            currMoreMenuIndex: '',
            cancelMenu: require("../assets/img/cancel-menu.png"),
            moreMenuLst: [],
            pagePath:'',
            menuMore1: [
                {
                    menuLevel2: '工业互联网人才学院',
                    childMenu: [
                        {
                            name: '智能制造学院',
                            path: '/pages/intelManuCollege'
                        }
                    ]
                },
                {
                    menuLevel2: '工业互联网人才学院',
                    childMenu: [
                        {
                            name: '智能制造学院',
                            path: '/pages/intelManuCollege'
                        }
                    ]
                },
                {
                    menuLevel2: '工业互联网技术',
                    childMenu: [
                        {
                            name: '项目管理系统',
                            path: '/pages/projectMngSystem'
                        },
                        {
                            name: '知识管理系统',
                            path: '/pages/knowledgeMngSystem'
                        },
                        {
                            name: '能耗管理系统',
                            path: '/pages/energyMngSystem'
                        },
                        {
                            name: '供应商质量分析系统',
                            path: '/pages/supplierQualityAnalysisSystem'
                        },
                        {
                            name: '供应商产能负荷监控系统',
                            path: '/pages/supplierCapacityMonitoringSystem'
                        },
                        {
                            name: '供应商动态评价系统',
                            path: '/pages/supplierEvaluationSystem'
                        },
                    ]
                },
                {
                    menuLevel2: '工业互联网平台',
                    childMenu: [
                        {
                            name: 'SaaS防伪追溯平台',
                            path: '/pages/saasPlatform'
                        },
                        {
                            name: '数字化供应链协同平台',
                            path: '/pages/digitalCollPlatform'
                        },
                        {
                            name: '企业数字化管理平台及数据中台',
                            path: '/pages/enterpriseMngPlatform'
                        },
                    ]
                },
            ],
            menuMore2: [
                {
                    menuLevel2: '技术解决方案',
                    childMenu: [
                        {
                            name: '政府监管防伪追溯解决方案',
                            path: '/pages/goveCounSolution'
                        },
                        {
                            name: '产品全生命周期管理解决方案',
                            path: '/pages/productLifeMngSolution'
                        },
                        {
                            name: '数字化工艺解决方案',
                            path: '/pages/digitalProcessSolutions'
                        },
                        {
                            name: '数字化工厂解决方案',
                            path: '/pages/digitalFactorySolutions'
                        }
                    ]
                },
                {
                    menuLevel2: '行业解决方案',
                    childMenu: [
                        {
                            name: '数字化农业解决方案',
                            path: '/pages/digiAgriculSolu'
                        },
                        {
                            name: '工业制造业解决方案',
                            path: '/pages/industManuSolu'
                        },
                        {
                            name: '社会管理解决方案',
                            path: '/pages/socialMngSolutions'
                        },
                        {
                            name: '商业管理解决方案',
                            path: '/pages/businessMngSolutions'
                        }
                    ]
                },
                {
                    menuLevel2: '二级节点',
                    childMenu: [
                        {
                            name: '二级节点介绍',
                            path: '/pages/nodeIntroduction'
                        },
                        {
                            name: '服务细则',
                            path: '/pages/nodeServiceDetail'
                        }
                    ]
                }
            ],
            menuMore4: [
                {
                    menuLevel2: '新闻动态',
                    childMenu: [
                        {
                            name: '新闻动态',
                            path: '/pages/newsCenter'
                        }
                    ]
                },
                {
                    menuLevel2: '关于IF在线',
                    childMenu: [
                        {
                            name: '关于IF在线',
                            path: '/pages/konwIfWeline'
                        },
                    ]
                },
            ]
        }
    },
    components:{
        HeaderWeb
    },
    watch:{
        $route(to){
            let path = to.path
            this.pagePath = path
        }
    },
    methods: {
        // 关闭二级菜单
        cancelHeaderMenu(){
            this.$emit('cancelHeaderMenu')
        },
        // 打开三级菜单
        openHeaderMenu(index){
            this.showMoreMenu = true
            this.currMoreMenuIndex = index
            switch ( index*1 ) {
                case 1:
                    this.moreMenuLst = this.menuMore1;
                    break
                case 2:
                    this.moreMenuLst = this.menuMore2;
                    break
                case 4:
                    this.moreMenuLst = this.menuMore4;
                    break
            }
        },
        // 关闭三级菜单
        cancelHeaderMenuMore(){
            this.showMoreMenu = false
        },
        scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
        },
        changePage(path){
            this.scrollToTop();
            this.$router.push(path);
        },
    },
    props:{
        showImg: {
            default: true
        }
    }
}
</script>

<style scoped lang="scss">
    .menu-open{
        height: 72px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.0700);
        .header-web-open-show-more1{
            box-sizing: border-box;
            padding-left: 50px;
            animation: headerWebOpenShowMore1 1s;
            -webkit-animation: headerWebOpenShowMore1 1s; /* Safari 与 Chrome */
        }
        .header-web-open-show-more2{
            box-sizing: border-box;
            padding-left: 50px;
            animation: headerWebOpenShowMore2 1s;
            -webkit-animation: headerWebOpenShowMore2 1s; /* Safari 与 Chrome */
        }
        .header-web-open-show-more4{
            box-sizing: border-box;
            padding-left: 50px;
            animation: headerWebOpenShowMore4 1s;
            -webkit-animation: headerWebOpenShowMore4 1s; /* Safari 与 Chrome */
        }
        .show-img-class{
            position: relative;
            right: 50px;
        }
        .img-box{
            height: 72px;
            display: flex;
            align-items: center;
            margin-left: 24px;
            img{
                width: 81px;
                height: 25px;
                cursor: pointer;
            }
            .no-img{
                width: 81px;
                height: 25px;
            }
            .header-open{
                position: relative;
                right: 105px;
            }
        }
        .header-menu-more{
            position: fixed;
            top: 72px;
            width: 100vw;
            background: #FFFFFF;
            box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.0700);
            z-index: 10086;
            border-top: 1px solid #E0E0E0;
            .header-menu-more-box{
                box-sizing: border-box;
                width: 1080px;
                margin: 0 auto;
                padding: 24px 0px 20px;
                // display: flex;
                // justify-content: right;
                .menu-list-box{
                    margin-right: 80px;
                    height: 100%;
                    // 浏览器兼容不能使用justify-content: right;
                    float: right;
                    .menu-title{
                        height: 21px;
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 21px;
                        padding-bottom: 12px;
                        border-bottom: 1px solid #E0E0E0;
                    }
                    .menu-item{
                        height: 18px;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 18px;
                        margin: 16px 0px;
                        cursor: pointer;
                        color: rgb(104, 104, 104);
                        &:hover{
                            color: #4970E0;
                        }
                    }
                }
            }
        }
    }
</style>
