<!-- 产品全生命周期管理解决方案 -->
<template>
  <div>
    <div class="product-life-mngt-solution">
        <BannerWeb :info="bannerInfo" :textWidth="995"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="businessChallenges"></a>
        <div class="business-challenges">
            <div class="title">业务挑战</div>
            <div class="business-challenges-box">
                <ProductAdvantagesItemSupplierQualityAnalysis v-for="(item, index) in schemeStructureInfo" :info="item" :key="index"></ProductAdvantagesItemSupplierQualityAnalysis>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure">
            <div class="title">方案架构</div>
            <div class="img-box">
                <img :src="schemeStructureImg">
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="values-box" :style="{backgroundImage: `url(${schemeAdvantagesImg})`}">
                    <ApplicationValueProjectMngSystem v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"
                        @changeTextFlag="changeTextFlag"
                    ></ApplicationValueProjectMngSystem>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
    <!-- 移动端 -->
    <div class="product-life-mngt-solution_mob">
        <BannerWeb :info="Object.assign({}, bannerInfo, { bannerImg: bannerMobImg })" :textWidth="500"></BannerWeb>
        <div class="business-challenges_mob">
            <div class="title_mob">业务挑战</div>
            <div class="business-challenges-box_mob">
                <ProductAdvantagesItemSupplierQualityAnalysis v-for="(item, index) in schemeStructureInfo" :info="item" :key="index"></ProductAdvantagesItemSupplierQualityAnalysis>
            </div>
        </div>
        <div class="scheme-structure_mob">
            <div class="title_mob">方案架构</div>
            <div class="img-box_mob">
                <img :src="schemeStructureMobImg">
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${schemeAdvantagesBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="values-box_mob">
                    <div class="flex-column_mob" :style="{ backgroundImage: `url(${schemeAdvantagesMobImg})` }">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in schemeAdvantagesInfos.filter((ele, eleIndex) => eleIndex <= 1)" :key="index" :info="info"
                        @changeTextFlag="changeTextFlag"
                      ></ApplicationValueProjectMngSystem>
                    </div>
                    <div class="flex-column_mob" :style="{ backgroundImage: `url(${schemeAdvantagesMobImg})` }">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in schemeAdvantagesInfos.filter((ele, eleIndex) => eleIndex > 1 && eleIndex <= 3)" :key="index" :info="info"
                        @changeTextFlag="changeTextFlag"
                      ></ApplicationValueProjectMngSystem>
                    </div>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="Object.assign({}, customerCasePagesInfo, { customerCaseImg: customerCaseMobImg })"></CustomerCasePages>
    </div>
  </div>
    
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ApplicationValueProjectMngSystem from '../components/application-value-ProjectMngSystem.vue'
import ProductAdvantagesItemSupplierQualityAnalysis from '../components/product-advantages-item-SupplierQualityAnalysis'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'ProductLifeMngtSolution',
    data() {
        return {
            bannerMobImg: require("../assets/img/product-life-mngt-solution/banner-mob.png"),
            bannerInfo: {
                bannerImg: require("../assets/img/product-life-mngt-solution/banner.png"),
                title: '产品全生命周期管理解决方案',
                text: '产品全生命周期管理解决方案，支持从需求、规划、设计、生产、经销、运行、使用、维修保养、直到回收再用处置的全生命周期中的信息与过程进行管理。并且支持并行设计、敏捷制造、协同设计和制造,网络化制造等先进的设计制造技术'
            },
            anchorLinks: [
                {
                    name: '业务挑战',
                    link: 'businessChallenges'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            schemeStructureInfo: [
                {
                    imgUrl: require("../assets/img/product-life-mngt-solution/business-challenges-img1.png"),
                    title: '不足够重视客户真正的需求',
                    text: '产品的工程性大于产品的经济性，过分的追求技术，不足够重视客户真正的需求，使很多技术公司都会陷入的困境。'
                },
                {
                    imgUrl: require("../assets/img/product-life-mngt-solution/business-challenges-img2.png"),
                    title: '设计各端无法实时同步',
                    text: '当有多个设计者时，设计人员往往不能实时得到最新数据，无法共享设计成果。'
                },
                {
                    imgUrl: require("../assets/img/product-life-mngt-solution/business-challenges-img3.png"),
                    title: '企业自身IT系统结构的过度装备',
                    text: '很多大企业不是没有进行数字化，反而是过度数字化，过度的数字化反而会影响效率。'
                },
                {
                    imgUrl: require("../assets/img/product-life-mngt-solution/business-challenges-img4.png"),
                    title: '业务部门没有恰当的掌握技术信息',
                    text: '在工程实际中，市场和销售等功能性部门需要适当的掌握产品信息。'
                },
            ],
            schemeStructureImg: require("../assets/img/product-life-mngt-solution/scheme-structure.png"),
            schemeStructureMobImg: require("../assets/img/product-life-mngt-solution/scheme-structure-mob.png"),
            schemeAdvantagesBgImg: require("../assets/img/product-life-mngt-solution/scheme-advantages-bgimg.png"),
            schemeAdvantagesInfos: [
                {
                    index: 0,
                    title: '提高企业市场竞争力',
                    showText: false,
                    text: ['提高市场竞争力, 也能提高产品的质量和竞争力、优化企业业务过程与产品策略。']
                },
                {
                    index: 1,
                    title: '提高设计工作效率',
                    showText: false,
                    text: ['通过集成设计工具，实现设计工程师的设计数据共享；产品设计研发业务同工艺规划与管理系统、制造系统进行业务交互、数据共享。']
                },
                {
                    index: 2,
                    title: '有利于产品的管理',
                    showText: false,
                    text: ['支持整个产品全生命周期的产品协同设计、制造和管理, 从概念设计、产品工程设计、生产准备和制造、售后服务等整个过程的产品全生命周期的管理。']
                },
                {
                    index: 3,
                    title: '有利于提高企业竞争力',
                    showText: false,
                    text: ['提高市场竞争力, 也能提高产品的质量和竞争力、优化企业业务过程与产品策略。']
                }
            ],
            schemeAdvantagesImg: require("../assets/img/product-life-mngt-solution/scheme-advantages-contentimg.png"),
            schemeAdvantagesMobImg: require('../assets/img/product-life-mngt-solution/scheme-advantages-contentimg-mob.png'),
            customerCaseMobImg: require("../assets/img/product-life-mngt-solution/customer-case-mob.png"),
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/product-life-mngt-solution/customer-case.png"),
                text: '复创智云产品全生命周期解决方案'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemSupplierQualityAnalysis, ApplicationValueProjectMngSystem, CustomerCasePages
    },
    methods: {
        changeTextFlag(arg){
            this.schemeAdvantagesInfos[arg[1]].showText = arg[0]
        }
    },
}
</script>
<!-- B端 -->
<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .product-life-mngt-solution {
        display: block;
    }
    .product-life-mngt-solution_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .product-life-mngt-solution {
        display: none;
    }
    .product-life-mngt-solution_mob {
        display: block;
    }
}
.product-life-mngt-solution{
    width: 100%;
    min-width: 1440px;
    .business-challenges{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .business-challenges-box{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }
    .scheme-structure{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .img-box{
            width: 827px;
            margin: 0 auto;
            img{
                width: 827px;
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-repeat: no-repeat;
                background-size: 100% 266px;
                border-radius: 0px 0px 4px 4px;
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.product-life-mngt-solution_mob {
    .business-challenges_mob, .scheme-structure_mob, .scheme-advantages_mob {
        .title_mob{
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
        }
        .business-challenges-box_mob {
            display: flex;
            flex-wrap: wrap;
        }
        .img-box_mob {
            padding: 0 10px;
            img{
                width: 100%;
            }
        }
        .values-box_mob {
            padding: 0 10px 10px;
            .flex-column_mob {
                display: flex;
                flex-wrap: wrap;
                background-size: 100% 100%;
                margin-bottom: 20px;
            }
        }
    }
}
</style>
