<!-- 首页以外的其他页面 -->
<template>
    <div>
        <div class="other-page">
            <HeaderWebOpen :showImg="false" class="other-page-header"></HeaderWebOpen>
            <router-view></router-view>
            <FooterWeb></FooterWeb>
        </div>
        
        <div class="other-page-mob">
            <HeaderMob  class="other-page-header"></HeaderMob>
            <router-view></router-view>
            <FooterMob></FooterMob>
        </div>
    </div>
</template>

<script>
import HeaderWebOpen from "../components/home-menu-open.vue";
import HeaderMob from '../components/mobileComp/header-mob.vue'
import FooterWeb from "../components/footer-web/footer-web.vue";
import FooterMob from '../components/mobileComp/footer-mob.vue'
export default {
    name: 'NotHome',
    components:{
        HeaderWebOpen, FooterWeb, HeaderMob, FooterMob
    },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 601px) { 
    .other-page { display: block; }
    .other-page-mob { display: none; }
}
@media screen and (max-width: 600px) { 
    .other-page { display: none; }
    .other-page-mob { display: block; }
}
.other-page {
    width: 100%;
    padding-top: 72px;
    .other-page-header {
        position: fixed;
        top: 0px;
        z-index: 10087;
    }
}
.other-page-mob {
    width: 100%;
    padding-top: 40px;
    .other-page-header {
        position: fixed;
        top: 0px;
        z-index: 10087;
    }
}
</style>
