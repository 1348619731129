<!-- 产品中心，项目管理系统，应用价值组件 -->
<template>
    <div class="application-value-item" @mouseenter="changeTextFlag(true)" @mouseleave="changeTextFlag(false)">
        <div class="text-box" :class="info.showText ? 'show-text' : ''" v-show="info.showText">
            <div class="text-box-item" :style="{ left: info.text.length==1?'50%':'60%' }">
                <div class="text-item" v-for="(item, index) in info.text" :key="index">{{ item }}</div>
            </div>
        </div>
        <div :class="['title', info.index!=3?'title-border-right':'', info.showText?'title-border-bottom':'']">{{ info.title }}</div>
    </div>
</template>

<script>
import '../assets/css/animate.css'
export default {
    name: 'ApplicationValueProjectMngSystem',
    props:{
        info: {
            require: true
        }
    },
    methods: {
        changeTextFlag(flag){
            this.$emit('changeTextFlag', [ flag , this.info.index ])
        }
    },
}
</script>

<style scoped lang="scss">
    .application-value-item{
        width: 270px;
        height: 332px;
        cursor: pointer;
        position: relative;
        @media screen and (max-width:600px) {
            width: 50%;
            height: 222px;
        }
        .show-text{
            animation: textShowFromBottom 1s;
            -webkit-animation: textShowFromBottom 1s; /* Safari 与 Chrome */
        }
        .text-box{
            width: 270px;
            height: 266px;
            background-color: rgba($color: #05206D, $alpha: 0.75);
            color: rgb(104, 104, 104);
            line-height: 266px;
            position: relative;
            @media screen and (max-width:600px) {
                width: 100%;
                height: 180px;
                line-height: 180px;
            }
            .text-box-item{
                position: absolute;
                top: 50%;
                transform: translate(-50%,-50%);
                @media screen and (max-width: 600px) {
                    left: 10px !important;
                    right: 10px !important;
                    transform: translate(0%,-50%);
                }
                .text-item{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 36px;
                    width: 215px;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 20px;
                    }
                }
            }
        }
        .title-border-right{
            border-right: 1px solid #EBEBEB;
        }
        .title-border-bottom{
            border-bottom: 4px solid #4970E0;
        }
        .title{
            width: 269px;
            text-align: center;
            height: 66px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 66px;
            font-size: 18px;
            position: absolute;
            background: #FFFFFF;
            bottom: 0;
            box-sizing: border-box;
            @media screen and (max-width: 600px) {
                height: 42px;
                line-height: 42px;
                width: 100%;
                font-size: 15px;
            }
        }
    }
</style>
