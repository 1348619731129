<!-- 数字化供应链协同平台 -->
<template>
    <div class="digital-coll-platform">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks" class="anchor-navigation"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemDigitalCollPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemDigitalCollPlatform>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box">
                    <ApplicationValueDigitalCollPlatform v-for="(info, index) in applicationValueInfos" :key="index" :info="info" class="application-value-web"
                    ></ApplicationValueDigitalCollPlatform>
                    <ApplicationValueDigitalCollPlatform v-for="(info, index) in applicationValueInfosMob" :key="index+'mob'" :info="info" class="application-value-mob"
                    ></ApplicationValueDigitalCollPlatform>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <div class="customer-case">
            <div class="title">客户案例</div>
            <div class="customer-case-box">
                <div class="customer-case-comp">
                    <div @mouseenter="changeImg(0)">
                        <img :src="imgs[0].riceImgSmall" v-show="!imgs[0].showBig" :class="!imgs[0].showBig?'img-card-change-small':''">
                        <img :src="imgs[0].riceImgbig" v-show="imgs[0].showBig" :class="imgs[0].showBig?'img-card-change':''">
                    </div>
                    <div @mouseenter="changeImg(1)">
                        <img :src="imgs[1].carbImgSmall" v-show="!imgs[1].showBig" :class="!imgs[1].showBig?'img-card-change-small':''">
                        <img :src="imgs[1].carbImgbig" v-show="imgs[1].showBig" :class="imgs[1].showBig?'img-card-change':''">
                    </div>
                </div>
                <CustomerCaseMob :caseImgs="mobImgCaseList" class="customer-case-comp-mob"></CustomerCaseMob>
            </div>
        </div>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemDigitalCollPlatform from "../components/product-advantages-item-DigitalCollPlatform.vue";
import ApplicationValueDigitalCollPlatform from "../components/application-value-DigitalCollPlatform.vue";
import CustomerCaseMob from '../components/customer-case-mob-imgs';
export default {
    name: 'DigitalCollPlatform',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/digital-platform/banner.png"),
                title: '数字化供应链协同平台',
                text: '通过平台连接企业内部的人、机、料、法，实现内部深度集成的数字化工厂；连接上下游供应链伙伴实现外部横向协同数字化，使得可以围绕用户需求重新组织要素与生产模式，更有效的实现供需匹配，推动企业的数字化转型与发展'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/digital-platform/product-advantages-img1.png"),
                    title: '帮助企业重新定义端到端价值链',
                    text: '提供从生产制造、物流仓储供应商协同全过程端到端的数字化管理，客户方可掌控供应链全局，可视、可控，高效、透明。'
                },
                {
                    imgUrl: require("../assets/img/digital-platform/product-advantages-img2.png"),
                    title: '辅助企业决策流程升级',
                    text: '通过超级模拟引擎，对销售数据、制造数据、供应链数据进行大数据分析和模拟，自动化输出销售、制造、供应链关键指标数据。'
                },
                {
                    imgUrl: require("../assets/img/digital-platform/product-advantages-img3.png"),
                    title: '短期物料齐套与生产协同',
                    text: '结合历史销量数据以及外部数据提供市场潜力流动分析，准确识别销售淡季/旺季，需求集中区域等，进而指导合理安排生产。'
                },
                {
                    imgUrl: require("../assets/img/digital-platform/product-advantages-img4.png"),
                    title: '可视化分析大屏',
                    text: '通过控制塔可视化和情景模拟提升决策精度与速度，使业务人员每天要做的决策变成算法自动化。'
                },
                {
                    imgUrl: require("../assets/img/digital-platform/product-advantages-img5.png"),
                    title: '支持全约束的复杂运作场景',
                    text: '生产计划排程智能排产模块基于自身灵活性的设置，能够快速响应业务变化，提升工作效率。'
                },
                {
                    imgUrl: require("../assets/img/digital-platform/product-advantages-img6.png"),
                    title: '标识解析+AI算法驱动',
                    text: '通过标识和AI手段，打通企业内外部数据，对外输出数据服务能力，赋能企业快速创新，AI技术优化企业效率，数据驱动数字化转型。'
                },
            ],
            functionIntroductionImg: require("../assets/img/digital-platform/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/digital-platform/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    title: '提升了客户体验',
                    text: '更好的感知客户需求，加快上市新产品和服务，给新渠道和业务带来业务增长，提高运营的响应速度、敏捷性和可负担性。',
                    bgimg: require("../assets/img/digital-platform/application-value-img1.png")
                },
                {
                    title: '实现库存优化',
                    text: '订单满足率提高8%，存货成本降低22%，需求预测准确率提高11%。',
                    bgimg: require("../assets/img/digital-platform/application-value-img2.png")
                },
                {
                    title: '生产排程指标优化',
                    text: '订单满足率提高20%，产能损失率降低30%，排产排程人工干预降低70%，非计划维修率降低15%。',
                    bgimg: require("../assets/img/digital-platform/application-value-img3.png")
                },
                {
                    title: '提高仓内作业能力',
                    text: '提高仓内工作效率3-4倍，支持500台AGV同时调度，拣选效率提升15%。',
                    bgimg: require("../assets/img/digital-platform/application-value-img4.png")
                },
                {
                    title: '优化运输流程',
                    text: '排线时间从4-5小时降低至5分钟，每日车辆使用量降低25%，每日行驶里程降低12%，每车装载量最高增加超50%。',
                    bgimg: require("../assets/img/digital-platform/application-value-img5.png")
                },
                {
                    title: '需求预测能力提高',
                    text: '预测准确能力提升10%，预测稳定性提升20%，节省预测人工投入50%。',
                    bgimg: require("../assets/img/digital-platform/application-value-img6.png")
                },
            ],
            applicationValueInfosMob: [
                {
                    title: '提升了客户体验',
                    text: '更好的感知客户需求，加快上市新产品和服务，给新渠道和业务带来业务增长，提高运营的响应速度、敏捷性和可负担性。',
                    bgimg: require("../assets/img/digital-platform/application-value-mob-img1.png")
                },
                {
                    title: '实现库存优化',
                    text: '订单满足率提高8%，存货成本降低22%，需求预测准确率提高11%。',
                    bgimg: require("../assets/img/digital-platform/application-value-mob-img2.png")
                },
                {
                    title: '生产排程指标优化',
                    text: '订单满足率提高20%，产能损失率降低30%，排产排程人工干预降低70%，非计划维修率降低15%。',
                    bgimg: require("../assets/img/digital-platform/application-value-mob-img3.png")
                },
                {
                    title: '提高仓内作业能力',
                    text: '提高仓内工作效率3-4倍，支持500台AGV同时调度，拣选效率提升15%。',
                    bgimg: require("../assets/img/digital-platform/application-value-mob-img4.png")
                },
                {
                    title: '优化运输流程',
                    text: '排线时间从4-5小时降低至5分钟，每日车辆使用量降低25%，每日行驶里程降低12%，每车装载量最高增加超50%。',
                    bgimg: require("../assets/img/digital-platform/application-value-mob-img5.png")
                },
                {
                    title: '需求预测能力提高',
                    text: '预测准确能力提升10%，预测稳定性提升20%，节省预测人工投入50%。',
                    bgimg: require("../assets/img/digital-platform/application-value-mob-img6.png")
                },
            ],
            imgs: [
                {
                    riceImgSmall: require("../assets/img/digital-platform/customer-case-img1.png"),
                    riceImgbig: require("../assets/img/digital-platform/customer-case-img1-cover.png"),
                    showBig: false
                },
                {
                    carbImgSmall: require("../assets/img/digital-platform/customer-case-img2.png"),
                    carbImgbig: require("../assets/img/digital-platform/customer-case-img2-cover.png"),
                    showBig: true
                },
            ],
            mobImgCaseList: [
                require("../assets/img/digital-platform/customer-case-mob-img1.png"),
                require("../assets/img/digital-platform/customer-case-mob-img2.png"),
            ]
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemDigitalCollPlatform, ApplicationValueDigitalCollPlatform, CustomerCaseMob
    },
    methods: {
        changeImg(i){
            for (let index = 0; index < this.imgs.length; index++) {
                const element = this.imgs[index];
                index == i ? element.showBig = true : element.showBig = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.digital-coll-platform{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .product-advantages-box{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 600px) {
                justify-content: space-around;
            }
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .img-box{
            height: 614px;
            width: 800px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 345px;
                height: 266px;
                margin: 0 auto;
            }
            img{
                width: 800px;
                height: 614px;
                @media screen and (max-width: 600px) {
                    width: 345px;
                    height: 266px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px 28px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 13px 20px 0px;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin: 0 auto;
            }
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
                @media screen and (max-width: 600px) {
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom: 15px;
                }
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @media screen and (max-width: 600px) {
                    .application-value-web{
                        display: none;
                    }
                    .application-value-mob{
                        display: block;
                    }
                }
                @media screen and (min-width: 601px) {
                    .application-value-web{
                        display: block;
                    }
                    .application-value-mob{
                        display: none;
                    }
                }
            }
        }
    }
    .customer-case{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .customer-case-comp{
            width: 1080px;
            height: 460px;
            display: flex;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                display: none;
            }
            img{
                height: 460px;
            }
            .img-card-change{
                animation: imgCardChangeGove 1s;
                -webkit-animation: imgCardChangeGove 1s; /* Safari 与 Chrome */
            }
            .img-card-change-small{
                animation: imgCardChangeSmallGove 1s;
                -webkit-animation: imgCardChangeSmallGove 1s; /* Safari 与 Chrome */
            }
        }
        .customer-case-comp-mob{
            display: none;
            text-align: center;
            @media screen and (max-width: 600px) {
                display: block;
            }
        }
    }
}
</style>
