<!-- 了解if在线 -->
<template>
    <div class="contact-us">
        <HeaderWebOpen :showImg="false" class="other-page-header"></HeaderWebOpen>
        <BannerWebOnlytitle :info="bannerInfo"></BannerWebOnlytitle>
        <div class="inner-box" :style="{ backgroundImage: `url(${innerBgimg})` }">
            <div class="content-box">
                <div class="phone-addr">
                    <div class="title">咨询热线</div>
                    <div class="number"><img :src="phoneImg">400-106-2918</div>
                    <div class="text">咨询业务、获取方案、申请免费体验产品，拨打热线让我们为您服务！</div>
                    <div class="addr" id="addr"></div>
                </div>
                <div class="from-box">
                    <div class="form-header">留下您的联系方式，您的专属顾问将在1个工作日内与您联系</div>
                    <el-form :model="contactUsInfo" :rules="contactFormRules" ref="contactRuleFormPage" class="contact-ruleForm">
                        <el-form-item prop="companyName">
                            <el-input v-model="contactUsInfo.companyName" placeholder="请输入您的公司名称" class="contact-input"></el-input>
                        </el-form-item>
                        <el-form-item prop="name" class="input-smail margin-rig">
                            <el-input v-model="contactUsInfo.name" placeholder="请输入您的姓名" class="contact-input"></el-input>
                        </el-form-item>
                        <el-form-item prop="position" class="input-smail">
                            <el-input v-model="contactUsInfo.position" placeholder="请输入您的职位" class="contact-input"></el-input>
                        </el-form-item>
                        <el-form-item prop="contact" class="input-smail margin-rig">
                            <el-input v-model="contactUsInfo.contact" placeholder="请输入您的手机号码" class="contact-input"></el-input>
                        </el-form-item>
                        <el-form-item prop="mail" class="input-smail">
                            <el-input v-model="contactUsInfo.mail" placeholder="请输入您的邮箱" class="contact-input"></el-input>
                        </el-form-item>
                        <el-form-item prop="remark">
                            <el-input v-model="contactUsInfo.remark" placeholder="请留下您的需求" class="contact-input"></el-input>
                        </el-form-item>
                        <div class="submit-btn" @click="commitContactInfo">立即预约</div>
                    </el-form>
                </div>
            </div>
        </div>
        <FooterWeb :notShowForm="true"></FooterWeb>
    </div>
</template>

<script>
import BannerWebOnlytitle from '../components/banner-web-onlytitle'
import HeaderWebOpen from "../components/home-menu-open.vue";
import FooterWeb from "../components/footer-web/footer-web.vue";
import AMapLoader from '@amap/amap-jsapi-loader';
import { Message } from 'element-ui';
export default {
    name: 'ContactUs',
    mounted() {
        this.initMap()
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0
    },
    data() {
        var checkPhoneNumber = (rule, value, callback) => {
            var reg = /^1[3-9]\d{9}$/
            if (value === '') {
                callback(new Error('请输入手机号码'));
            } else if (!reg.test(value)) {
                callback(new Error('手机号码格式不正确！'));
            } else {
                callback();
            }
        };
        var checkMail = (rule, value, callback) => {
            var reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
            if (value === '') {
                callback(new Error('请输入邮箱'));
            } else if (!reg.test(value)) {
                callback(new Error('邮箱格式不正确！'));
            } else {
                callback();
            }
        };
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/contact-us/banner.png"),
                title: ['联系我们'],
                introduction: ['工业互联网平台软件技术解决方案和服务，', '帮助企业低成本、高效率、专业化建立属于自己的工业互联网平台']
            },
            phoneImg: require("../assets/img/contact-us/phone.png"),
            // 第六页的提交信息
            contactUsInfo: {
                companyName: '',
                name: '',
                position: '',
                contact: '',
                mail: '',
                remark: ''
            },
            // 表单的校验
            contactFormRules: {
                companyName: [
                    { required: true, message: '请输入您的公司名称', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                position: [
                    { required: true, message: '请输入您的职位', trigger: 'blur' }
                ],
                mail: [
                    { validator: checkMail, trigger: 'blur' }
                ],
                contact: [
                    { validator: checkPhoneNumber, trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入您的需求', trigger: 'blur' }
                ]
            },
            innerBgimg: require("../assets/img/contact-us/content-bgimg.png"),
            map: ''
        }
    },
    methods: {
        // 提交咨询信息
        commitContactInfo(){
            this.$refs['contactRuleFormPage'].validate((valid) => {
                if (valid) {
                    this.$axios.post('ifweline/fcapi/contactUs/addItem',{ tenantId: 1, ...this.contactUsInfo }).then(res => {
                        if(res.data&&res.data.code == 200){
                            this.contactUsInfo = {
                                companyName: '',
                                name: '',
                                position: '',
                                contact: '',
                                mail: '',
                                remark: ''
                            }
                            Message.success({offset: 92, message: "预约成功！请耐心等候消息。"})
                        }else{
                            Message.error({offset: 92, message: "预约失败！" + res.data.message})
                        }
                    }).catch(() => {
                        Message.error({offset: 92, message: "预约失败，网络异常！请稍后重试。"})
                    })
                } else {
                    return false;
                }
            });
        },
        // 初始化高德地图
        initMap() {
            // 这里使用的是高德地图个人开发者账号的key，每日访问数会有5000的上限，后续访问量过多需要申请企业开发者账户
            AMapLoader.load({
                key:"c646be70eac119cff1bb25f726c91501", // 申请好的Web端开发者Key，首次调用 load 时必填
                version:"2.0",// 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins:[''],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap)=>{
                this.map = new AMap.Map("addr", { //设置地图容器id
                    viewMode: "3D", //是否为3D地图模式
                    zoom: 19, //初始化地图级别
                    center: [118.827000, 31.866955], //初始化地图中心点位置, 要显示右边的更多信息，所以中心点往右偏移
                });
                // 标记
                let marker = new AMap.Marker({
                    position: [118.826811, 31.866955] // 基点位置
                });
                // 地图添加标记
                this.map.add(marker);
            }).catch(e=>{
                Message.error({offset: 92, message: e})
            })
        }
    },
    components:{
        BannerWebOnlytitle, HeaderWebOpen, FooterWeb
    }
}
</script>

<style lang="scss" scoped>
.contact-us{
    width: 100%;
    padding-top: 72px;
    .other-page-header{
        position: fixed;
        top: 0px;
        z-index: 10087;
    }
    .inner-box{
        background-repeat: no-repeat;
        padding: 60px 0px;
        background-size: 100% 100%;
    }
    .content-box{
        width: 1080px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .phone-addr{
            width: 460px;
            .title{
                height: 40px;
                font-size: 28px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 40px;
                margin-bottom: 20px;
            }
            .number{
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 20px;
                margin-bottom: 17px;
                img{
                    width: 15px;
                    height: 14px;
                    margin-right: 8px;
                }
            }
            .text{
                height: 24px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 24px;
                margin-bottom: 32px;
            }
            .addr{
                width: 460px;
                height: 300px;
            }
        }
        .from-box{
            width: 540px;
            height: 453px;
            border: 1px solid #E1E8FA;
            border-radius: 4px 4px 0px 0px;
            .form-header{
                width: 540px;
                height: 50px;
                background: rgba($color: #4970E0, $alpha: 0.92);
                box-shadow: 3px 3px 14px 1px rgba(0,0,0,0.06);
                border-radius: 4px 4px 0px 0px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 50px;
                box-sizing: border-box;
                padding-left: 24px;
            }
            .contact-ruleForm{
                height: 403px;
                background-color: #FFFFFF;
                box-sizing: border-box;
                padding: 10px 24px 32px 24px;
                ::v-deep .el-form-item__content{
                    margin-left: 0 !important;
                }
                ::v-deep .el-input__inner{
                    border: none;
                    border-bottom: 1px solid #F4F4F4;
                    padding: 0;
                }
                .input-smail{
                    width: 200px;
                    display: inline-block;
                }
                .margin-rig{
                    margin-right: 92px;
                }
                .submit-btn{
                    width: 97px;
                    height: 34px;
                    background: #4970E0;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 34px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    float: right;
                    margin-top: 76px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
