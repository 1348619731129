<!-- 产品中心，数字化供应链协同平台，产品优势组件 -->
<template>
    <div class="product-advantages-item" :style="{ paddingTop: paddingTop, height: height }">
        <div class="left-content">
            <div class="img-box"><img :src="info.imgUrl"></div>
        </div>
        <div class="right-content">
            <div class="title">{{ info.title }}</div>
            <div class="text">{{ info.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductAdvantagesItemDigitalCollPlatform',
    props:{
        info: {
            require: true
        },
        paddingTop:{
            default:'24px'
        },
        height:{}
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        box-sizing: border-box;
        width: 480px;
        padding: 24px 48px 24px 28px;
        margin-bottom: 48px;
        box-shadow: 3px 0px 14px 1px rgba(0,0,0,0.0700);
        display: flex;
        @media screen and (max-width: 600px) {
            padding: 10px 17px 10px 10px !important;
            width: 345px;
            height: auto !important;
            background: #FFFFFF;
            box-shadow: 3px 0px 14px 1px rgba(0,0,0,0.07);
            margin-bottom: 15px;
        }
        .left-content{
            height: 100px;
            margin-right: 28px;
            @media screen and (max-width: 600px) {
                height: 100%;
                margin-right: 10px;
            }
            .img-box{
                height: 30px;
                @media screen and (max-width: 600px) {
                    height: 20px;
                }
                img{
                    height: 30px;
                    @media screen and (max-width: 600px) {
                        height: 20px;
                    }
                }
            }
        }
        .right-content{
            width: 406px;
            @media screen and (max-width: 600px) {
                width: 288px;
            }
            .title{
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4970E0;
                line-height: 28px;
                @media screen and (max-width: 600px) {
                    height: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #4970E0;
                    line-height: 21px;
                    margin-bottom: 8px;
                }
            }
            .text{
                margin-top: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 20px;
                @media screen and (max-width: 600px) {
                    height: auto;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    line-height: 18px;
                    opacity: 0.65;
                    margin-top: 0;
                }
            }
        }
    }
</style>
