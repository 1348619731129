<!-- 项目管理系统 -->
<template>
    <div class="project-mng-system">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemProjectMngSystem v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemProjectMngSystem>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="text">五大类，四十小类的功能项，满足所有角色的功能需求</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box" :style="{backgroundImage: `url(${applicationValueImg})`}">
                    <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfos" :key="index" :info="info"
                        @changeTextFlag="changeTextFlag"
                    ></ApplicationValueProjectMngSystem>
                </div>
                <div class="values-box-mob">
                    <div class="values-box-item-mob" :style="{ backgroundImage: `url(${applicationValueImgMob1})`, marginBottom: '20px' }">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfosMob1" :key="index" :info="info"
                            @changeTextFlag="changeTextFlagMob1"
                        ></ApplicationValueProjectMngSystem>
                    </div>
                    <div class="values-box-item-mob" :style="{backgroundImage: `url(${applicationValueImgMob2})`}">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfosMob2" :key="index" :info="info"
                            @changeTextFlag="changeTextFlagMob2"
                        ></ApplicationValueProjectMngSystem>
                    </div>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo" class="customer-case-web"></CustomerCasePages>
        <CustomerCasePages :info="customerCasePagesInfoMob" class="customer-case-mob"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemProjectMngSystem from '../components/product-advantages-item-ProjectMngSystem.vue'
import ApplicationValueProjectMngSystem from '../components/application-value-ProjectMngSystem.vue'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'ProjectMngSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/project-mng-system/banner.png"),
                title: '项目管理系统',
                text: '复创智云项目管理系统，涉及到十大知识领域，包括：项目综合管理、项目范围管理、项目时间管理、项目费用管理、项目质量管理、项目人力资源管理、项目沟通管理、项目风险管理、项目外包采购管理、干系人管理，建立从决策-业务执行-基础数据管理的闭环'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/project-mng-system/product-advantages-img1.png"),
                    title: '单一数据源',
                    text: '从支撑体系到执行体系，再到决策体系，建立基于“单一数据源”的一体化决策及执行体系'
                },
                {
                    imgUrl: require("../assets/img/project-mng-system/product-advantages-img2.png"),
                    title: '覆盖6大角色，28项管理事项的项目管理流程',
                    text: '从产品经理对产品生命周期的管控，项目经理和业务代表对项目执行的管控，项目管理部和业务经理对项目管理协调，三者形成项目管理解决方案，为管理决策层提供决策与指导的支持'
                },
                {
                    imgUrl: require("../assets/img/project-mng-system/product-advantages-img3.png"),
                    title: '5大类和40+小项功能',
                    text: '提供5大类功能包括：项目运行准备、项目定义、项目计划、项目执行、项目控制40+小项功能包括：项目模板定义、项目交付物定义、工时管理、变更管理、风险控制等等'
                },
                {
                    imgUrl: require("../assets/img/project-mng-system/product-advantages-img4.png"),
                    title: '虚拟作战室',
                    text: '项目计划提供多种报表分析，计划报表、任务报表、风险、问题、资源负载、财务等。支持报表的定制'
                },
                {
                    imgUrl: require("../assets/img/project-mng-system/product-advantages-img5.png"),
                    title: '基于“APQP”的项目管理-体系建设',
                    text: '支持图形化报表的方式，总览APQP项目，从而直观的了解项目的整理状况；各主机厂之间可以个性化定义界面，差异化展示；支持APQP项目进度的在线化、实时化跟踪，保证项目的进度；实现多种数据格式的，自动化交付'
                },
                {
                    imgUrl: require("../assets/img/project-mng-system/product-advantages-img6.png"),
                    title: '项目监控',
                    text: '从项目成员、项目经理、项目群成员、项目群经理、部门领导、PMO、公司管理层，不同角色的人员，都可监控到自己关注的数据'
                },
            ],
            functionIntroductionImg: require("../assets/img/project-mng-system/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/project-mng-system/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    index: 0,
                    title: '日清日结',
                    showText: false,
                    text: ['知道有什么要做', '知道今天该做什么', '知道明天该准备什么']
                },
                {
                    index: 1,
                    title: '资源管理',
                    showText: false,
                    text: ['知道在做什么', '知道该派谁去做什么', '知道目标方向是否一致']
                },
                {
                    index: 2,
                    title: '经验传承',
                    showText: false,
                    text: ['知道该怎么做', '谁知道怎么做更好', '站在巨人的肩膀上前行']
                },
                {
                    index: 3,
                    title: '问题管理',
                    showText: false,
                    text: ['问题需要反馈', '问题需要共享', '问题需要升级', '问题的背后是需求的平衡']
                }
            ],
            applicationValueInfosMob1:[
                {
                    index: 0,
                    title: '日清日结',
                    showText: false,
                    text: ['知道有什么要做', '知道今天该做什么', '知道明天该准备什么']
                },
                {
                    index: 1,
                    title: '资源管理',
                    showText: false,
                    text: ['知道在做什么', '知道该派谁去做什么', '知道目标方向是否一致']
                },
            ],
            applicationValueInfosMob2: [
                {
                    index: 0,
                    title: '经验传承',
                    showText: false,
                    text: ['知道该怎么做', '谁知道怎么做更好', '站在巨人的肩膀上前行']
                },
                {
                    index: 1,
                    title: '问题管理',
                    showText: false,
                    text: ['问题需要反馈', '问题需要共享', '问题需要升级', '问题的背后是需求的平衡']
                }
            ],
            applicationValueImgMob1: require("../assets/img/project-mng-system/application-value-bgimg-mob1.png"),
            applicationValueImgMob2: require("../assets/img/project-mng-system/application-value-bgimg-mob2.png"),
            applicationValueImg: require("../assets/img/project-mng-system/application-value-contentimg.png"),
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/project-mng-system/customer-case.png"),
                text: '波音公司数字化战略'
            },
            customerCasePagesInfoMob: {
                customerCaseImg: require("../assets/img/project-mng-system/customer-case-mob.png"),
                text: '波音公司数字化战略'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemProjectMngSystem, ApplicationValueProjectMngSystem, CustomerCasePages
    },
    methods: {
        changeTextFlag(arg){
            this.applicationValueInfos[arg[1]].showText = arg[0]
        },
        changeTextFlagMob1(arg) {
            this.applicationValueInfosMob1[arg[1]].showText = arg[0]
        },
        changeTextFlagMob2(arg) {
            this.applicationValueInfosMob2[arg[1]].showText = arg[0]
        }
    },
}
</script>

<style lang="scss" scoped>
.project-mng-system{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding-top: 60px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .product-advantages-box{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 600px) {
                justify-content: space-around;
            }
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 20px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 10px;
            }
        }
        .text{
            text-align: center;
            margin: 0 auto;
            height: 25px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgb(104, 104, 104);
            line-height: 25px;
            margin-bottom: 36px;
            @media screen and (max-width: 600px) {
                width: 182px;
                height: auto;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 25px;
                margin-bottom: 10px;
            }
        }
        .img-box{
            height: 545px;
            width: 816px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                height: 232px;
                width: 345px;
            }
            img{
                width: 816px;
                height: 545px;
                @media screen and (max-width: 600px) {
                    height: 232px;
                    width: 345px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
                @media screen and (max-width: 600px) {
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-repeat: no-repeat;
                background-size: 100% 266px;
                border-radius: 0px 0px 4px 4px;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            .values-box-mob{
                display: none;
                @media screen and (max-width: 600px) {
                    display: block;
                }
                .values-box-item-mob{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    background-repeat: no-repeat;
                    background-size: 100% 180px;
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }
    }
    .customer-case-web{
        display: block;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    .customer-case-mob{
        display: none;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }
}
</style>
