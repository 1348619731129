<!-- 客户案例组件手机端多个图片 -->
<template>
    <div class="customer-case-mob-comp">
        <img :src="item" v-for="(item, index) in caseImgs" :key="index" />
    </div>
</template>

<script>
export default {
    name: 'CustomerCaseMob',
    props: {
        caseImgs: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .customer-case-mob-comp{
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        img{
            height: 240px;
        }
    }
</style>
