<!-- 头部背景图组件 -->
<template>
    <div class="banner-web" :style="{ backgroundImage: 'url(' + info.bannerImg + ')'}">
        <div class="content-box">
            <div class="title" v-for="(item ,index) in info.title" :key="index">{{ item }}</div>
            <div class="introduction-box" v-if="info.introduction">
                <div class="introduction-item" v-for="(introduc, index) in info.introduction" :key="index">{{ introduc }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannerWebOnlytitle',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .banner-web{
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 420px;
        display: flex;
        align-items: center;
        .content-box{
            width: 738px;
            text-align: center;
            margin: 0px auto;
            color: #FFFFFF;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                line-height: 56px;
                letter-spacing: 2px;
                margin-bottom: 24px;
            }
            .introduction-item{
                height: 28px;
                font-size: 17px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 28px;
                letter-spacing: 1px;
            }
        }
    }
</style>
