<!-- 能耗管理系统 -->
<template>
    <div class="Energy-mng-system">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemEnergyMngSystem v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnergyMngSystem>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value application-value-web" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box">
                    <ApplicationValueEnergyMngSystem :infos="applicationValueInfos"></ApplicationValueEnergyMngSystem>
                </div>
            </div>
        </div>
        <ApplicationValueMob :infos="applicationValueMobInfos" class="application-value-mob"></ApplicationValueMob>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemEnergyMngSystem from "../components/product-advantages-item-EnergyMngSystem.vue";
import ApplicationValueEnergyMngSystem from "../components/application-value-EnergyMngSystem.vue";
import CustomerCasePages from '../components/customer-case-pages.vue';
import ApplicationValueMob from '../components/application-value-mob.vue'
export default {
    name: 'EnergyMngSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/Energy-mng-system/banner.png"),
                title: '能耗管理系统',
                text: '复创智云能耗管理系统，通过采集各个能耗监测点的能耗和运行信息，形成能耗的分类、分项、分区域统计分析，对能源统一调度、优化能源介质平衡、提高环保质量、降低企业综合能耗和提高劳动生产率，帮助客户更有效的使用能源，实现“节能管理，绿色增效”'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    index: 0,
                    imgUrl: require("../assets/img/Energy-mng-system/product-advantages-img1.png"),
                    title: '体系标准化管理',
                    text: ['能源标准管理体系，实现能源应用服务的基础；','能效考核结果评比分析，为管理层提供决策支持；','能效考核规则定制化，扩展业务应用范围。']
                },
                {
                    index: 1,
                    imgUrl: require("../assets/img/Energy-mng-system/product-advantages-img2.png"),
                    title: '集中精确化管理',
                    text: ['能耗过程精细化监管，随时分析系统状态；','能源数据趋势实时变化与历史追踪，灵活分析动态特性；','重点耗能设备监测，综合分析，能源数据动态流程全过程监视。']
                },
                {
                    index: 2,
                    imgUrl: require("../assets/img/Energy-mng-system/product-advantages-img3.png"),
                    title: '专业科学化管理',
                    text: ['能量平衡分析帮助用户了解企业用能状况；','协助用户制定合理能源计划，能源计划与能源实绩的科学对比；','能源成本分析，自动抽取能源大数据，实时分析消耗成本。']
                },
                {
                    index: 3,
                    imgUrl: require("../assets/img/Energy-mng-system/product-advantages-img4.png"),
                    title: '持续智能化管理',
                    text: ['能效诊断评价主要耗能设备的实时能效状况；','高耗能设备告警统计分析功能，有效管理历史告警信息；','智能化分析，为故障解决提供辅助决策。']
                },
            ],
            functionIntroductionImg: require("../assets/img/Energy-mng-system/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/Energy-mng-system/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    title: '优化企业成本核算决策力',
                    text: '能耗管理系统的实施，可以帮助企业管理部门了解自身的用能构成、设备的详细能耗，减少因管理和统计不到位造成的浪费，增加企业的能源管理能力，降低管理成本，实现了能耗管理的智能化。'
                },
                {
                    title: '提高企业决策能力',
                    text: '能有效的帮助节能管理人员分析设备用能现状，结合企业的用能趋势，从而找出能源使用过程中的漏洞和不合理的地方，调整能源分配策略并减少能源使用过程中的浪费。'
                },
                {
                    title: '降低企业人力成本，提高产能',
                    text: '数据实时采集、上传、分析，能耗管理系统支持导出excel报表，可以满足生产现场的多种多样需求。导出的报表可以直接打印，从而显著减少抄表工作的人力成本，提高经济效益。'
                },
                {
                    title: '实时监控，降低企业风险',
                    text: '生产管理者可以随时随地查看能耗情况，提升企业的管理机制，提高企业的整体运作效率，提高成本结算的时效性和精确性。'
                },
            ],
            applicationValueMobInfos: {
                title: '应用价值',
                bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg.png"),
                intru: [
                    {
                        title: '优化企业成本核算决策力',
                        text: '能耗管理系统的实施，可以帮助企业管理部门了解自身的用能构成、设备的详细能耗，减少因管理和统计不到位造成的浪费，增加企业的能源管理能力，降低管理成本，实现了能耗管理的智能化。',
                        width: '125px',
                        height: '307px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg2.png"),
                        color: '#151515',
                        opacity: '0.8',
                        titlePadding: '16px 15px 16px 10px',
                        textPadding:'0 10px 0 10px'
                    },
                    {
                        title: '提高企业决策能力',
                        text: '能有效的帮助节能管理人员分析设备用能现状，结合企业的用能趋势，从而找出能源使用过程中的漏洞和不合理的地方，调整能源分配策略并减少能源使用过程中的浪费。',
                        width: '210px',
                        height: '307px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg4.png"),
                        color: '#FFFFFF',
                        titlePadding: '16px 46px 36px 11px',
                        textPadding:'0 19px 0 11px'
                    },
                    {
                        title: '降低企业人力成本，提高产能',
                        text: '数据实时采集、上传、分析，能耗管理系统支持导出excel报表，可以满足生产现场的多种多样需求。导出的报表可以直接打印，从而显著减少抄表工作的人力成本，提高经济效益。',
                        width: '210px',
                        height: '227px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg1.png"),
                        color: '#FFFFFF',
                        titlePadding: '16px 46px 16px 11px',
                        textPadding:'0 19px 15px 11px'
                    },
                    {
                        title: '实时监控，降低企业风险',
                        text: '生产管理者可以随时随地查看能耗情况，提升企业的管理机制，提高企业的整体运作效率，提高成本结算的时效性和精确性。',
                        width: '125px',
                        height: '227px',
                        bgimg: require("../assets/img/saas-platform/product-advantages-mob-bgimg2.png"),
                        color: '#151515',
                        opacity: '0.8',
                        titlePadding: '16px 5px 16px 10px',
                        textPadding:'0 10px 15px 10px'
                    },
                ],
            },
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/Energy-mng-system/customer-case.png"),
                text: '复创大数据能耗管理系统'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemEnergyMngSystem, ApplicationValueEnergyMngSystem, CustomerCasePages, ApplicationValueMob
    }
}
</script>

<style lang="scss" scoped>
.Energy-mng-system{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding-top: 60px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 4px;
            }
        }
        .product-advantages-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media screen and (max-width: 600px) {
                justify-content: space-around;
            }
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 10px;
            }
        }
        .img-box{
            width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 345px;
            }
            img{
                width: 1080px;
                @media screen and (max-width: 600px) {
                    width: 345px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
                @media screen and (max-width: 600px) {
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .application-value-web{
        display: block;
        @media screen and (max-width: 600px) { 
            display: none;
        }
    }
    .application-value-mob{
        display: none;
        @media screen and (max-width: 600px) { 
            display: block;
        }
    }
}
</style>
