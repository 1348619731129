<!-- 了解if在线 -->
<template>
    <div class="konw-if-weline">
        <BannerWebOnlytitle :info="bannerInfo"></BannerWebOnlytitle>
        <div class="bg-content" :style="{ backgroundImage: `url(${contentBgimg})` }">
            <div class="introduction-box">
                <div class="img-box"><img :src="introductionImg"></div>
                <div class="content-box">
                    <div class="title">If weline</div>
                    <div class="title-content">
                        <div class="content-title">介绍</div>
                        <div class="text">
                            ifweline 致力于成为中国领先的工业互联网平台及工业互联网软件技术解决方案提供商，推动中国工业
                            、制造业高质量发展，为客户提供从研发设计、生产制造、仓储管理、运营与服务模式全链条、端到端的深度工业互联网全
                            系解决方案，以及自动化、数字化、网络化、智能化的软硬件产品、技术和服务。旗下设有多家分子公司，累计服务企业1,000+。
                        </div>
                    </div>
                </div>
            </div>
            <div class="business-box">
                <div class="title">主营业务</div>
                <el-carousel :interval="4000" type="card" height="420px" :autoplay="true" class="carousel" arrow="never">
                    <el-carousel-item>
                        <img :src="businessImg1">
                        <div class="introduction">立足国际智能制造</div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <img :src="businessImg2">
                        <div class="introduction">钻研技术工业互联</div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <img :src="businessImg3">
                        <div class="introduction">深耕教育匠心科技</div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="service-mode">
                <div class="title">服务模式</div>
                <div class="content-box" :style="{ backgroundImage: `url(${serviceModeBgImg})` }">
                    <div class="content-item" v-for="(item, index) in serviceModeData" :key="index">
                        <div class="introduction">{{ item.introduction }}</div>
                        <div class="title" :style="{ borderRight:index==2?'none':'', width: '360px' }">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mission-vision" :style="{ backgroundImage: `url(${missionVisionBgimg})` }">
            <div class="content-box">
                <div class="title">使命与愿景</div>
                <div class="style-text-box">
                    <div class="text-line1">
                        <div class="normol">秉承着</div>
                        <div class="style-text">“让制造更美好&nbsp;&nbsp;&nbsp;&nbsp;让创新更自在”</div>
                        <div class="normol">的愿景</div>
                    </div>
                    <div class="text-line2">
                        <div class="normol">致力于成为中国</div>
                        <div class="style-text">“赋能实体&nbsp;&nbsp;&nbsp;&nbsp;智慧强国”</div>
                        <div class="normol">的领跑者</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>
                <div class="mission-vision-cards">
                    <div class="card-item" v-for="(item, index) in missionVisionData" :key="index">
                        <div class="introduction" :style="{ backgroundImage: `url(${item.bgImg})` }">
                            <div class="text-box">
                                <div class="text-item" v-for="(text, i) in item.introduction" :key="i">{{ text }}</div>
                            </div>
                        </div>
                        <div class="title">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <LogosComp></LogosComp>
    </div>
</template>

<script>
import '../assets/css/animate.css'
import BannerWebOnlytitle from '../components/banner-web-onlytitle'
import LogosComp from '../components/logos-comp'
export default {
    name: 'KonwIfWeline',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/know-ifweline/banner.png"),
                title: ['If weline', '工业互联网技术解决方案提供商'],
            },
            contentBgimg: require("../assets/img/know-ifweline/content-bgimg.png"),
            introductionImg: require("../assets/img/know-ifweline/introduction-img.png"),
            businessImg1: require("../assets/img/know-ifweline/businessimg1.png"),
            businessImg2: require("../assets/img/know-ifweline/businessimg2.png"),
            businessImg3: require("../assets/img/know-ifweline/businessimg3.png"),
            serviceModeBgImg: require("../assets/img/know-ifweline/service-mode-bgimg.png"),
            serviceModeData: [
                {
                    introduction: '实现企业从生产到终端消费的全流程跟踪，实现用户从终端消费到生产加工的溯源，实现政府解决市场流通产品存在的监管问题，为社会面各环节提供真正可靠可行的产品追溯、防伪及监管机制。',
                    title: 'SAAS服务'
                },
                {
                    introduction: '为满足企业灵活化、个性化的需求，为企业构建高性价比的信息化管理服务体系，提供系统平台化、业务—体化、定制个性化的SaaS平台，数据实时整合并自动互联，一站式满足企业的全部业务及管理需求，全面提升企业经营效率。',
                    title: 'SAAS化定制服务'
                },
                {
                    introduction: '为各行业企业提供定制化的创新软件开发服务，构建精准的定制化产品、系统和服务，帮助客户更迅速地将概念转化为价值，为企业交付具有创新性的软件产品及企业级系统应用，助力企业实现业务升级和转型。',
                    title: 'on-premises部署及行业定制化解决方案'
                },
            ],
            missionVisionBgimg: require("../assets/img/know-ifweline/mission-vision-bgimg.png"),
            missionVisionData: [
                {
                    introduction: ['', '让制造更美好', '让创新更自在'],
                    title: '企业愿景',
                    bgImg: require("../assets/img/know-ifweline/mission-vision-img1.png"),
                },
                {
                    introduction: ['让数据“慧”生长', '让城市“慧”进发', '让国家“慧”繁荣'],
                    title: '复创匠心',
                    bgImg: require("../assets/img/know-ifweline/mission-vision-img2.png"),
                },
                {
                    introduction: ['', '需求为中心', '服务为生命'],
                    title: 'SAAS服务',
                    bgImg: require("../assets/img/know-ifweline/mission-vision-img3.png"),
                },
            ]
        }
    },
    components:{
        BannerWebOnlytitle, LogosComp
    }
}
</script>

<style lang="scss" scoped>
.konw-if-weline{
    width: 100%;
    .bg-content{
        width: 100%;
        height: 1991px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding-top: 132px;
        .introduction-box{
            width: 100%;
            min-width: 1080px;
            display: flex;
            margin-bottom: 163px;
            .img-box{
                width: 660px;
                height: 382px;
                margin-top: 9px;
                img{
                    width: 660px;
                    height: 382px;
                }
            }
            .content-box{
                .title{
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: rgba(73, 112, 224, 1);
                    line-height: 56px;
                    padding-left: 50px;
                }
                .title-content{
                    min-width: 593px;
                    padding-right: 110px;
                    background-color: white;
                    padding-left: 50px;
                    height: 288px;
                    background: #FFFFFF;
                    box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.06);
                    border-radius: 4px;
                    .content-title{
                        height: 56px;
                        font-size: 40px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: rgba(73, 112, 224, 1);
                        line-height: 56px;
                        margin-bottom: 38px;
                    }
                    .text{
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 33px;
                    }
                }
            }
        }
        .business-box{
            width: 1080px;
            margin: 0 auto;
            margin-bottom: 150px;
            img{
                width: 420px;
                height: 420px;
            }
            ::v-deep .is-in-stage{
                text-align: center;
                height: 420px;
            }
            ::v-deep .el-carousel__indicator{
                width: 5px;
                height: 5px;
                background: #CDCDCD;
                border-radius: 3px;
                padding: 0px;
                margin: 0px 8px;
            }
            ::v-deep .el-carousel__indicators--outside{
                margin-top: 30px;
                .is-active{
                    width: 15px;
                    height: 5px;
                    background: #4970E0;
                    border-radius: 3px;
                }
                .el-carousel__button{
                    width: 0px;
                }
            }
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 60px;
            }
            .carousel{
                margin: 0 auto;
                width: 1080px;
                .el-carousel__container{
                    display: flex;
                    justify-content: center;
                }
                .introduction{
                    width: 420px;
                    height: 126px;
                    background: rgba($color: #000000, $alpha: 0.4);
                    border-radius: 0px 0px 4px 4px;
                    position: relative;
                    bottom: 130px;
                    font-size: 26px;
                    left: 60px;
                    color: #FFFFFF;
                    line-height: 126px;
                    text-align: center;
                }
            }
        }
        .service-mode{
            width: 1080px;
            margin: 0 auto;
            .title{
                text-align: center;
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                margin-bottom: 40px;
            }
            .content-box{
                width: 1080px;
                height: 418px;
                background-repeat: no-repeat;
                background-size: 100% 352px;
                display: flex;
                .content-item{
                    width: 360px;
                    height: 418px;
                    cursor: pointer;
                    position: relative;
                    &:hover{
                        .introduction{
                            animation: textShowFromBottomIfweline 1s;
                            -webkit-animation: textShowFromBottomIfweline 1s; /* Safari 与 Chrome */
                            visibility: visible;
                        }
                        .title{
                            height: 62px;
                            border-bottom: 4px solid #4970E0;
                        }
                    }
                    .introduction{
                        width: 359px;
                        height: 352px;
                        background: rgba($color: #05206D, $alpha: 0.77);
                        display: flex;
                        align-items: center;
                        padding: 0px 63px;
                        box-sizing: border-box;
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 36px;
                        visibility: hidden;
                    }
                    .title{
                        width: 360px;
                        height: 66px;
                        background: #FFFFFF;
                        line-height: 66px;
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        border-right: 1px solid #EBEBEB;
                        position: absolute;
                        bottom: -41px;
                    }
                }
            }
        }
    }
    .mission-vision{
        width: 100%;
        height: 796px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 60px 0px;
        box-sizing: border-box;
        .content-box{
            width: 1080px;
            margin: 0 auto;
            .title{
                text-align: center;
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                margin-bottom: 56px;
            }
            .style-text-box{
                padding: 0px 30px 0px 34px;
                .normol{
                    height: 30px;
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 30px;
                }
                .style-text{
                    height: 42px;
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #4970E0;
                    line-height: 42px;
                    margin: 0px 20px;
                }
                .text-line1{
                    display: flex;
                    align-items: center;
                    margin-bottom: 35px;
                }
                .text-line2{
                    float: right;
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;
                }
            }
            .mission-vision-cards{
                display: flex;
                justify-content: space-between;
                .card-item{
                    width: 300px;
                    height: 404px;
                    .introduction{
                        width: 300px;
                        height: 338px;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        .text-box{
                            padding-top: 212px;
                            .text-item{
                                height: 24px;
                                font-size: 17px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 24px;
                                letter-spacing: 1px;
                                text-align: center;
                                margin-top: 11px;
                            }
                        }
                    }
                    .title{
                        width: 300px;
                        height: 66px;
                        background: #FFFFFF;
                        line-height: 66px;
                        text-align: center;
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #4970E0;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}
</style>
