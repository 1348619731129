<!-- 产品中心，项目管理系统，产品优势组件 -->
<template>
    <div class="product-advantages-item">
        <div class="img-box"><img :src="info.imgUrl"></div>
        <div class="title">{{ info.title }}</div>
        <div class="text">{{ info.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProductAdvantagesItemProjectMngSystem',
    props:{
        info: {
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .product-advantages-item{
        box-sizing: border-box;
        padding-bottom: 20px;
        margin-bottom: 40px;
        @media screen and (max-width: 600px) {
            width: 315px;
            margin: 10px 0px;
        }
        .img-box{
            text-align: center;
            height: 46px;
            margin-bottom: 16px;
            img{
                width: 46px;
                height: 46px;
            }
        }
        .title{
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4970E0;
            line-height: 28px;
            margin-bottom: 16px;
            text-align: center;
            @media screen and (max-width: 600px) {
                height: auto;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4970E0;
                line-height: 21px;
            }
        }
        .text{
            margin: 0 auto;
            text-align: center;
            width: 300px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 25px;
            color: rgb(104, 104, 104);
            @media screen and (max-width: 600px) {
                width: 315px;
                height: auto;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 18px;
            }
        }
    }
</style>
