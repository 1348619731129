<!-- 知识管理系统 -->
<template>
    <div class="knowledge-mng-system">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemEnterpriseMngPlatform v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemEnterpriseMngPlatform>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="text">科学严谨的知识生命周期理念，让复创智云知识管理系统更符合技术与管理的平衡</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box">
                    <ProductAdvantagesItemSaasPlatform v-for="(info, index) in applicationValueInfos" :key="index" :info="info"></ProductAdvantagesItemSaasPlatform>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo" class="customer-case-web"></CustomerCasePages>
        <CustomerCasePages :info="customerCasePagesInfoMob" class="customer-case-mob"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemEnterpriseMngPlatform from "../components/product-advantages-item-EnterpriseMngPlatform.vue";
import ProductAdvantagesItemSaasPlatform from "../components/product-advantages-item-SaasPlatform.vue";
import CustomerCasePages from '../components/customer-case-pages.vue';
export default {
    name: 'KnowledgeMngSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/knowledge-mng-system/banner.png"),
                title: '知识管理系统',
                text: '复创智云知识管理系统，以平台化、数字化、集成化、智能化为核心基础，为企业提供全面的知识管理应用'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/product-advantages-img1.png"),
                    title: '知识积累和管理',
                    text: '建立统一的知识仓库，自动采集不同来源的知识文档。根据知识分类，对不同类型的知识文档进行规范管理'
                },
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/product-advantages-img2.png"),
                    title: '团队协作、移动办公',
                    text: '构建线上团队，团队共享知识，支持多终端跨网跨区域协作和分享。支持移动办公，可随时随地查看、调用、审批文件、资料'
                },
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/product-advantages-img3.png"),
                    title: '深度智能能力',
                    text: '引入深度学习、人工智能、知识挖掘技术，实现了模糊语义搜索，自动知识分类，知识规则挖掘，海量数据知识萃取等智慧化功能'
                },
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/product-advantages-img4.png"),
                    title: '知识安全管理',
                    text: '覆盖知识生产、流转、应用、外发的全生命周期，在标识技术和区块链技术的基础上结合多重加密机制，保证知识高效且有效分发'
                },
            ],
            functionIntroductionImg: require("../assets/img/knowledge-mng-system/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/knowledge-mng-system/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/application-value-img1.png"),
                    title: '助力企业知识体系建设',
                    text: '全面管理内部的知识文档体系，统一采集，统一存储，保障知识的合规性、安全性、数据一致性。'
                },
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/application-value-img2.png"),
                    title: '提高企业员工培训学习效率',
                    text: '完善的内部知识管理体系，员工通过检索可自行学习知识，提高了员工自主学习能力和效率，提升了一线生产力水平。'
                },
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/application-value-img3.png"),
                    title: '积累企业知识资产',
                    text: '按照不同维度进行知识的加工整理，形成知识仓库、案例仓库、专家网络，帮助组织建立知识沉淀和知识创新的机制。'
                },
                {
                    imgUrl: require("../assets/img/knowledge-mng-system/application-value-img4.png"),
                    title: '开发企业知识管理多重应用',
                    text: '建立统一的知识仓库，提供知识搜索、知识地图、知识社区、知识问答等应用帮助制造企业建立知识分享和创新的氛围。'
                }
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/knowledge-mng-system/customer-case.png"),
                text: '复创大数据知识管理系统'
            },
            customerCasePagesInfoMob: {
                customerCaseImg: require("../assets/img/knowledge-mng-system/customer-case-mob.png"),
                text: '复创大数据知识管理系统'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemEnterpriseMngPlatform, ProductAdvantagesItemSaasPlatform, CustomerCasePages
    },
    methods: {
        changeTextFlag(arg){
            this.applicationValueInfos[arg[1]].showText = arg[0]
        }
    },
}
</script>

<style lang="scss" scoped>
.knowledge-mng-system{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1440px;
        margin: 0 auto;
        padding-top: 60px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .product-advantages-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 20px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .text{
            text-align: center;
            margin: 0 auto;
            height: 25px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgb(104, 104, 104);
            line-height: 25px;
            margin-bottom: 36px;
            @media screen and (max-width: 600px) {
                width: 294px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 25px;
                height: 50px;
            }
        }
        .img-box{
            height: 453px;
            width: 883px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 345px;
                height: 181px;
                margin: 0 auto;
            }
            img{
                width: 883px;
                height: 453px;
                @media screen and (max-width: 600px) {
                    width: 345px;
                    height: 181px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px 28px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin: 0 auto;
            }
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 40px;
                @media screen and (max-width: 600px) {
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 25px;
                    text-align: center;
                }
            }
            .values-box{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }
    .customer-case-web{
        display: block;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    .customer-case-mob{
        display: none;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }
}
</style>
