<!-- 智能制造学院 -->
<template>
    <div class="intelligent-manu-college">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemIntelManuCollege v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemIntelManuCollege>
            </div>
        </div>
        <a name="contentIntroduction"></a>
        <div class="content-introduction" :style="{ backgroundImage: `url(${contentIntroductionImg})` }">
            <div class="title">内容介绍</div>
            <div class="content-box">
                <div class="box-item1">
                    <div class="text-box">
                        <div class="title">{{ contentIntroductionData[0].title }}</div>
                        <div class="text-item" v-for="(item, index) in contentIntroductionData[0].lis" :key="index">{{ item }}</div>
                    </div>
                    <div class="img-box"><img :src="contentIntroductionData[0].imgUrl"></div>
                </div>
                <div class="box-item2">
                    <div class="img-box"><img :src="contentIntroductionData[1].imgUrl"></div>
                    <div class="text-box">
                        <div class="title">{{ contentIntroductionData[1].title }}</div>
                        <div class="text-item" v-for="(item, index) in contentIntroductionData[1].lis" :key="index">{{ item }}</div>
                    </div>
                </div>
                <div class="box-item3">
                    <div class="text-box">
                        <div class="title">{{ contentIntroductionData[2].title }}</div>
                        <div class="text-item" v-for="(item, index) in contentIntroductionData[2].lis" :key="index">{{ item }}</div>
                    </div>
                    <div class="img-box"><img :src="contentIntroductionData[2].imgUrl"></div>
                </div>
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box">
                    <div class="img-box"><img :src="applicationValueImg1"></div>
                    <div class="introduction-item" v-for="(item, index) in applicationValueInfos" :key="index" :style="{ backgroundColor: item.bgColor,
                        color: item.color }"
                    >
                        <div class="content-box">
                            <div class="introduction-item-title">{{ item.title }}</div>
                            <div class="introduction-item-text">{{ item.text }}</div>
                        </div>
                    </div>
                    <div class="img-box"><img :src="applicationValueImg2"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemIntelManuCollege from '../components/product-advantages-item-IntelligentManufacturingCollege'
import '../assets/css/animate.css'
export default {
    name: 'IntelligentManufacturingCollege',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/intelligent-manu-college/banner.png"),
                title: '智能制造学院',
                text: '复创智能制造学院秉持教育兴国，科技强国战略背景下，以顶层设计规划思维与基层人才技能落地两大方针为办学理念，专注智能制造领域专业人才培养，探索产学研一体化发展路径，助力企业数字化转型和智能化改造。'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '内容介绍',
                    link: 'contentIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/intelligent-manu-college/product-advantages-img1.png"),
                    title: '工信部职业能力证书认证',
                    content: '根据《工业和信息化职业技能提升培训项目指导目录》，覆盖制造业重点技术领域、提供80多个培训项目，输出5,0000个行业急需和技术前沿的高技术型人才。'
                },
                {
                    imgUrl: require("../assets/img/intelligent-manu-college/product-advantages-img2.png"),
                    title: '雄厚师资力量',
                    content: '聚焦智能制造行业，汇聚众多行业专家及院校名师，以专业的数字工业知识课程，共同促进产业发展，助力产业转型升级。'
                },
                {
                    imgUrl: require("../assets/img/intelligent-manu-college/product-advantages-img3.png"),
                    title: '达索系统技术能力证书认证',
                    content: '2000+各高校及达索系统专业课程库，满足工程类、机械类等专业工程师多样化学习需求。'
                },
                {
                    imgUrl: require("../assets/img/intelligent-manu-college/product-advantages-img4.png"),
                    title: '就业推荐指导',
                    content: '领域大咖对行业精准分析，并为学员量身打造职业规划，提供生态圈知名企业就业推荐以及案例分享。'
                }
            ],
            contentIntroductionImg: require("../assets/img/intelligent-manu-college/content-introduction-bgimg.png"),
            contentIntroductionData: [
                {
                    title: '数字化转型课程',
                    lis: ['• 新一代信息技术', '• 区块链+数字经济', '• 数字孪生与元宇宙', '• 数字化精益生产'],
                    imgUrl: require("../assets/img/intelligent-manu-college/content-introduction-img1.png")
                },
                {
                    title: '工业互联网课程',
                    lis: ['• 大数据与人工智能', '• 云计算', '• 5G+工业互联网', '• 工业APP'],
                    imgUrl: require("../assets/img/intelligent-manu-college/content-introduction-img2.png")
                },
                {
                    title: '智能制造',
                    lis: ['• 智能制造装备', '• 先进制造技术', '• 人工智能与工业机器人', '• 增材制造'],
                    imgUrl: require("../assets/img/intelligent-manu-college/content-introduction-img3.png")
                },
            ],
            applicationValueImg1: require("../assets/img/intelligent-manu-college/application-value-img1.png"),
            applicationValueImg2: require("../assets/img/intelligent-manu-college/application-value-img2.png"),
            applicationValueInfos: [
                {
                    title: '提升职业发展空间',
                    text: '工信部教育与考试中心认证，提升职业能力与未来发展空间。',
                    bgColor: '#4970E0',
                    color: '#FFFFFF'
                },
                {
                    title: '就业能力最大化',
                    text: '达索系统公司官方颁发证书，全球通用，使持证者就业能力最大化。',
                    bgColor: '#1741BA',
                    color: '#FFFFFF'
                },
                {
                    title: '助力企业项目投标',
                    text: '证书认可度高，对企业项目投标具有推动作用。',
                    bgColor: '#D6E0FC',
                    color: '#151515'
                },
                {
                    title: '职业培训补贴',
                    text: '持证者可按当地政策申领职业技能培训补贴。',
                    bgColor: '#4970E0',
                    color: '#FFFFFF'
                },
                {
                    title: '入驻人才库',
                    text: '持证者将有机会获得达索系统公司推荐工作机会，直接进入达索系统公司人才库。',
                    bgColor: '#1741BA',
                    color: '#FFFFFF'
                },
                {
                    title: '赋予数字徽章',
                    text: '达索还将赋予学院数字徽章。数字徽章可携带、验证、在领域中代表着权威的象征。',
                    bgColor: '#D6E0FC',
                    color: '#151515'
                },
            ]
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemIntelManuCollege
    }
}
</script>

<style lang="scss" scoped>
.intelligent-manu-college{
    width: 100%;
    min-width: 1440px;
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .product-advantages-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .content-introduction{
        width: 100%;
        margin: 0 auto;
        padding: 60px 0px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 56px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 40px;
        }
        .content-box{
            width: 1080px;
            height: 460px;
            margin: 0 auto;
            display: flex;
            .img-box{
                width: 360px;
                height: 230px;
                img{
                    width: 360px;
                    height: 230px;
                }
            }
            .box-item1{
                width: 360px;
                height: 460px;
                .text-box{
                    box-sizing: border-box;
                    padding: 24px 0px;
                    background: #4970E0;
                    height: 230px;
                    .title{
                        text-align: center;
                        height: 25px;
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 25px;
                        margin-bottom: 8px;
                    }
                    .text-item{
                        padding-left: 113px;
                        height: 25px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 25px;
                        margin-top: 12px;
                    }
                }
            }
            .box-item2{
                width: 360px;
                height: 460px;
                .text-box{
                    box-sizing: border-box;
                    padding: 24px 0px;
                    background: #FFFFFF;
                    height: 230px;
                    .title{
                        text-align: center;
                        height: 25px;
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 25px;
                        margin-bottom: 8px;
                    }
                    .text-item{
                        padding-left: 113px;
                        height: 25px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 25px;
                        margin-top: 12px;
                    }
                }
            }
            .box-item3{
                width: 360px;
                height: 460px;
                .text-box{
                    box-sizing: border-box;
                    padding: 24px 0px;
                    background: #0B2A82;
                    height: 230px;
                    .title{
                        text-align: center;
                        height: 25px;
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 25px;
                        margin-bottom: 8px;
                    }
                    .text-item{
                        padding-left: 113px;
                        height: 25px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 25px;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 40px;
            }
            .values-box{
                display: flex;
                flex-wrap: wrap;
                .img-box{
                    width: 540px;
                    height: 236px;
                    img{
                        width: 540px;
                        height: 236px;
                    }
                }
                .introduction-item{
                    width: 180px;
                    height: 236px;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .content-box{
                            .introduction-item-text{
                                animation: textShowFromBottomIntelManuCollegeValues 1s;
                                -webkit-animation: textShowFromBottomIntelManuCollegeValues 1s; /* Safari 与 Chrome */
                                display: block;
                            }
                        }
                    }
                    .content-box{
                        width: 144px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        .introduction-item-title{
                            font-size: 18px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            line-height: 25px;
                            margin-bottom: 18px;
                            text-align: center;
                        }
                        .introduction-item-text{
                            display: none;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            line-height: 25px;
                            height: 100px;
                        }
                    }
                }
            }
        }
    }
}
</style>
