<!-- 供应商质量分析系统 -->
<template>
    <div class="supplier-quality-analysis">
        <BannerWeb :info="bannerInfo"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="productAdvantages"></a>
        <div class="product-advantages">
            <div class="title">产品优势</div>
            <div class="product-advantages-box">
                <ProductAdvantagesItemSupplierQualityAnalysis v-for="(item, index) in productAdvantagesInfo" :info="item" :key="index"></ProductAdvantagesItemSupplierQualityAnalysis>
            </div>
        </div>
        <a name="functionIntroduction"></a>
        <div class="function-introduction">
            <div class="title">功能介绍</div>
            <div class="img-box">
                <img :src="functionIntroductionImg">
            </div>
        </div>
        <a name="applicationValue"></a>
        <div class="application-value" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="application-value-content">
                <div class="title">应用价值</div>
                <div class="values-box" :style="{backgroundImage: `url(${applicationValueImg})`}">
                    <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfos" :key="index" :info="info"
                        @changeTextFlag="changeTextFlag"
                    ></ApplicationValueProjectMngSystem>
                </div>
                <div class="values-box-mob">
                    <div class="values-box-item-mob" :style="{ backgroundImage: `url(${applicationValueImgMob})`, marginBottom: '20px' }">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfosMob1" :key="index" :info="info"
                            @changeTextFlag="changeTextFlagMob1"
                        ></ApplicationValueProjectMngSystem>
                    </div>
                    <div class="values-box-item-mob" :style="{backgroundImage: `url(${applicationValueImgMob})`}">
                        <ApplicationValueProjectMngSystem v-for="(info, index) in applicationValueInfosMob2" :key="index" :info="info"
                            @changeTextFlag="changeTextFlagMob2"
                        ></ApplicationValueProjectMngSystem>
                    </div>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo" class="customer-case-web"></CustomerCasePages>
        <CustomerCasePages :info="customerCasePagesInfoMob" class="customer-case-mob"></CustomerCasePages>
    </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ApplicationValueProjectMngSystem from '../components/application-value-ProjectMngSystem.vue'
import ProductAdvantagesItemSupplierQualityAnalysis from '../components/product-advantages-item-SupplierQualityAnalysis'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'SupplierQualityAnalysisSystem',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/supplier-quality-analysis/banner.png"),
                title: '供应商质量分析系统',
                text: '复创智云供应商质量分析系统，涵盖供应商档案、供应商准入、供应商年度监察、供应商业绩评价、供应商质量统计分析等业务功能。对供应商开发过程管理、供应商业绩评价公平透明、供应商监察、准入考察及来料质量异常的改进进行跟踪管理'
            },
            anchorLinks: [
                {
                    name: '产品优势',
                    link: 'productAdvantages'
                },
                {
                    name: '功能介绍',
                    link: 'functionIntroduction'
                },
                {
                    name: '应用价值',
                    link: 'applicationValue'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            productAdvantagesInfo: [
                {
                    imgUrl: require("../assets/img/supplier-quality-analysis/product-advantages-img1.png"),
                    title: '准入流程电子化',
                    text: '供应商从报名、注册、认证、审核和准入全流程电子化管理，让供应商管理简单高效，并让供应商信息更加透明清晰。'
                },
                {
                    imgUrl: require("../assets/img/supplier-quality-analysis/product-advantages-img2.png"),
                    title: '供应商电子档案',
                    text: '构建供应商电子档案，规范管理、统一查询和修改、实时数据同步，且支持多端查询。'
                },
                {
                    imgUrl: require("../assets/img/supplier-quality-analysis/product-advantages-img3.png"),
                    title: '质量问题及时预警',
                    text: '通过质量管理系统实时监控，实时更新的数据对质量问题预警信息实现精准传递，确保不合格信息及时控制和可追溯化，系统可进行交互同步预警，实现质量信息互联互通。'
                },
                {
                    imgUrl: require("../assets/img/supplier-quality-analysis/product-advantages-img4.png"),
                    title: '数据分析与应用',
                    text: '对质量问题数据信息进行智能分析和辅助判断，对问题进行多维度的分级和识别, 支持进一步的数据挖掘，为企业提供数据分析模型，用数据为企业提供应用价值。'
                },
            ],
            functionIntroductionImg: require("../assets/img/supplier-quality-analysis/function-introduction.png"),
            applicationValueBgImg: require("../assets/img/supplier-quality-analysis/application-value-bgimg.png"),
            applicationValueInfos: [
                {
                    index: 0,
                    title: '提升企业核心竞争能力',
                    showText: false,
                    text: ['随着企业越来越注重于核心能力的培养和核心业务的开拓，从外部获取供应资源，有助于企业集中精力来提升自身的核心竞争能力。']
                },
                {
                    index: 1,
                    title: '有利于降低商品采购成本',
                    showText: false,
                    text: ['由于采购成本在企业总成本中占据着相当大的比重，且该比例将随着核心能力的集中和业务外包比例的增加而增加，制造商只有与供应商联合，形成长期稳定的采购关系，才能实现产品采购成本的进一步降低。']
                },
                {
                    index: 2,
                    title: '有利于降低企业库存水平',
                    showText: false,
                    text: ['有效的供应商管理可以帮助企业建立一支可靠的供应商队伍，为企业生产提供稳定可靠的物资供应保障。从而有助于制造商降低库存水平，减少库存的压力。']
                },
                {
                    index: 3,
                    title: '有利于集成制造资源',
                    showText: false,
                    text: ['制造资源应被集成起来发挥作用早已是人们在制造生产中得到的共识，有效的供应商管理可实现制造企业与供应商的有效集成。']
                }
            ],
            applicationValueInfosMob1:[
                {
                    index: 0,
                    title: '提升企业核心竞争能力',
                    showText: false,
                    text: ['随着企业越来越注重于核心能力的培养和核心业务的开拓，从外部获取供应资源，有助于企业集中精力来提升自身的核心竞争能力。']
                },
                {
                    index: 1,
                    title: '有利于降低商品采购成本',
                    showText: false,
                    text: ['由于采购成本在企业总成本中占据着相当大的比重，且该比例将随着核心能力的集中和业务外包比例的增加而增加，制造商只有与供应商联合，形成长期稳定的采购关系，才能实现产品采购成本的进一步降低。']
                },
            ],
            applicationValueInfosMob2: [
                {
                    index: 0,
                    title: '有利于降低企业库存水平',
                    showText: false,
                    text: ['有效的供应商管理可以帮助企业建立一支可靠的供应商队伍，为企业生产提供稳定可靠的物资供应保障。从而有助于制造商降低库存水平，减少库存的压力。']
                },
                {
                    index: 1,
                    title: '有利于集成制造资源',
                    showText: false,
                    text: ['制造资源应被集成起来发挥作用早已是人们在制造生产中得到的共识，有效的供应商管理可实现制造企业与供应商的有效集成。']
                }
            ],
            applicationValueImgMob: require("../assets/img/supplier-quality-analysis/application-value-contentimg-mob.png"),
            applicationValueImg: require("../assets/img/supplier-quality-analysis/application-value-contentimg.png"),
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/supplier-quality-analysis/customer-case.png"),
                text: '复创大数据供应商质量分析管理系统'
            },
            customerCasePagesInfoMob: {
                customerCaseImg: require("../assets/img/supplier-quality-analysis/customer-case-mob.png"),
                // text: '复创大数据供应商质量分析管理系统'
            }
        }
    },
    components:{
        BannerWeb, AnchorNavigation, ProductAdvantagesItemSupplierQualityAnalysis, ApplicationValueProjectMngSystem, CustomerCasePages
    },
    methods: {
        changeTextFlag(arg){
            this.applicationValueInfos[arg[1]].showText = arg[0]
        },
        changeTextFlagMob1(arg) {
            this.applicationValueInfosMob1[arg[1]].showText = arg[0]
        },
        changeTextFlagMob2(arg) {
            this.applicationValueInfosMob2[arg[1]].showText = arg[0]
        }
    },
}
</script>

<style lang="scss" scoped>
.supplier-quality-analysis{
    width: 100%;
    min-width: 1440px;
    @media screen and (max-width: 600px) { 
        width: 100%;
        min-width: 100px;
        .anchor-navigation{
            display: none;
        }
    }
    .product-advantages{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .product-advantages-box{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }
    .function-introduction{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
            @media screen and (max-width: 600px) {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 25px;
                text-align: center;
                margin-bottom: 10px;
            }
        }
        .img-box{
            height: 502px;
            width: 880px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                height: 199px;
                width: 345px;
            }
            img{
                height: 502px;
                width: 880px;
                @media screen and (max-width: 600px) {
                    height: 199px;
                    width: 345px;
                }
            }
        }
    }
    .application-value{
        width: 100%;
        padding: 60px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 600px) {
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
        }
        .application-value-content{
            width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 25px;
                @media screen and (max-width: 600px) {
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
            .values-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-repeat: no-repeat;
                background-size: 100% 266px;
                border-radius: 0px 0px 4px 4px;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            .values-box-mob{
                display: none;
                @media screen and (max-width: 600px) {
                    display: block;
                }
                .values-box-item-mob{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    background-repeat: no-repeat;
                    background-size: 100% 180px;
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }
    }
    .customer-case-web{
        display: block;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    .customer-case-mob{
        display: none;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }
}
</style>
