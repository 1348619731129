<!-- 数字化农业解决方案 -->
<template>
 <div>
    <div class="digital-agriculture-solutions">
        <BannerWeb :info="bannerInfo" :textWidth="1009"></BannerWeb>
        <AnchorNavigation :anchorLinks="anchorLinks"></AnchorNavigation>
        <a name="industryTrends"></a>
        <div class="industry-trends">
            <div class="title">行业趋势</div>
            <div class="industry-trends-box">
                <ProductAdvantagesItemSupplierQualityAnalysis v-for="(item, index) in industryTrendsInfo" :info="item" :key="index"></ProductAdvantagesItemSupplierQualityAnalysis>
            </div>
        </div>
        <a name="schemeStructure"></a>
        <div class="scheme-structure-bgbox">
            <div class="scheme-structure">
                <div class="title">方案架构</div>
                <div class="img-box">
                    <img :src="schemeStructureImg">
                </div>
            </div>
        </div>
        <a name="schemeAdvantages"></a>
        <div class="scheme-advantages" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="scheme-advantages-content">
                <div class="title">方案优势</div>
                <div class="advantages-box">
                    <SchemeAdvantagesDigitalAgricultureSolutions v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"></SchemeAdvantagesDigitalAgricultureSolutions>
                </div>
            </div>
        </div>
        <a name="customerCase"></a>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
    <!-- 移动端 -->
    <div class="digital-agriculture-solutions_mob">
        <BannerWeb :info="bannerInfo" :textWidth="500"></BannerWeb>
        <div class="industry-trends_mob">
            <div class="title_mob">行业趋势</div>
            <div class="industry-trends-box_mob">
                <ProductAdvantagesItemSupplierQualityAnalysis v-for="(item, index) in industryTrendsInfo" :info="item" :key="index"></ProductAdvantagesItemSupplierQualityAnalysis>
            </div>
        </div>
        <div class="scheme-structure-bgbox_mob">
            <div class="scheme-structure_mob">
                <div class="title_mob">方案架构</div>
                <div class="img-box_mob">
                    <img :src="schemeStructure1MobImg" />
                    <img :src="schemeStructure2MobImg" />
                </div>
            </div>
        </div>
        <div class="scheme-advantages_mob" :style="{ backgroundImage: `url(${applicationValueBgImg})` }">
            <div class="scheme-advantages-content_mob">
                <div class="title_mob">方案优势</div>
                <div class="advantages-box_mob">
                    <SchemeAdvantagesDigitalAgricultureSolutions v-for="(info, index) in schemeAdvantagesInfos" :key="index" :info="info"></SchemeAdvantagesDigitalAgricultureSolutions>
                </div>
            </div>
        </div>
        <CustomerCasePages :info="customerCasePagesInfo"></CustomerCasePages>
    </div>
 </div>
</template>

<script>
import BannerWeb from "../components/banner-web.vue";
import AnchorNavigation from "../components/anchor-navigation.vue";
import ProductAdvantagesItemSupplierQualityAnalysis from '../components/product-advantages-item-SupplierQualityAnalysis'
import SchemeAdvantagesDigitalAgricultureSolutions from '../components/scheme-advantages-DigitalAgricultureSolutions.vue'
import CustomerCasePages from '../components/customer-case-pages.vue'
export default {
    name: 'DigitalAgricultureSolutions',
    data() {
        return {
            bannerInfo: {
                bannerImg: require("../assets/img/digital-agriculture-solutions/banner.png"),
                title: '数字化农业解决方案',
                text: '复创智云数字化农业解决方案，综合应用现代管理理念及信息化技术，为农企量身定制的企业发展全供应链资源整合平台，促进农业企业生产管理标准化、资源管理信息化以及运营管理平台化发展。以物联网数据采集平台为基础，通过全链路农产品产业供应链运营指挥中心、交易平台、物流平台和金融平台将结合，实现农业生产全过程的信息采集和溯源管理'
            },
            anchorLinks: [
                {
                    name: '行业趋势',
                    link: 'industryTrends'
                },
                {
                    name: '方案架构',
                    link: 'schemeStructure'
                },
                {
                    name: '方案优势',
                    link: 'schemeAdvantages'
                },
                {
                    name: '客户案例',
                    link: 'customerCase'
                },
            ],
            industryTrendsInfo: [
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/industry-trends-img1.png"),
                    title: '科技赋农',
                    text: '提高环境控制能力，数据化积累栽培管理经验，数量化和指标化生产管理能力越来越重要'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/industry-trends-img2.png"),
                    title: '机械化、智能化管理趋势',
                    text: '农业机械的配套水平和机械化作业水平越来越高，生产力逐渐被机器代替为主，劳动强度大大降低'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/industry-trends-img3.png"),
                    title: '数字化管理驱动可持续发展',
                    text: '数字化农业园区运营管理能力， 内外部优质资源整合能力，从而实现数字化可持续发展。'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/industry-trends-img4.png"),
                    title: '高新技术驱动产业创新',
                    text: '近年来，大数据、云计算、人工智能、虚拟现实等数字技术快速发展，为农业创造了丰富的应用场景，正在驱动数字化农业产业创新。'
                },
            ],
            schemeStructureImg: require("../assets/img/digital-agriculture-solutions/scheme-structure.png"),
            schemeStructure1MobImg: require("../assets/img/digital-agriculture-solutions/scheme-structure1-mob.png"),
            schemeStructure2MobImg: require("../assets/img/digital-agriculture-solutions/scheme-structure2-mob.png"),
            schemeAdvantagesInfos: [
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/scheme-advantages-img1.png"),
                    title: '食品溯源功能',
                    text: '实现产品源产地、生长过程、喂养打药、产品资质等可视化溯源系统，实现农产品的全链条信息采集，并通过二维码实现查询'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/scheme-advantages-img2.png"),
                    title: '防伪鉴真能力',
                    text: '防伪技术为基础建立的溯源体系，可保证每一项录入的数据不可篡改。有效防止假冒伪劣产品混淆，保持企业产品的好口碑，提升农产品的品牌竞争力。'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/scheme-advantages-img3.png"),
                    title: '智慧仓储功能',
                    text: '供应链数字化、标识化，实现经销、服务精准推送，并依托数字化载体，实现对农产品货位标签、物资标签电子化管理，支持对物资上下架，货位调整、转储、盘点、冻结、报废等业务处理，便于数据快速采集、识别和追溯。'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/scheme-advantages-img4.png"),
                    title: '农产监控能力',
                    text: '对种养殖现场打药、除草、施肥等农事监管、防盗监控及数据采集；对讲、防盗监控、农事监控等功能全覆盖。'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/scheme-advantages-img5.png"),
                    title: '企业展示功能',
                    text: '展示企业风采、产品品质、企业动态等信息，实现消费者对企业的全方位了解。'
                },
                {
                    imgUrl: require("../assets/img/digital-agriculture-solutions/scheme-advantages-img6.png"),
                    title: '数字营销功能',
                    text: '建立企业数字化展示及营销平台，发布企业及产品信息，帮助企业进行商品销售和品牌文化宣传。'
                }
            ],
            customerCasePagesInfo: {
                customerCaseImg: require("../assets/img/digital-agriculture-solutions/customer-case.png"),
                text: '石台硒米数字化管理解决方案'
            },
            applicationValueBgImg: require("../assets/img/digital-agriculture-solutions/application-value-bgimg.png"),
        }
    },
    components:{
        BannerWeb, AnchorNavigation, SchemeAdvantagesDigitalAgricultureSolutions, ProductAdvantagesItemSupplierQualityAnalysis, CustomerCasePages
    },
    methods: {
        changeTextFlag(arg){
            this.applicationValueInfos[arg[1]].showText = arg[0]
        }
    },
}
</script>
<!-- B端 -->
<style lang="scss" scoped>
@media screen and (min-width: 601px) {
    .digital-agriculture-solutions {
        display: block;
    }
    .digital-agriculture-solutions_mob {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .digital-agriculture-solutions {
        display: none;
    }
    .digital-agriculture-solutions_mob {
        display: block;
    }
}
.digital-agriculture-solutions{
    width: 100%;
    min-width: 1440px;
    .industry-trends{
        width: 1080px;
        margin: 0 auto;
        padding: 60px 0px 12px;
        .title{
            height: 56px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 56px;
            text-align: center;
            margin-bottom: 40px;
        }
        .industry-trends-box{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }
    .scheme-structure-bgbox{
        width: 100%;
        .scheme-structure{
            width: 1080px;
            margin: 0 auto;
            padding: 60px 0px;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 40px;
            }
            .img-box{
                height: 316px;
                max-width: 1080px;
                margin: 0 auto;
                img{
                    height: 316px;
                    max-width: 1080px;
                }
            }
        }
    }
    .scheme-advantages{
        width: 100%;
        padding: 60px 0px 10px 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .scheme-advantages-content{
            width: 1080px;
            margin: 0 auto;
            .title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                text-align: center;
                margin-bottom: 40px;
            }
            .advantages-box{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                background-repeat: no-repeat;
                background-size: 100% 266px;
                border-radius: 0px 0px 4px 4px;
            }
        }
    }
}
</style>
<!-- C端 -->
<style lang="scss" scoped>
.digital-agriculture-solutions_mob {
   .industry-trends_mob, .scheme-structure-bgbox_mob, .scheme-advantages_mob {
      .title_mob {
            height: 76px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 76px;
            text-align: center;
            letter-spacing: 1px;
      }
      .industry-trends-box_mob {
        display: flex;
        flex-wrap: wrap;
      }
      .img-box_mob {
         padding: 0 10px 10px;
         img {
            width: 100%;
         }
      }
   }
}
</style>